<template>
  <div>
    <v-card
      :width="size"
      :height="cardHeight"
      :class="{'rounded-0': !rounded}"
      :title="resource.name"
    >
      <v-img
        :src="imageUrl"
        :lazy-src="resourceDefaultImage"
        :width="size"
        :aspect-ratio="1.85"
        :contain="false"
        :eager="true"
        :alt="altDescription"
      >
        <v-chip
          v-if="isEditor"
          small
          label
          class="editor-chip"
        >
          EDITOR
        </v-chip>
      </v-img>
      <div class="card-info">
        <div class="card-info--heading">
          <v-card-title
            :class="{
              'd-flex justify-space-between': true,
              'px-2 pt-0 pb-0 mt-0': size < 350,
              'pb-0 pt-1 mt-0' : size >= 350
            }"
          >
            <span
              :class="{
                'resource-type': true,
                'resource-type-xs': size < 280
              }"
              :aria-label="'resource type: '+ resourceType"
            >
              {{ resourceType }}
            </span>
            <span
              class="d-flex"
            >
              <FeatureIcon
                v-for="cat in resourceCategory"
                :key="cat.id"
                size="20"
                :aria-label="cat.label"
                :color="cat.iconColor"
                :backgroundColor="cat.iconBg"
                :icon="cat.icon"
                class="ml-2"
              />
            </span>

            <v-chip
              v-if="isDraft && (isOwner || isEditor)"
              small
              class="draft-chip"
            >
              DRAFT
            </v-chip>
          </v-card-title>
          <v-card-title
            v-if="isDraft"
            class="pt-0 pb-0"
            :class="{'px-2': size < 350 }"
          >
            <a
              :id="itemId"
              tabindex="0"
              class="primary-control"
              @click="openDetailsPage"
              @keyup.enter="openDetailsPage"
            >
              <h3
                class="card-title"
                :class="{'card-title-xl': size > 450, 'card-title-md': size < 320}"
              >
                {{ resource.Name ? resource.Name : 'No title created' }}
              </h3>
            </a>
          </v-card-title>
          <v-card-title
            v-else
            class="pt-0 pb-0"
            :class="{'px-2': size < 350 }"
          >
            <router-link
              :id="item.id"
              :to="{
                name: 'HubResourceDetailsPage',
                params: {
                  id: itemId
                }}"
              tabindex="0"
              class="primary-control"
            >
              <h3
                class="card-title"
                :class="{'card-title-xl': size > 450, 'card-title-md': size < 320}"
              >
                {{ resource.Name ? resource.Name : 'No title created' }}
              </h3>
            </router-link>
          </v-card-title>
        </div>
        <v-card-actions :class="{ 'd-flex align-end': true }">
          <v-card-subtitle
            :class="{'pt-1 pr-6': size >= 350, 'px-2 pt-0 pb-2': size < 350, 'pb-1' : true}"
            class="card-footer"
          >
            <div
              v-if="isSIRCategory"
              class="card-left-note"
            >
              Focus Area:
              <span
                :class="{
                  'years-sm': size < 300,
                  'years-xs': size < 280
                }"
              >
                {{ focusAreas ? focusAreas : 'None' }}
              </span>
            </div>
            <div v-else>
              Years:
              <span
                :class="{
                  'years-sm': size < 300,
                  'years-xs': size < 280
                }"
              >
                {{ years ? years : 'None selected' }}
              </span>
            </div>
            <span>Updated: {{ moment(updatedDate).format('DD MMM YYYY') }}</span>
          </v-card-subtitle>
          <v-spacer />
          <template>
            <div
              class="d-flex justify-end mb-1"
            >
              <v-tooltip
                bottom
                :activator="`.save-to-collection-${_uid}`"
              >
                <span>Save to collection</span>
              </v-tooltip>
              <v-tooltip
                bottom
                :activator="`.copy-link-${_uid}`"
              >
                <span>Copy this link</span>
              </v-tooltip>
              <v-tooltip
                bottom
                :activator="`.goto-resource-${_uid}`"
              >
                <span>Go to Resource</span>
              </v-tooltip>
              <v-btn
                icon
                small
                aria-label="Save to collection"
                :class="`save-to-collection-${_uid}`"
                class="button-icon save-to-collection"
                @click="handleAddToCollection"
              >
                <v-icon outlined>
                  mdi-bookmark-plus-outline
                </v-icon>
              </v-btn>
              <template v-if="showAddToCollectionDialog">
                <SaveToCollectionDialog
                  v-model="showAddToCollectionDialog"
                  :items="[{...item}]"
                  :selected-cards="[{
                    ...item?.document,
                    Application: item?.audit?.iss
                  }]"
                />
              </template>
              <div>
                <Dialog
                  v-model="dialogCopyLink"
                  title="Copy this link"
                  dark-heading
                  :max-width="$vuetify.breakpoint.smAndUp ? 500 : 360"
                >
                  <template #activator>
                    <v-btn
                      icon
                      small
                      aria-label="copy url"
                      :class="`copy-link-${_uid}`"
                      class="button-icon copy-button"
                      @click="dialogCopyLink = true"
                    >
                      <v-icon outlined>
                        content_copy
                      </v-icon>
                    </v-btn>
                  </template>
                  <template #content>
                    <CopyClipboardMenu
                      :is-open="dialogCopyLink"
                      description="Copy and paste this link:"
                      :text-to-copy="itemUrl"
                    />
                  </template>
                </Dialog>
              </div>
              <v-btn
                icon
                small
                aria-label="Go to resource"
                :class="`goto-resource-${_uid}`"
                class="button-icon go-to-resource-button"
                @click="handleGoToResource"
              >
                <v-icon outlined>
                  open_in_new
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import resourceDefaultImage from '@/assets/default_myresources_image.png';
import Dialog from '@/components/Dialogs/Dialog';
import CopyClipboardMenu from '@/components/CopyClipboardMenu';
import {FeatureIcon} from '@nswdoe/doe-ui-core';
import {RESOURCE_CATEGORIES} from '@/constants';
import {mapGetters} from 'vuex';
import SaveToCollectionDialog from '@/components/Dialogs/Collections/SaveToCollection';

export default {
  name: 'ResourceCard',
  components: {
    SaveToCollectionDialog,
    Dialog,
    CopyClipboardMenu,
    FeatureIcon
  },
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    files: {
      type: Array,
      default: () => []
    },
    size: {
      type: [Number, String],
      default: 350
    },
    height: {
      type: [Number, String],
      default: 350
    },
    rounded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      moment,
      dialogCopyLink: false,
      deleteDialog: false,
      showAddToCollectionDialog: false,
      resourceDefaultImage,
    }
  },
  computed: {
    ...mapGetters({
      allHubResourceTypes: 'metadata/allHubResourceTypes'
    }),
    isSIRCategory() {
      return this.resource.ResourceCategory?.includes('sir') || false;
    },
    focusAreas() {
      return this.resource.FocusArea?.map(e => e.focusArea).join(', ');
    },
    resourceType() {
      // Lookup in metadata for the resourceType label
      return this.allHubResourceTypes.length > 0 && this.allHubResourceTypes?.find(type => {
        return type.id === this.resource.ResourceType
      })?.name || 'Resource';
    },
    resource() {
      if (this.item.document) {
        if (this.isDraft && !this.isEditor && !this.isOwner) {
          const reversedArchive = this.item.archive.slice().reverse();
          const beforeDraft = reversedArchive.find(resource => !resource.document.draft);
          return beforeDraft.document;
        }
        return this.item.document;
      } else {
        return this.item;
      }
    },
    resourceCategory() {
      return this.resource.ResourceCategory?.map(id => this.getCategoryDetails(id));
    },
    imageUrl() {
      if (this.resource.ThumbnailImage) {
        return this.resource.ThumbnailImage;
      } else {
        return resourceDefaultImage;
      }
    },
    altDescription() {
      return this.resource.ResourceImage?.altDescription ? this.resource.ResourceImage?.altDescription : 'alt description not available';
    },
    updatedDate() {
      return this.item.record.modified ? this.item.record.modified : this.item.record.created;
    },
    itemUrl() {
      // return this.resource?.ResourceURL || this.files?.[0]?.DownloadUrl
      return this.item.links?.[0]?.url || this.item.files?.[0]?.DownloadUrl;
    },
    itemId() {
      return this.item?.document?.ItemID;
    },
    years() {
      return this.resource.Year?.join(' ');
    },
    isOwner() {
      return false;
    },
    isEditor() {
      return false;
    },
    isViewer() {
      return false;
    },
    isDraft() {
      return false;
    },
    cardHeight() {
      return this.$vuetify.breakpoint.mdAndDown ? this.height + 20 : this.height;
    }
  },
  methods: {
    handleGoToResource() {
      this.$store.dispatch('captureGoToResource', this.itemId);
      window.open(this.itemUrl, '_blank');
    },
    handleAddToCollection() {
      this.showAddToCollectionDialog = true;
    },
    getCategoryDetails(category) {
      return RESOURCE_CATEGORIES.find(cat => cat.id === category) || null;
    },
    async openDetailsPage() {
      try {
        await this.$router.push('/detail/' + this.resource.id); // Hub details page
      } catch (e) {
        if (e.name === 'NavigationDuplicated') {
          return;
        }
        this.$store.commit('errors/SET_ERROR_TYPE', 'resourceNotFound');
        await this.$router.push('/notfound');
      }
    },
    parseAndSortYearsOrStages(arr, str) {
      let arrModified = [];
      let includeString = false;
      if (arr.includes(str)) {
        includeString = true;
        arrModified = arr.filter(s => s !== str);
        arrModified = arrModified.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      } else {
        arrModified = arr.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      }
      if (includeString) {
        arrModified.unshift('K');
      }
      return arrModified;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.v-card {
  display: flex;
  flex-direction: column;

  .v-image {
    position: relative;

    border: none;
    &.v-responsive {
      flex: none;
    }

    .editor-chip {
      background-color: $ads-dark-70;
      font-weight: bold;
      color: $ads-white;
      position: absolute;
      bottom: 8px;
      right: 8px;
    }
  }

  .card-footer {
    max-width: 90%;
  }

  .card-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .resource-type {
      font-size: 14px;
      font-weight: bold;
      display: block;
      color: $ads-blue-1;
      line-height: 28px;

      &.resource-type-xs {
        line-height: 24px;
      }
    }

    .draft-chip {
      font-weight: bold;
      background-color: $ads-light-blue;
    }

    .primary-control {
      text-decoration: none;

      &:focus {
        outline-color: $ads-navy;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .card-title {
        font-size: 16px;
        color: $ads-navy;
        font-weight: bold;
        line-height: 23px;
        height: 45px;
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    span {
      &.years-sm {
        letter-spacing: -0.5px;
      }

      &.years-xs {
        letter-spacing: -1px;
      }
    }

    .v-card__actions {
      padding: 0;
      position: relative;

      .card-left-note {
        height: 45px;
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .button-icon {
        box-sizing: border-box;
        border: 2px solid transparent;
        border-radius: 50%;
        padding: 4px;

        &:focus {
          border-color: $ads-navy;
          outline: none;
        }
      }
    }
  }
}
</style>
