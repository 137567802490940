import {apiBaseUrl, apiBaseUrlV2} from '@/store/config';
import api from '@/api';
import {delay} from '@/store/helpers';
import Vue from 'vue'
import axios from 'axios';
import {SortDirection, SortField} from 'resource_store_vue_client';
import {DATATABLE_ITEMS_STATUS, EDPUSH_ACTION, ResourceStatus} from '@/constants';
import {combineFilesAndOrderedLinks, capitalise, extractAndMapStatusCounts} from '@/transforms';

const CONFIG = (window as any).dlrhConfig;
/*
A dedicated Vuex module for Editor.
 */
const moduleEditor = {
  namespaced: true,
  state: {
    errorState: false,
    customResourceId: false,
    filters: {
      owner: '',
      resourcecategory: ''
    },
    filtersSelection: {
      checkboxOwner: [],
      checkboxCategory: []
    },
    editMode: false,
    removeProgressionsDialog: false,
    clearProgressions: false,
    lastDeletedResource: '',
    currentVersion: 1,
    notifications: [],
    resourceNotifications: [],
    resources: {
      items: [],
      metadata: {}
    },
    /*
The resourceDetails object structure matches the api response.data object (resource object from Elasticsearch), e.g.:
{
  archive: []
  audit: {iss: "searchv2", sub: "irvin.flack"}
  document: {ItemID: "AHA-DC211021151354", AccessLevel: null,…}
  draft: {ItemID: "AHA-DC211021151354", AccessLevel: null,…}
  record: {created: "2022-06-14T13:42:06Z", collection: "aboriginalhscattainment", version: 1,…}
  userAccess: {owner: ["irvin.flack"], scope: 0, editor: [], viewer: [], schools: []}
}
 */
    // resourceDetails' default in store must be null because UI needs to check if a resource is fetched or not
    resourceDetails: null,
    resourceFiles: null,
    // below fields are in line with the /resourceeditor Api parameters
    applicationUserSelected: '',
    application: CONFIG.APPLICATION,
    source: CONFIG.SOURCE,
    status: -1, // -1 = all, the value user selects from the Showing dropdown
    perPage: 16,
    pageNum: 1,
    keywords: '',
    // sort: 'default:asc',
    sourceIsEmpty: false,
    approversRaw: [],
    publishersRaw: [],
    reviewSubmitted: false,
    declineSubmitted: false,
    endorseSubmitted: false,
    publishSubmitted: false,
    unpublishSubmitted: false,
    stateMachineForResource: null,
    loadingAuditResources: null,
    auditResourcesError: null,
    auditResources: {},
    loadingStatusCounts: null,
    statusCountsError: null,
    statusCounts: [],
    filesToInclude: [],
    zipFiles: [],
    zipFileChildCount: 0,
    zipFileOriginalName: '',
    zipFileAlias: ''
  },
  getters: {
    items: state => state.resources.items,
    count: state => state.resources.metadata.count ? state.resources.metadata.count : 0,
    removeProgressionsDialog: state => state.removeProgressionsDialog,
    clearProgressions: state => state.clearProgressions,
    approvers: state => {
      const _approvers = state.approversRaw.map(el => {
        return {userId: el.userId, name: `${el.firstName} ${el.lastName}`, email: el.email};
      });
      return _approvers;
    },
    publishers: state => {
      const _publishers = state.publishersRaw.map(el => {
        return {userId: el.userId, name: `${el.firstName} ${el.lastName}`, email: el.email};
      });
      return _publishers;
    },
    userIdFirstApprover: (state, getters) => {
      return getters.approvers[0].userId;
    },
    // 4.3 - totally 8 resource statuses
    isArchived: state => state.resourceDetails?.record?.status === ResourceStatus.archived,
    isDeclined: state => state.resourceDetails?.record?.status === ResourceStatus.declined,
    isDraft: state => state.resourceDetails?.record?.status === ResourceStatus.draft,
    isEndorsed: state => state.resourceDetails?.record?.status === ResourceStatus.endorsed,
    isPending: state => state.resourceDetails?.record?.status === ResourceStatus.pending,
    isPublished: state => state.resourceDetails?.record?.status === ResourceStatus.published,
    isRejected: state => state.resourceDetails?.record?.status === ResourceStatus.rejected,
    isUnpublished: state => state.resourceDetails?.record?.status === ResourceStatus.unpublished,
    resourceStatusNumber: state => state.resourceDetails?.record?.status,
    resourceStatus: state => {
      let status;
      switch (state.resourceDetails?.record?.status) {
        case ResourceStatus.draft:
          status = DATATABLE_ITEMS_STATUS.DRAFT
          break;
        case ResourceStatus.pending:
          status = DATATABLE_ITEMS_STATUS.PENDING
          break;
        case ResourceStatus.endorsed:
          status = DATATABLE_ITEMS_STATUS.ENDORSED
          break;
        case ResourceStatus.declined:
          status = DATATABLE_ITEMS_STATUS.DECLINED
          break;
        case ResourceStatus.published:
          status = DATATABLE_ITEMS_STATUS.PUBLISHED
          break;
        case ResourceStatus.unpublished:
          status = DATATABLE_ITEMS_STATUS.UNPUBLISHED
          break;
        case ResourceStatus.archived:
          status = DATATABLE_ITEMS_STATUS.ARCHIVED
          break;
        case ResourceStatus.rejected:
          status = DATATABLE_ITEMS_STATUS.REJECTED
          break;
      }
      return status;
    },
    goToResourceList: state => {
      let files: any[] = [], links;
      // 1. files
      state.resourceDetails?.files?.forEach(f => {
        if (!f.Hidden) {
          files.push({
            FileName: f.FileName,
            DownloadUrl: f.DownloadUrl,
            ZipFileEntryPoint: f.ZipFileEntryPoint
          })
          // if ZipFileEntryPoint === DownloadUrl, it means there's not a different entry point, and UI only displays the download button
          // otherwise if ZipFileEntryPoint !== DownloadUrl, there is a different entry point to launch and open in a new tab, and UI displays the download button + the launch button

          // for a scorm zip (to skip containsFiles), DownloadUrl and ZipFileEntryPoint are different
          if (f.containsFiles?.length > 0 && f.ArchiveType !== 'SCORM') { // not a scorm zip
            files = files.concat(f.containsFiles).filter(f => !f.Hidden);
          }
        }
      })
      // 2. links
      if (state.resourceDetails?.links) {
        links = [...state.resourceDetails?.links];
      }
      if (files || links) {
        const res = combineFilesAndOrderedLinks(files, links);
        console.log('res = ', res);
        return res;
      } else {
        return [];
      }
    },
    orderedSources: state => {
      const containsFiles: any[] = [];
      state.resourceDetails?.files.forEach(f => {
        if (f.containsFiles.length > 0 && f.DownloadUrl === f.ZipFileEntryPoint) { // not a scorm zip
          const toInclude = f.containsFiles.filter(f => !f.Hidden);
          containsFiles.concat(toInclude);
        }
      });
      let files, links;
      if (state.resourceDetails?.files) {
        files = [...state.resourceDetails?.files].concat(containsFiles);
        console.log(files)
      }
      if (state.resourceDetails?.links) {
        links = [...state.resourceDetails?.links];
      }
      if (files || links) {
        const res = combineFilesAndOrderedLinks(files, links);
        return res;
      } else {
        return [];
      }
    },
    assignedTo: state => capitalise(state.resourceDetails?.record?.assignedTo),
    contentOwnerName: (state, _, __, rootGetters) =>
      rootGetters['metadata/getSourceNameById'](state.resourceDetails?.record?.collection),
    createdBy: state => capitalise(state.resourceDetails?.userAccess?.owner?.[0]),
    createdDate: state => state.resourceDetails?.record?.created,
    endorsedBy: state => state.resourceDetails?.record?.endorsedBy,
    publishedBy: state => state.resourceDetails?.record?.publishedBy,
    activeResourceId: state => state.resourceDetails?.record?.id,
    loadingAuditResources: state => state.loadingAuditResources,
    auditResourcesError: state => state.auditResourcesError,
    auditDataByResourceId: state => resourceId => state.auditResources[resourceId],
    resourceAuthor: state => state.resourceDetails?.userAccess?.owner?.[0],
    loadingStatusCounts: state => state.loadingStatusCounts,
    statusCountsError: state => state.statusCountsError,
    statusCounts: state => state.statusCounts
  },
  mutations: {
    SET_APPLICATION_USER_SELECTED(state, value) {
      state.applicationUserSelected = value;
    },
    UPDATE_ZIP_FILES(state, value) {
      const existingKey = state.zipFiles.findIndex(f => f.original === value.original)
      if (existingKey !== -1) {
        state.zipFiles[existingKey] = value
      } else {
        state.zipFiles = [ ...state.zipFiles, value];
      }
    },
    SET_ZIP_FILE_ORIGINAL_NAME(state, value) {
      state.zipFileOriginalName = value;
    },
    SET_ZIP_FILE_ALIAS(state, value) {
      state.zipFileAlias = value;
    },
    SET_FILES_TO_INCLUDE(state, value) {
      state.filesToInclude = [...value];
    },
    SET_ZIP_FILE_CHILD_COUNT(state, value) {
      state.zipFileChildCount = value
    },
    SET_STATE_MACHINE_FOR_RESOURCE(state, value) {
      state.stateMachineForResource = value;
    },
    SET_REVIEW_SUBMITTED(state, value) {
      state.reviewSubmitted = value;
    },
    SET_DECLINE_SUBMITTED(state, value) {
      state.declineSubmitted = value;
    },
    SET_ENDORSE_SUBMITTED(state, value) {
      state.endorseSubmitted = value;
    },
    SET_PUBLISH_SUBMITTED(state, value) {
      state.publishSubmitted = value;
    },
    SET_UNPUBLISH_SUBMITTED(state, value) {
      state.unpublishSubmitted = value;
    },
    SET_APPROVERS(state, approvers) {
      state.approversRaw = approvers;
    },
    SET_PUBLISHERS(state, publishers) {
      state.publishersRaw = publishers;
    },
    SET_DISPLAY_ERROR(state, errorState) {
      state.errorState = errorState
    },
    SET_CUSTOM_RESOURCE_ID(state, customIdState) {
      state.customResourceId = customIdState
    },
    SET_EDIT_MODE(state, val) {
      state.editMode = val;
    },
    SET_SOURCE_IS_EMPTY(state, val) {
      state.sourceIsEmpty = val;
    },
    SET_REMOVE_PROGRESSIONS_DIALOG(state, val) {
      state.removeProgressionsDialog = val;
    },
    SET_CLEAR_PROGRESSIONS(state, val) {
      state.clearProgressions = val;
    },
    SET_FILTERS(state, filters) {
      state.filters = Object.assign(state.filters, filters);
    },
    SET_FILTERS_SELECTION(state, value) {
      state.pageNum = 1;
      state.filtersSelection = value;
    },
    SET_RESOURCES(state, {items, metadata}) {
      state.resources.metadata = metadata;
      state.resources.items = items;
    },
    SET_RESOURCE_DETAILS(state, resource) {
      state.resourceDetails = resource; // ok to pass reference here, as each time it's always a new resource object from api
    },
    SET_RESOURCE_FILES(state, files) {
      state.resourceFiles = files;
    },
    SET_KEYWORDS(state, keywords) {
      state.keywords = keywords;
      state.pageNum = 1;
    },
    SET_STATUS(state, status) {
      state.status = status;
      state.pageNum = 1;
    },
    SET_IS_LOADING_AUDIT_DATA(state, loading) {
      state.loadingAuditResources = loading;
    },
    SET_AUDIT_DATA_ERROR(state, error) {
      state.auditResourcesError = error;
    },
    SET_AUDIT_DATA(state, {resourceId, data}) {
      state.auditResources = {
        ...state.auditResources,
        [resourceId]: data
      };
    },
    SET_IS_LOADING_STATUS_COUNTS(state, loading) {
      state.loadingStatusCounts = loading;
    },
    SET_STATUS_COUNTS_ERROR(state, error) {
      state.statusCountsError = error;
    },
    SET_STATUS_COUNTS(state, statusCounts) {
      state.statusCounts = statusCounts;
    },
    SET_PAGE_NUM(state, page) {
      state.pageNum = page
    }
  },
  actions: {
    async fetchResources({commit, state, rootState, rootGetters}, payload) {
      // check isLoading
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, {root: true});
      }
      let sortBy = payload.sortBy[0];
      if (sortBy === 'default') {
        sortBy = SortField.UPDATED;
      }
      let sortDesc = payload.sortDesc?.[0];
      if (sortDesc === true) {
        sortDesc = SortDirection.DESC;
      } else {
        sortDesc = SortDirection.ASC;
      }
      const sortStr = `${sortBy}:${sortDesc}`;

      // let query = `sort=${sortStr}&status=${state.status}`;
      let query = `sort=${sortStr}`;

      /**
       * 4.3 - starts to use "record.status=" to fetch resources.
       * if state.status (dropdown selection) is all (-1), then do not send the record.status param
       */
      if (state.status == ResourceStatus.all) {
        // when ALL selected, use searchDraftAndDocument=true
        query += '&searchDraftAndDocument=true';
      } else if (state.status == ResourceStatus.published) {
        // when Published selected, it is on the Document object, use searchDocument=true&record.status=5
        query += `&searchDraftAndDocument=true&record.status=${state.status}`;
      } else {
        // for other status, they are all on the Draft object, use searchDraft=true&record.status={status}
        query += `&searchDraftAndDocument=true&record.status=${state.status}`;
      }
      /*      if (state.status === 'draft') {
              // query += '&searchDraft=true&record.published=false';
              query += '&searchDraft=true&record.status=2';
            } else if (state.status === 'published') {
              // query += '&searchDocument=true&record.published=true';
              query += '&searchDocument=true&record.status=5';
            } else {
              query += '&searchDraftAndDocument=true';
            }*/

      if (state.keywords) {
        query += `&keyword=${state.keywords}`;
      }
      if (state.filters.resourcecategory) {
        query += `&ResourceCategory=${state.filters.resourcecategory}`;
      }
      if (state.filters.owner) {
        query += `&Source=${state.filters.owner}`;
      } else {
        query += '&Source=';
      }
      // const randString = Math.floor(Math.random() * 100000)

      // 4.3 - need to commit payload.page to state.pageNum
      commit('SET_PAGE_NUM', payload.page);
      // editorhomepage=true indicates that the api will return the flat structure for the table, instead of the ES result sets
      const resourcesURL = `${apiBaseUrlV2}/search?size=${payload.itemsPerPage}&page=${payload.page}&${query}&editorhomepage=true`;
      const config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            application: CONFIG.APPLICATION,
            source: CONFIG.EDITOR_SOURCE,
            uid: rootState.uid,
            contentarea: rootGetters['users/contentArea']
          })
        }
      };
      const response: any = await axios.post(`${resourcesURL}`, {}, config).finally(() => {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, {root: true});
        }
      });
      const items = response.data.resources;
      const metadata = response.data.meta;
      commit('SET_RESOURCES', {items, metadata});
      return response.data;
    },
    async fetchResource({commit, state, rootState, rootGetters}, payload) {
      // check isLoading
      if (!rootState.isLoading) {
        commit('SET_IS_LOADING', true, {root: true});
      }
      const resourcesURL = `${apiBaseUrlV2}/search?searchDraftAndDocument=true&ItemID=${payload.id}`;
      const config = {
        headers:
          {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              accept: 'application/json',
              // application: rootGetters['users/profileApplication'].id,
              application: payload.application || CONFIG.APPLICATION,
              source: payload.source || CONFIG.EDITOR_SOURCE,
              uid: rootState.uid,
              sessionid: rootState.sessionId,
              contentarea: rootGetters['users/contentArea']
            })
          }
      };

      const response = await api.resources.getResources(resourcesURL, config).finally(() => {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, {root: true});
        }
      });
      const resource = response.data.resources[0];
      commit('SET_RESOURCE_DETAILS', resource);
      commit('SET_RESOURCE_FILES', resource?.files);
      return resource;
    },
    async createResource({commit, state, rootState}, payload) {
      commit('SET_IS_LOADING', true, {root: true});
      try {
        const data = {
          isDraft: payload.isDraft,
          scope: payload.scope,
          application: payload.application,
          document: payload.document,
          files: payload.files,
          links: payload.links,
          zipFiles: payload.zipFiles,
          zipFileOriginalName: state.zipFileOriginalName,
          zipFileAlias: payload.zipFileAlias,
          filesToInclude: payload.filesToInclude,
          uid: rootState.uid,
          sessionId: rootState.sessionId
        };
        const resource = await api.resources.createResource(data, (pobj) => { // dlrhub-1898. pobj = progress obj
          commit('SET_LOAD_PERCENT', Math.round(pobj.loaded / pobj.total * 1000)/10, {root: true})
        })?.finally(() => {
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, {root: true});
          }
          commit('SET_FILES_TO_INCLUDE', []);
        });
        if (payload.displayAlert) {
          const alert = payload.isDraft ? {
            type: 'success',
            text: 'Your draft has been saved.'
          } : {
            type: 'success',
            text: 'Your resource has been published.'
          };
          commit('SET_ALERT', alert, {root: true});
        }
        return resource?.data;
      } catch (e) {
        console.error(e);
        if (payload.displayAlert) {
          const alert = {
            type: 'error',
            text: 'There was an error saving your resource.'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        if (payload.displayAlert) {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }
    },
    async savePublishedResource({commit, state, rootState}, payload) {
      commit('SET_IS_LOADING', true, {root: true});
      try {
        const resourceId = payload.document.ItemID;
        const repository = payload.document.Source;
        const files = payload.files;
        const links = payload.links;
        const scope = payload.scope;
        const filesToInclude = payload.filesToInclude;
        let resourcesURL = `${apiBaseUrl}/resourceeditor/published/${resourceId}?scope=${scope}`;

        const config = {
          headers:
            {
              ...Vue.prototype.$OAuth.buildHttpHeader({
                'Content-Type': 'multipart/form-data',
                application: payload.application,
                source: repository,
                uid: rootState.uid,
                sessionid: rootState.sessionId
              })
            },
            onUploadProgress: (pobj) => { // dlrhub 1898
              commit('SET_LOAD_PERCENT', Math.round(pobj.loaded / pobj.total * 1000)/10, {root: true})
            }
        };

        const bodyFormData = new FormData();

        // to save a published resource (document object needs to be passes as formData)
        bodyFormData.append('document', JSON.stringify({
          ...payload.document,
          DateModified: new Date().toISOString().substr(0, 19), // update modified date
        }));

        // files
        let filesToKeep: any[] = [];
        if (files?.every(file => file.DownloadUrl)) {
          resourcesURL += '&keepFiles=true'
        } else if (files) {
          for (let i = 0; i < files.length; i++) {
            if (files[i].file.size !== 0) { // filter out dummy binary file
              bodyFormData.append('files', files[i].file);
            } else { // for 0 bytes files
              state.resourceDetails?.files.forEach(rf => {
                if (rf.FileName === files[i].file.name) {
                  filesToKeep.push({'Key': rf.Key});
                }
              })
            }
          }
        }
        // 4.4 physically remove any files the user deleted from the UI
        const fileNames = files.map(f => {
          return f.file.name;
        });
        const deleted: string[] = [];
        state.resourceDetails?.files.forEach(rf => {
          if (!fileNames?.includes(rf.FileName)) {
            deleted.push(rf.Key);
          }
        });
        console.log('deleted = ', deleted);
        // append deleted array into filesToKeep
        if (deleted.length > 0) {
          const _deleted = deleted.map(dk => ({'Key': dk}));
          filesToKeep = filesToKeep.concat(_deleted);
        }
        bodyFormData.append('filesToKeep', JSON.stringify(filesToKeep));

        // 4.4 - fields for zip files
        const unpackonly = filesToInclude?.map(file => ({name: file})) || [];
        console.log(unpackonly)
        // bodyFormData.append('zipFileAlias', JSON.stringify(zipFileAlias));
        bodyFormData.append('unpackonly', JSON.stringify(unpackonly));

        // 4.3 - move links from document/draft
        bodyFormData.append('links', JSON.stringify(links));

        const resource = await api.resources.savePublished(resourcesURL, bodyFormData, config).finally(() => {
          // 4.4 physically remove any files the user deleted from the UI
          if (deleted.length > 0) {
            const paramArr = deleted.map(i => `key=${i}`);
            const param = paramArr.join('&');
            console.log(paramArr, param)
            // /resourceeditor/BZ-FIN-06/index?key=urh_financialmanagement_BZ-FIN-06/Test File 1.pdf&key=urh_financialmanagement_BZ-FIN-06/Test File 2.pdf
            const resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}/index?${param}`;
            return api.resources.deleteFiles(resourcesURL, config);
          }
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, {root: true});
          }
        });

        if (payload.displayAlert) {
          const alert = {
            type: 'success',
            text: 'Your resource has been saved.'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return resource.data;
      } catch (e) {
        console.error(e);
        if (payload.displayAlert) {
          const alert = {
            type: 'error',
            text: 'There was an error saving your resource.'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        if (payload.displayAlert) {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }
    },
    // when saving existing resource if published, save as draft or else save published
    async updateResource({commit, state, rootState, dispatch}, payload) {
      commit('SET_IS_LOADING', true, {root: true});
      try {
        const isPublished = payload.isPublished;
        const resourceId = payload.document.ItemID;
        const repository = payload.document.Source;
        const application = payload.application;
        const files = payload.files;
        const links = payload.links;
        const scope = payload.scope;
        const assigneeId = payload.assigneeId;
        const status = payload.status;
        const zipFiles = payload.zipFiles;

        let zipAliases = {};
        let zipUnpackOnly = {};
        zipFiles?.forEach(f => {
          if (f.original !== f.new && f.new !== '') {
            zipAliases = {
              ...zipAliases,
              [f.original]: f.new
            }
          }
          zipUnpackOnly = {
            ...zipUnpackOnly,
            [f.new || f.original]: f.unpackOnly.map(name => {return {name}})
          }
        })
        console.log('all zip files', JSON.stringify(zipFiles))
        console.log('zip files:', JSON.stringify(zipAliases))
        console.log('zip unpack:', JSON.stringify(zipUnpackOnly))

        let resourcesURL = `${apiBaseUrl}/resourceeditor/${isPublished ? 'published' : 'draft'}/${resourceId}?scope=${scope}`;

        const bodyFormData = new FormData();

        let filesToKeep: any[] = [];

        // if files contain only metadata means no changes in files
        if (files?.every(file => file.DownloadUrl)) {
          filesToKeep = files.map(f => { return { 'Key': f.Key }});
          console.log('no changes in files', filesToKeep)
        } else if (files) {
          for (let i = 0; i < files.length; i++) {
            // find binary files
            if (files[i].file?.size !== 0) { // filter out dummy binary file
              bodyFormData.append('files', files[i].file);
            } else { // for existng files
              state.resourceDetails?.files.forEach(rf => {
                if (rf.FileName === files[i].file.name) {
                  filesToKeep.push({'Key': rf.Key});
                }
              })
            }
          }
        }
        console.log('filesToKeep', filesToKeep)

        // 4.4 physically remove any files the user deleted from the UI

        // 1. find all file names in current form
        const fileNames = files.map(f => {
          return f.file?.name || f.FileName;
        });
        console.log('fileNames = ', fileNames);

        // 2. determine if deleted checking if it is not available in store
        const deleted: string[] = [];
        state.resourceDetails?.files.forEach(rf => {
          if (!fileNames?.includes(rf.FileName)) {
            deleted.push(rf.Key);
          }
        });
        console.log('deleted = ', deleted);

        // 3. append deleted array into filesToKeep there will be a second api call to handle the deleted files
        if (deleted.length > 0) {
          const _deleted = deleted.map(dk => ({'Key': dk}));
          filesToKeep = filesToKeep.concat(_deleted);
        }

        bodyFormData.append('filesToKeep', JSON.stringify(filesToKeep));

        // 4.4 - fields for zip files
        bodyFormData.append('unpackonly', JSON.stringify(zipUnpackOnly));

        // 4.3 - when updating files, need to separate new files from existing ones
        // bodyFormData.append('filesToKeep', JSON.stringify(filesToKeep));

        // 4.3 - move links from document/draft
        bodyFormData.append('links', JSON.stringify(links));

        // to update a published resource (document object expected in the Body as JSON)
        // to update a draft resource (draft object expected in the Body as JSON)
        if (isPublished) {
          const doc = {
            ...payload.document,
            DateModified: new Date().toISOString().substr(0, 19), // update modified date
          }
          bodyFormData.append('document', JSON.stringify(doc));
          bodyFormData.append('assigneeId', assigneeId);
        } else {
          // except for published status, all others are treated as draft
          const draft = {
            ...payload.document,
            DateModified: new Date().toISOString().substr(0, 19), // update modified date
          }
          bodyFormData.append('draft', JSON.stringify(draft));
          bodyFormData.append('assigneeId', assigneeId);
        }

        if (status) {
          resourcesURL += `&status=${status}`;
        }

        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'multipart/form-data',
              'application': application,
              'source': repository, // should match to source/owner
              'uid': rootState.uid,
              sessionid: rootState.sessionId,
              filerename: JSON.stringify(zipAliases)
            })
          },
          onUploadProgress: (pobj) => { // dlrhub 1898
            commit('SET_LOAD_PERCENT', Math.round(pobj.loaded / pobj.total * 1000)/10, {root: true})
          }
        };

        let resource = await api.resources.updateResource(resourcesURL, bodyFormData, config)
        // 4.4 physically remove any files the user deleted from the UI
        if (deleted.length > 0) {
          const paramArr = deleted.map(i => `key=${i}`);
          const param = paramArr.join('&');
          console.log(paramArr, param)
          // /resourceeditor/BZ-FIN-06/index?key=urh_financialmanagement_BZ-FIN-06/Test File 1.pdf&key=urh_financialmanagement_BZ-FIN-06/Test File 2.pdf
          const resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}/index?${param}`;
          resource = await api.resources.deleteFiles(resourcesURL, config);
        }
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, {root: true});
        }
        if (payload.displayAlert) {
          const alert = payload.isPublished ? {
            type: 'success',
            text: 'Your resource has been saved.'
          } : {
            type: 'success',
            text: 'Your draft has been saved.'
          };
          commit('SET_ALERT', alert, {root: true});
        }

        return resource.data;
      } catch (e) {
        console.error(e);
        if (payload.displayAlert) {
          const alert = {
            type: 'error',
            text: 'There was an error saving your resource.'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        if (payload.displayAlert) {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }
    },
    async publishOrUnPublishResource({commit, state, rootState, rootGetters, dispatch}, payload) {
      commit('SET_IS_LOADING', true, {root: true});
      try {

        const {ItemID: resourceId, Source: repository} = payload.document;
        const {isPublished, files, filesToInclude, links, application, originalId, zipFiles} = payload;
        const deleted: string[] = [];

        const scope = `scope=${payload.scope}`
        // if unpublishing retain sub resources, files and links
        const retainData = isPublished ? '&keepSubResources=true&keepFiles=true&keepLinks=true': ''

        let resourcesURL;
        if (originalId) {
          resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}?${scope}${retainData}&action=${isPublished ? 'unpublish' : 'publish'}&originalId=${originalId}`;
        } else {
          resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}?${scope}${retainData}&action=${isPublished ? 'unpublish' : 'publish'}`;
        }

        const bodyFormData = new FormData();

        console.log('action publishOrUnPublishResource', isPublished ? 'unpublishing...': 'publishing...')

        //only send payload for publish
        if (!isPublished) {
          let zipAliases = {};
          let zipUnpackOnly = {};
          zipFiles?.forEach(f => {
            if (f.original !== f.new && f.new !== '') {
              zipAliases = {
                ...zipAliases,
                [f.original]: f.new
              }
            }
            zipUnpackOnly = {
              ...zipUnpackOnly,
              [f.new || f.original]: f.unpackOnly.map(name => {return {name}})
            }
          })
          console.log('all zip files', JSON.stringify(zipFiles))
          console.log('zip files:', JSON.stringify(zipAliases))
          console.log('zip unpack:', JSON.stringify(zipUnpackOnly))

          let filesToKeep: any[] = [];

          // if files contain only metadata means no changes in files
          if (files?.every(file => file.DownloadUrl)) {
            filesToKeep = files.map(f => { return { 'Key': f.Key }});
            console.log('no changes in files', filesToKeep)
          } else if (files) {
            for (let i = 0; i < files.length; i++) {
              // find binary files
              if (files[i].file?.size !== 0) { // filter out dummy binary file
                bodyFormData.append('files', files[i].file);
              } else { // for existng files
                state.resourceDetails?.files.forEach(rf => {
                  if (rf.FileName === files[i].file.name) {
                    filesToKeep.push({'Key': rf.Key});
                  }
                })
              }
            }
          }
          console.log('filesToKeep', filesToKeep)

          // 1. find all file names in current form
          const fileNames = files?.map(f => {
            return f.file?.name || f.FileName;
          });
          console.log('fileNames = ', fileNames);

          // 2. determine if deleted checking if it is not available in store
          // if removed from form, mark the files for deletion
          state.resourceDetails?.files.forEach(rf => {
            if (!fileNames?.includes(rf.FileName)) {
              deleted.push(rf.Key);
            }
          });
          console.log('deleted = ', deleted);

          // 3. append deleted array into filesToKeep there will be a second api call to handle the deleted files
          if (deleted.length > 0) {
            const _deleted = deleted.map(dk => ({'Key': dk}));
            filesToKeep = filesToKeep.concat(_deleted);
          }

          bodyFormData.append('filesToKeep', JSON.stringify(filesToKeep));

          // 4.4 - fields for zip files
          const unpackonly = filesToInclude?.map(file => ({name: file}));
          // bodyFormData.append('zipFileAlias', JSON.stringify(zipFileAlias));
          bodyFormData.append('unpackonly', JSON.stringify(zipUnpackOnly));

          // 4.3 - move links from document/draft
          bodyFormData.append('links', JSON.stringify(links || []));

          const draft = {
            ...payload.document
          }
          bodyFormData.append('draft', JSON.stringify(draft));
        } // end payload for publish

        bodyFormData.append('owner', payload.owner || rootGetters['editor/resourceAuthor']);

        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'multipart/form-data',
              application: application,
              source: repository, // should match to source/owner
              uid: rootState.uid,
              id: resourceId,
              sessionid: rootState.sessionId
            })
          }
        };
        // if to unpublish a published resource, no payload is required

        const resource = await api.resources.publish(resourcesURL, bodyFormData, config).finally(() => {
          // 4.4 physically remove any files the user deleted from the UI only when publishing
          if (deleted.length > 0 && !isPublished) {
            // for 4.4.1
            // const paramArr = isPublished? ['key=all'] : deleted.map(i => `key=${i}`);
            const paramArr = deleted.map(i => `key=${i}`);
            const param = paramArr.join('&');
            console.log(paramArr, param)
            // /resourceeditor/BZ-FIN-06/index?key=urh_financialmanagement_BZ-FIN-06/Test File 1.pdf&key=urh_financialmanagement_BZ-FIN-06/Test File 2.pdf
            const resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}/index?${param}`;
            return api.resources.deleteFiles(resourcesURL, config);
          }
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, {root: true});
          }
        });
        if (payload.displayAlert) {
          const alert = payload.isPublished ? {
            type: 'success',
            text: 'Your resource has been unpublished.'
          } : {
            type: 'success',
            text: 'Your resource has been published.'
          };
          commit('SET_ALERT', alert, {root: true});
        }

        if (resource.data) {
          // call EdPush Post
          const edPushResult = await dispatch('declineEndorsePendingResource', {
            assignee: '',
            assigneeId: '',
            comments: '',
            action: isPublished ? EDPUSH_ACTION.UNPUBLISH : EDPUSH_ACTION.PUBLISH,
            ...(payload.owner && {
              source: repository,
              resourceId,
              name: payload.document.Name
            })
          });
        }

        return resource.data;
      } catch (e) {
        console.error(e);
        if (payload.displayAlert) {
          const alert = {
            type: 'error',
            text: 'There was an error saving your resource.'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        if (payload.displayAlert) {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }
    },
    async publishUnPublishResourcesWithData({rootState, commit, dispatch}, { scope, documents, isPublished, displayMessage = true}) {
      return await Promise.all(documents.map(async document => {
        const {
          ItemID: resourceId,
          CreatedBy: owner,
          Application: application,
          Source: source
        } = document;

        const resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}?keepSubResources=true&keepFiles=true&keepLinks=true&scope=${scope}&action=${isPublished?'unpublish':'publish'}`;

        const bodyFormData = new FormData();

        bodyFormData.append('owner', owner);

        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'multipart/form-data',
              application,
              source,
              id: resourceId,
              uid: rootState.uid,
              sessionid: rootState.sessionId
            })
          }
        };

        try {
          const response = await api.resources.publish(resourcesURL, bodyFormData, config);
          await dispatch('declineEndorsePendingResource', {
            assignee: '',
            assigneeId: '',
            comments: '',
            action: isPublished? EDPUSH_ACTION.UNPUBLISH : EDPUSH_ACTION.PUBLISH,
            application,
            source,
            resourceId,
            name: document.Name
          });

          const successMessage = `Your resources have been ${isPublished? 'unpublished' : 'published'}`

          displayMessage && commit('SET_ALERT', {
            type: 'success',
            text: successMessage
          }, { root: true });
          return response;
        } catch (e: any) {
          displayMessage && commit('SET_ALERT', {type: 'error', text: 'There was an error unpublishing your resources.'}, {root: true});
        } finally {
          if (displayMessage) {
            commit('SET_DISPLAY_ALERT', true, {root: true});
            setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
          }
        }
      }));
    },
    async unpublishResources({rootState, commit, dispatch}, {scope, documents}) {
      await Promise.all(documents.map(async document => {
        const {ItemID: resourceId, CreatedBy: owner, Application: application, Source: source} = document;

        const resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}?scope=${scope}&action=unpublish`;

        const bodyFormData = new FormData();
        bodyFormData.append('document', JSON.stringify(document));
        bodyFormData.append('owner', owner);

        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'multipart/form-data',
              application,
              source,
              uid: rootState.uid,
              sessionid: rootState.sessionId
            })
          }
        };

        try {
          await api.resources.publish(resourcesURL, bodyFormData, config);
          await dispatch('declineEndorsePendingResource', {
            assignee: '',
            assigneeId: '',
            comments: '',
            action: EDPUSH_ACTION.UNPUBLISH,
            application,
            source,
            resourceId,
            name: document.Name
          });

          commit('SET_ALERT', {type: 'success', text: 'Your resources have been unpublished.'}, {root: true});
        } catch (e: any) {
          commit('SET_ALERT', {type: 'error', text: 'There was an error unpublishing your resources.'}, {root: true});
        } finally {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }));
    },
    async deleteResource({commit, state, rootState, dispatch}, payload) {
      try {
        commit('SET_IS_LOADING', true, {root: true});
        const resourcesURL = `${apiBaseUrl}/resourceeditor/${payload.resourceId}`;

        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              application: payload.application,
              source: payload.repository, // should match to source/owner
              uid: rootState.uid,
              sessionid: rootState.sessionId
            })
          }
        };
        const res = await api.resources.deleteResource(resourcesURL, config);
        if (res.status === 200) {
          // const resourcesURL = `${apiBaseUrl}/resourceeditor/${payload.resourceId}/index?key=all`;
          // await api.resources.deleteFiles(resourcesURL, config);
          await delay(1000);
          commit('SET_IS_LOADING', false, {root: true});
        }
        const alert = {
          type: 'success',
          text: 'Your resource has been deleted.'
        }
        commit('SET_ALERT', alert, {root: true});
        // commit('SET_LAST_DELETED_RESOURCE', payload.resourceId);
        return res
      } catch (e: any) {
        console.error(e);
        const alert = {
          type: 'error',
          text: 'There was an error deleting your resource.'
        }
        /**
         * show generic error dialog for status 401 before any other errors,
         * ie: when user tries to delete resource via url.
         * */
        if (e.response.status === 401) {
          commit('SET_DISPLAY_ERROR', true)
        }
        commit('SET_ALERT', alert, {root: true});
        // commit('SET_LAST_DELETED_RESOURCE', '');

      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
    },
    async deleteResources({commit, rootState}, payload) {
      try {
        commit('SET_IS_LOADING', true, {root: true});

        const responses = await Promise.all(payload.map(({resourceId, application, repository}) => {
          const resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}`;
          const config = {
            headers: {
              ...Vue.prototype.$OAuth.buildHttpHeader({
                'Content-Type': 'application/json',
                application,
                source: repository, // should match to source/owner
                uid: rootState.uid,
                sessionid: rootState.sessionId
              })
            }
          };
          return api.resources.deleteResource(resourcesURL, config);
        }));

        const alert = {
          type: 'success',
          text: 'Your resources have been deleted.'
        };
        commit('SET_ALERT', alert, {root: true});

        return responses.every(({status}) => status === 200);
      } catch (e: any) {
        const alert = {
          type: 'error',
          text: 'There was an error deleting your resources.'
        }
        /**
         * show generic error dialog for status 401 before any other errors,
         * ie: when user tries to delete resource via url.
         * */
        if (e.response.status === 401) {
          commit('SET_DISPLAY_ERROR', true);
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
    },
    async copyResource({commit, state, rootState, dispatch, rootGetters}, {
      originalResourceId,
      resourceId,
      resourceName,
      repository,
      application,
      appendCustomIdQuery
    }) {
      let copiedResource: any = null;
      commit('SET_IS_LOADING', true, {root: true});

      //Create as query that sends a customId
      const customId = '';
      try {
        const requestData: any = {
          uid: rootState.uid,
          originalResourceId,
          resourceId,
          action: 'copy',
          source: repository,
          // application: rootGetters['users/profileApplication'].id,
          application: state.applicationUserSelected ? state.applicationUserSelected : state.resourceDetails?.audit?.iss,
          sessionid: rootState.sessionId
        }

        const alert = {
          type: 'success',
          text: `Successfully made a copy of '${resourceName}'`
        }

        //if payload has appendCustomIdQuery then add customId=${resourceId} query to the url
        if (appendCustomIdQuery === true) {
          requestData.customIdQuery = true;
        } else {
          requestData.customIdQuery = false;
        }
        const copiedResourceBeforeFinalise = await api.myresources.copyResource(apiBaseUrl, requestData)

        if (copiedResourceBeforeFinalise?.data) {
          let copiedResourceDocument = {
            Name: 'Copy of'
          }

          // when published document is cloned record.published would be false but the data would be in the document
          if (copiedResourceBeforeFinalise?.data?.record.published || !copiedResourceBeforeFinalise?.data.draft.Name) {
            copiedResourceDocument = copiedResourceBeforeFinalise.data.document
          } else {
            copiedResourceDocument = copiedResourceBeforeFinalise.data.draft
          }
          requestData.modifiedDocument = {
            ...copiedResourceDocument,
            Name: 'Copy of ' + copiedResourceDocument.Name,
            ResourceURL: '',
            DateCreated: copiedResourceBeforeFinalise.data.record.created,
            DateModified: copiedResourceBeforeFinalise.data.record.created,
            DateResCreated: copiedResourceBeforeFinalise.data.record.created,
            ItemID: copiedResourceBeforeFinalise.data.record.id
          }
          requestData.resourceId = copiedResourceBeforeFinalise.data.record.id;
          copiedResource = await api.myresources.finaliseCopyResource(apiBaseUrl, requestData);
          commit('SET_RESOURCE_DETAILS', copiedResource.data);
          // set successful alert
          commit('SET_ALERT', alert, {root: true});
        }
      } catch (e) {
        console.error(e);
        const alert = {
          type: 'error',
          text: 'There was an error copying your resource'
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
      return copiedResource.data;
    },
    async getResourceId({commit, state, rootState, dispatch, rootGetters}, {resourceId, repository, application}) {
      try {
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              // application: rootGetters['users/profileApplication'].id,
              application: state.applicationUserSelected ? state.applicationUserSelected : state.resourceDetails?.audit?.iss,
              source: 'dlrh', // should match to source/owner
              uid: rootState.uid,
              sessionid: rootState.sessionId
            })
          }
        };
        const url = `${apiBaseUrl}/resourceeditor/${resourceId}?checkId=true`
        const res = await api.resources.getResourceId(url, config)
        return res
      } catch (e: any) {
        console.error(e)
      }
    },
    async getReviewersList({state, rootState, commit, rootGetters}, source) {
      try {
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              // application: rootGetters['users/profileApplication'].id,
              application: state.applicationUserSelected ? state.applicationUserSelected : state.resourceDetails?.audit?.iss,
              source,
              uid: rootState.uid,
            })
          }
        };

        const url = `${apiBaseUrl}/resourceeditor/getAllApproversPublishers/${source}`;
        const res = await api.resources.getApproversPublishers(url, config);
        commit('SET_APPROVERS', res.data.approvers);
        commit('SET_PUBLISHERS', res.data.publishers);
      } catch (e: any) {
        console.error(e);
      }
    },
    async submitForReview({state, rootState, rootGetters}, {type, application, assignee, assigneeId, comments, source, title, recordId}) {
      try {
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              // application: rootGetters['users/profileApplication'].id,
              application: application ? application : state.applicationUserSelected ? state.applicationUserSelected : state.resourceDetails?.audit?.iss,
              source: source || state.resourceDetails.draft.Source,
              uid: rootState.uid,
              sessionid: rootState.sessionId,
              resourcetitle: title || state.resourceDetails?.draft.Name
            })
          }
        };
        const data = {type, assignee, assigneeId, comments};
        const url = `${apiBaseUrl}/resourceeditor/state/${recordId || state.resourceDetails.record.id}?action=${EDPUSH_ACTION.SUBMIT}`;
        const res = await api.resources.postResourceStatus(url, data, config);
        return res;
      } catch (e: any) {
        console.error(e);
      }
    },
    async declineEndorsePendingResource({state, rootState, rootGetters}, {
      type,
      application,
      assignee,
      assigneeId,
      comments,
      action,
      source = state.resourceDetails?.draft?.Source,
      resourceId = state.resourceDetails?.record?.id,
      name = state.resourceDetails?.draft?.Name
    }) {
      try {
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              // application: rootGetters['users/profileApplication'].id,
              application: application ? application : state.applicationUserSelected ? state.applicationUserSelected : state.resourceDetails?.audit?.iss,
              source,
              uid: rootState.uid,
              sessionid: rootState.sessionId,
              resourcetitle: name
            })
          }
        };
        const data = {type, assignee, assigneeId, comments};
        // update MongoDB
        const url = `${apiBaseUrl}/resourceeditor/state/${resourceId}?action=${action}`;
        const res = await api.resources.postResourceStatus(url, data, config);
        return res;
      } catch (e: any) {
        console.error(e);
      }
    },
    async updateResourceStatus({state, rootState, rootGetters}, {action, application, assigneeId, source, recordId}) {
      try {
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              application: application ? application : state.applicationUserSelected ? state.applicationUserSelected : state.resourceDetails?.audit?.iss,
              source: source || state.resourceDetails.draft.Source,
              sessionid: rootState.sessionId,
              uid: rootState.uid,
            })
          }
        };

        const data = {assigneeId};
        // elastic DB update
        const url = `${apiBaseUrl}/resourceeditor/status/${recordId || state.resourceDetails.record.id}?status=${action}`;
        const res = await api.resources.postResourceStatus(url, data, config);
        return res;
      } catch (e: any) {
        console.error(e);
      }
    },
    async getStateMachineForResource({state, rootState, rootGetters}, resourceId) {
      try {
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              // application: rootGetters['users/profileApplication']?.id,
              application: state.applicationUserSelected ? state.applicationUserSelected : state.resourceDetails?.audit?.iss,
              source: state.resourceDetails?.record?.collection,
              sessionid: rootState.sessionId,
              uid: rootState.uid,
            })
          }
        };
        const url = `${apiBaseUrl}/resourceeditor/state/${resourceId}`;
        const res = await api.resources.getStateMachineForResource(url, config);
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    async loadAuditDataByResource({state, commit}, resourceId) {
      if (!state.auditResources[resourceId]) {
        commit('SET_IS_LOADING_AUDIT_DATA', true);

        const config = {
          headers: Vue.prototype.$OAuth.buildHttpHeader({'Content-Type': 'application/json'})
        };

        try {
          const {data} = await axios.get(`${apiBaseUrl}/audit/${resourceId}`, config);
          commit('SET_AUDIT_DATA', {resourceId, data});
        } catch (e) {
          commit('SET_AUDIT_DATA_ERROR', e);
        }

        commit('SET_IS_LOADING_AUDIT_DATA', false);
      }
    },
    async loadStatusCounts({rootState, rootGetters, commit}) {
      commit('SET_IS_LOADING_STATUS_COUNTS', true);

      try {
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              Accept: 'application/json',
              'Content-Type': 'application/json',
              // application: rootGetters['users/profileApplication']?.id,
              application: CONFIG.APPLICATION,
              sessionid: rootState.sessionId,
              source: rootGetters['users/sourcesUserProfile'][0].id,
              uid: rootState.uid,
            })
          }
        };
        const userSource = rootGetters['users/sourcesStringUserProfile'];
        const resourcesUrls = [
          `${apiBaseUrl}/resourceeditor/statuscount/pending?source=${userSource}`,
          `${apiBaseUrl}/resourceeditor/statuscount/declined?source=${userSource}`
        ];
        const requests = resourcesUrls.map(url => axios.get(url, config));

        const responses = await axios.all(requests);
        commit('SET_STATUS_COUNTS', extractAndMapStatusCounts(responses));
      } catch (e) {
        commit('SET_STATUS_COUNTS_ERROR', e);
      }

      commit('SET_IS_LOADING_STATUS_COUNTS', false);
    },
  },
};

export default moduleEditor;
