<template>
  <div class="card--wrapper">
    <v-btn
      v-if="enableSelection"
      class="btn-select-resource"
      small
      white
      fab
      aria-label="select resource"
      @click="cardClickHandler"
      @keyup.enter="cardClickHandler"
    >
      <v-icon>{{ selected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
    </v-btn>
    <v-card
      :width="size"
      :height="cardHeight"
      :class="{'rounded-0': !rounded}"
      :title="collection.Name"
    >
      <div
        class="image-container d-flex"
      >
        <v-img
          v-if="collection.document.ThumbnailImage"
          :src="collection.document.ThumbnailImage"
          :lazy-src="collection.document.ThumbnailImage"
          height="100%"
          position="left"
        />
        <ImageCollage
          v-else
          :images="collectionImages"
        />
      </div>

      <template v-if="!hubCollection">
        <ActionsMenu
          class="floating-action-menu"
          icon
          bottom
          offset-y
          left
          :external-data="collection"
          :items="isOwner ? ownerActions : isEditor ? editorActions : defaultActions"
          @clickedAction="handleAction"
        />
      </template>
      <div class="card-info">
        <div class="card-info--heading mt-2">
          <v-card-title
            class="pt-0 pb-0"
            :class="{'px-2': size < 350 }"
          >
            <a
              :id="collection.document.itemID"
              tabindex="0"
              class="primary-control"
              @click="goToCollection(collection.document)"
              @keyup.enter="goToCollection(collection.document)"
            >
              <h3
                class="card-title"
                :class="{'card-title-xl': size > 450, 'card-title-md': size < 350}"
              >
                {{ collection.document.Name ? collection.document.Name : 'No title created' }}
              </h3>
            </a>
            <span class="sub-title text-body-2">
              {{ collection.document.KLA?.join(', ') }}
              <template v-if="(collection.document.KLA && collection.document.KLA.length > 0) && (collection.document.Stages && collection.document.Stages?.length > 0)">|</template>
              <template v-if="collection.document.Stages?.length > 0">
                Stage {{ stages.join().replace('ES','Early Stage') }}
              </template>
            </span>
            <span
              class="card-desc"
              :class="{'card-desc-md': size > 400}"
            >
              {{ collection.document.Description }}
            </span>
          </v-card-title>
        </div>
        <v-card-actions class="d-flex justify-space-between">
          <span class="ml-4 resource-length text-body-2 text--darken-4">{{ subResources }} resources </span>
          <Chip
            v-if="hubCollection && isFollowing"
            chipClass="status-chip px-4 mr-2 mb-2"
            small
            style="font-weight: bold"
            :textColor="ADS_Colors.Navy"
            text="FOLLOWING"
            :color="ADS_Colors.Blue_3"
          />
          <span
            v-if="!hubCollection"
            class="card-footer"
          >
            <AvatarList
              v-if="members"
              class="mr-1 mb-2"
              :items="members"
              :show-only="3"
            />
          </span>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import resourceDefaultImage from '@/assets/default_myresources_image.png';
import ActionsMenu from '@/views/MyResourceDetailsPage/ActionsMenu/ActionsMenu';
import {RESOURCE_CATEGORIES} from '@/constants';
import {mapGetters} from 'vuex';
import AvatarList from '@/components/AvatarList'
import ImageCollage from '@/components/ImageCollage'
import {ADS_Colors, Chip} from '@nswdoe/doe-ui-core'

export default {
  name: 'ResourceCollectionsCard',
  components: {
    Chip,
    ImageCollage,
    AvatarList,
    ActionsMenu,
  },
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    size: {
      type: [Number, String],
      default: 350
    },
    height: {
      type: [Number, String],
      default: 400
    },
    rounded: {
      type: Boolean,
      default: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    enableSelection: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      moment,
      ADS_Colors,
      dialogCopyLink: false,
      inviteMembersDialog: false,
      showEditMetaDataDialog: false,
      showInformationDrawer: false,
      deleteCollectinDialog: false,
      selected: this.isSelected,
      resourceDefaultImage,
      ownerActions: [
        {
          id: 'inviteMembers',
          name: 'Invite Members',
          tag: 'a',
          icon: 'group_add'
        },
        {
          id: 'editCollection',
          name: 'Edit Details',
          tag: 'a',
          icon: 'mdi-pencil-outline'
        },
        {
          id: 'copyCollection',
          name: 'Make a copy',
          tag: 'a',
          icon: 'mdi-content-copy'
        },
        {
          id: 'info',
          name: 'Information',
          tag: 'a',
          icon: 'mdi-information-outline'
        },
        {
          id: 'delete',
          name: 'Delete collection for everyone',
          tag: 'a',
          icon: 'mdi-delete-outline',
        }
      ],
      editorActions: [
        {
          id: 'inviteMembers',
          name: 'Invite Members',
          tag: 'a',
          icon: 'group_add'
        },
        {
          id: 'editCollection',
          name: 'Edit Details',
          tag: 'a',
          icon: 'mdi-pencil-outline'
        },
        {
          id: 'copyCollection',
          name: 'Make a copy',
          tag: 'a',
          icon: 'mdi-content-copy'
        },
        {
          id: 'info',
          name: 'Information',
          tag: 'a',
          icon: 'mdi-information-outline'
        },
        {
          id: 'leaveCollection',
          name: 'Leave Collection',
          tag: 'a',
          icon: 'mdi-information-outline',
        }
      ],
      defaultActions: [
        {
          id: 'inviteMembers',
          name: 'Invite Members',
          tag: 'a',
          icon: 'group_add'
        },
        {
          id: 'copyCollection',
          name: 'Make a copy',
          tag: 'a',
          icon: 'mdi-content-copy'
        },
        {
          id: 'info',
          name: 'Information',
          tag: 'a',
          icon: 'mdi-information-outline'
        },
        {
          id: 'leaveCollection',
          name: 'Leave Collection',
          tag: 'a',
          icon: 'mdi-information-outline'
        }
      ]
    }
  },
  watch: {
    isSelected(val) {
      this.selected = val
    }
  },
  computed: {
    ...mapGetters({
      allStages: 'metadata/stages'
    }),
    hubCollection() {
      return this.item?.record?.isResourceCollection && this.source !== 'collections'
    },
    isFollowing() {
      return this.item.userAccess.followers.includes(this.userId)
    },
    application() {
      return this.item?.audit?.iss
    },
    source() {
      return this.item?.record?.collection
    },
    userId() {
      return this.$store.getters['users/userProfile']?.userId?.toLowerCase() || '';
    },
    isOwner() {
      return this.item?.userAccess?.owner.includes(this.userId)
    },
    isEditor() {
      return this.item?.userAccess?.editor.includes(this.userId)
    },
    isViewer(){
      return this.item?.userAccess?.viewer.includes(this.userId)
    },
    collectionImages(){
      // @TODO subResources will not have images
      return this.item.subResources?.map( item => item.image).filter(n=>n)
    },
    collection() {
      return this.item;
    },
    members() {
      return [ ...this.item.userAccess.editor, ...this.item.userAccess.viewer]
    },
    altDescription(){
      return 'this is cover image.'
    },
    itemId() {
      return this.collection.ItemID;
    },
    stages() {
      return [ ...this.collection?.document?.Stages ].sort()
    },
    cardHeight() {
      return this.$vuetify.breakpoint.mdAndDown ? this.height + 20 : this.height;
    },
    subResources(){
      return this.item.subResources ? this.item.subResources.length : 0
    },
    editorPage() {
      const ret = this.$route.query.rce === 'true' || this.$route.params.rce == true || this.$route.params.rce
      console.log(ret, 'return editorpage', this.$route.query)
      return ret
    },
  },
  methods: {
    getCategoryDetails(category) {
      return RESOURCE_CATEGORIES.find(cat => cat.id === category) || null;
    },
    handleAction({action, externalData}) {
      this.$emit('action', {action, externalData});
    },
    goToCollection(collection) {
      console.log({
        id: collection.ItemID,
        source: this.source,
        application: this.application
      })
      this.$store.commit('SET_COLLECTION_VIEW', true);
      this.$router.push({
        name: 'CollectionBoardPage',
        query: {
          rce: this.editorPage ? true: false
        },
        params: {
          id: collection.ItemID,
          source: this.source,
          application: this.application,
          rce: this.editorPage ? true: false
        }});
    },
    cardClickHandler() {
      this.$emit('onSelect', { select: !this.selected, item: this.item });
      console.log('selection ' + this.selected + ' item ' + this.item.ItemID)
    },
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.card--wrapper {
  position: relative;
  .btn-select-resource {
    position: absolute;
    right: 20px;
    top: 8px;
    z-index: 1;
    opacity: 0.8;

    &.v-btn:focus {
      outline-color: white !important;
    }
  }
  &.dense .btn-select-resource {
    right: 10px;
  }

}

.v-card {
  display: flex;
  flex-direction: column;

  .image-container {
    height: 240px;
    position: relative;

    .editor-chip {
      background-color: $ads-dark-70;
      font-weight: bold;
      color: $ads-white;
      position: absolute;
      bottom: 8px;
      right: 8px;
    }
  }
  .editor-chip{
    padding: 0 15px;
    border-radius: 4px;
    position: absolute;
    background-color: $ads-dark-70;
    font-weight: bold;
    color: $ads-white;
    top: 15px;
    left: 10px;
    z-index: 1;
  }

   ::v-deep button.v-btn{
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: $ads-white;
      z-index: 2;
  }

  .card-footer {
    max-width: 90%;
  }

  span.resource-length{
    color: $ads-dark-80;
  }

  .card-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .resource-type {
      font-size: 14px;
      font-weight: bold;
      display: block;
      color: $ads-blue-1;
      line-height: 28px;

      &.resource-type-xs {
        line-height: 24px;
      }
    }

    .draft-chip {
      font-weight: bold;
      background-color: $ads-light-blue;
    }

    .primary-control {
      text-decoration: none;
      display: block;
      width: 100%;

      &:focus {
        outline-color: $ads-navy;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .card-title {
        font-size: 18px;
        color: $ads-navy;
        font-weight: bold;
        line-height: 23px;
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &.card-title-md {
          font-size: 16px;
          line-height: 23px;
          -webkit-line-clamp: 2;
        }

        &.card-title-xl {
          font-size: 20px;
          line-height: 24px;
          height: 48px;
          -webkit-line-clamp: 2;
        }
      }
    }

  .card-desc {
    font-size: 12px;
    line-height: 14px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &.card-desc-md {
      font-size: 12px;
      line-height: 14px;
      -webkit-line-clamp: 3;
    }
  }
  .sub-title {
      display: block;
      font-size: 14px;
      font-weight: normal;
      color: $ads-dark-70;
      line-height: 30px;
    }

    span {
      &.years-sm {
        letter-spacing: -0.5px;
      }

      &.years-xs {
        letter-spacing: -1px;
      }
    }

    .v-card__actions {
      padding: 0;
      position: relative;

      .button-icon {
        box-sizing: border-box;
        border: 2px solid transparent;
        border-radius: 50%;
        padding: 4px;

        &:focus {
          border-color: $ads-navy;
          outline: none;
        }
      }
    }
  }
}
</style>
