<template>
  <div>
    <AppBar
      v-if="$vuetify.breakpoint.xsOnly"
      showBackButton
      :color="ADS_Colors.Navy"
    >
      <template #title>
        <h1 class="appbar-title">
          {{ $store.state.itemDetail && $store.state.itemDetail.name }}
        </h1>
      </template>
    </AppBar>
    <div
      v-resize="calcCardSize"
      class="resource-details__wrapper"
    >
      <div class="content">
        <v-row
          :class="{'pa-6': $vuetify.breakpoint.mdAndUp, 'ma-0 pa-2': $vuetify.breakpoint.xsOnly, 'small-screen': $vuetify.breakpoint.smOnly }"
        >
          <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            cols="12"
            sm="4"
            style="display: flex; justify-content: start;"
          >
            <AdsButton
              v-if="$store.state.previousPath !== '/'"
              tertiary
              icon="mdi-arrow-left"
              buttonText="Back"
              class="pl-0"
              @click="$router.back()"
              @keyup.enter="$router.back()"
            />
            <AdsButton
              v-else
              tertiary
              icon="mdi-arrow-left"
              buttonText="Back to Hub Resources"
              class="pl-0"
              @click="$router.push({ name: 'HubResourcesPage' })"
              @keyup.enter="$router.push({ name: 'HubResourcesPage' })"
            />
          </v-col>
          <v-col
            class="pa-0"
            cols="12"
            sm="4"
            :class="{ 'pt-0': $vuetify.breakpoint.xsOnly, 'mid-screen': $vuetify.breakpoint.mdAndUp, 'sm-screen': $vuetify.breakpoint.smOnly}"
          >
            <AdsButton
              tertiary
              icon="mdi-export-variant"
              buttonText="Share card"
              @click="dialogShare = true;"
              @keyup.enter="dialogShare = true;"
            />
          </v-col>
        </v-row>
        <v-row class="content-wrapper">
          <v-col
            cols="12"
            lg="8"
            md="8"
            sm="12"
            class="main-col"
          >
            <v-card :class="{'pa-8': $vuetify.breakpoint.mdAndUp, 'pa-4': $vuetify.breakpoint.smOnly, 'pa-0': $vuetify.breakpoint.xsOnly}">

              <v-responsive
                v-if="$store.state.isLoading"
                aspect-ratio="1.8"
              >
                <v-skeleton-loader
                  height="200px"
                  width="100%"
                  type="image"
                />
                <v-skeleton-loader
                  class="ml-n10"
                  type="article"
                />
              </v-responsive>
              <ResourceDetails v-else />
            </v-card>
          </v-col>
          <v-col
            ref="side-column"
            cols="12"
            lg="4"
            md="4"
            sm="12"
            class="side-col"
          >
            <v-skeleton-loader
              v-if="$store.state.isLoading"
              height="200px"
              width="100%"
              type="image"
            />
            <template v-else>
              <template v-if="($route && $route.params.collection) || $store.state.collectionView">
                <OtherResources
                  v-if="otherResourcesFromCollection.length > 0"
                  heading="More from this collection"
                  :cardWidth="cardWidth"
                  :resources="otherResourcesFromCollection"
                  collection-view
                />
              </template>
              <template v-else>
                <OtherResources
                  v-if="otherSearchResults.length > 0"
                  heading="More from your search"
                  :cardWidth="cardWidth"
                  :resources="otherSearchResults"
                />
                <OtherResources
                  v-else
                  heading="Most viewed"
                  :cardWidth="cardWidth"
                  :resources="mostViewedResources"
                />
              </template>
            </template>
          </v-col>
        </v-row>

        <ShareThisCardDialog
          v-model="dialogShare"
          heading="Your colleagues will be invited to view this card."
          :resourceName="$store.state.itemDetail && $store.state.itemDetail.name ? $store.state.itemDetail.name : ''"
          :resourceUrl="$store.state.itemDetail && $store.state.itemDetail.url ? $store.state.itemDetail.url : ''"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {AdsButton, AppBar, ADS_Colors} from '@nswdoe/doe-ui-core';
import OtherResources from '@/views/HubResourceDetailsPage/OtherResources';
import ResourceDetails from '@/views/HubResourceDetailsPage/ResourceDetails';
import ShareThisCardDialog from '../../components/Dialogs/ShareThisCardDialog.vue';
import {ResourceStatus} from '@/constants';

let timeoutId = null;

export default {
  name: 'HubResourceDetailsPage',
  components: {
    ResourceDetails,
    OtherResources,
    AdsButton,
    AppBar,
    ShareThisCardDialog
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      const res = await this.$store.dispatch('fetchResourceDetailsWithClient', to.params.id);
      if (!res) {
        this.$store.commit('errors/SET_ERROR_TYPE', 'resourceNotFound');
        await this.$router.push('/notfound');
      }
    }
    next();
  },
  data() {
    return {
      ADS_Colors,
      cardWidth: 350,
      otherSearchResults: [],
      mostViewedResources: [],
      // otherCollectionsWithResource: [],
      shareCard: false,
      dialogShare: false,
    };
  },
  computed: {
    otherResourcesFromCollection() {
      const route = this.$route
      if (route.params.collection || this.$store.state.collectionView) {
        // get current collection resources
        const resources = this.$store.state.myCollections.sortedSubResources
        const cardPosition = resources.findIndex(r => r.document.ItemID === route.params.id);

        const NO_OF_RESOURCES_TO_DISPLAY = 8;
        const startIndex = cardPosition + 1;
        let otherResultsNumber = resources.length - cardPosition;
        if (otherResultsNumber > 8) {
          otherResultsNumber = 8;
        }
        const filteredResults = resources.slice(startIndex, startIndex + NO_OF_RESOURCES_TO_DISPLAY);
        let fill = [];
        if (filteredResults.length < NO_OF_RESOURCES_TO_DISPLAY && resources.length - filteredResults.length > 0) {
          const secondEndIndex = NO_OF_RESOURCES_TO_DISPLAY - filteredResults.length
          // for cases it loops through the resources again
          if ( secondEndIndex < startIndex) {
            fill = resources.slice(0, secondEndIndex);
          } else {
            fill = resources.slice(0, startIndex);
          }
          console.log('...results displayed', filteredResults.length)
          console.log('...there is more in the front', resources.length - filteredResults.length)
          console.log('...picked from front', NO_OF_RESOURCES_TO_DISPLAY - filteredResults.length)
        }
        return [...filteredResults, ...fill].filter(r => r.document.ItemID !== route.params.id)
      } else {
        return []
      }
    }
  },
  async mounted() {
    await this.$store.commit('SET_IS_LOADING', true)
    await this.getMostViewed();
    this.getOtherSearchResults();
    this.$nextTick(this.calcCardSize);

    // load item details based on param id regardless whats in the store to enable direct route access
    try {
      const res = await this.$store.dispatch('fetchResourceDetailsWithClient', this.$route.params.id);
      if (!res) {
        this.$store.commit('errors/SET_ERROR_TYPE', 'resourceNotFound');
        await this.$router.push('/notfound');
      }
    } catch (e) {
      console.error(e);
      await this.$router.push('/notfound');
    } finally {
      await this.$store.commit('SET_IS_LOADING', false)
    }
  },
  methods: {
    getOtherSearchResults() {
      // if the details page was accessed through direct url,
      // or the result is only the current resource, no 'More from your search' will be displayed
      let currentResults = this.$store.state.resources.items;
      const resultsCount = this.$store.state.searchResultsCount;
      if (currentResults.length <= 1 || this.$store.state.searchMode === false) {
        this.otherSearchResults = [];
      } else {
        const cardPosition = currentResults.findIndex(r => r.document.ItemID === this.$route.params.id) + 1;
        let otherResultsNumber = resultsCount - cardPosition;
        if (otherResultsNumber > 4) {
          otherResultsNumber = 4;
        }
        if (otherResultsNumber > 0) {
          // perform a new fetch if needed
          if (currentResults.length - cardPosition < otherResultsNumber) {
            this.$store.commit('SET_PAGE_NUM', this.$store.state.pageNum + 1);
            this.$store.dispatch('fetchResourcesWithClient').then(res => {
              currentResults += res;
              this.otherSearchResults = currentResults.slice(cardPosition, cardPosition + 4);
            });
          } else {
            this.otherSearchResults = currentResults.slice(cardPosition, cardPosition + 4);
          }
        }
      }
    },
    async getMostViewed() {
      try {
        this.mostViewedResources = await this.$store.dispatch('fetchResourcesForMostViewedWithClient');
      } catch (e) {
        console.error(e);
      }
    },
    handleResize() {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => this.calcCardSize(), 200);
      } else {
        timeoutId = setTimeout(() => this.calcCardSize(), 200);
      }
    },
    calcCardSize() {
      if (window.innerWidth <= 960 && window.innerWidth > 450) {
        this.cardWidth = 350;
      } else if (window.innerWidth > 960) {
        if (this.$refs['side-column'].clientWidth) {
          this.cardWidth = this.$refs['side-column'].clientWidth;
        }
      } else {
        this.cardWidth = this.$vuetify.breakpoint.width;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.resource-details__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .content {
    width: 100%;
    max-width: 1240px;

    .content-wrapper {
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0;

      .main-col,
      .side-col {
        padding: 0;
        margin: 0;
      }

      .main-col {
        border-left: 24px solid transparent;
        border-right: 12px solid transparent;
      }

      .side-col {
        border-left: 12px solid transparent;
        border-right: 24px solid transparent;
      }
    }
  }
}

.appbar-title {
  padding-right: 12px;
  font-size: 1.25rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mid-screen {
  display: flex;
  justify-content: flex-end;
  align-items: center
}

.small-screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sm-screen {
  display: flex;
  justify-content: flex-end;
  align-content: center;
}

@media only screen and (max-width: 960px) {
  .resource-details__wrapper {
    justify-content: flex-start;

    .content {
      width: 100%;
      overflow-x: clip;
      margin-top: 0;

      .content-wrapper {
        flex-direction: column;

        .main-col,
        .side-col {
          border: none;
        }

        .main-col {
          margin-bottom: 24px;
        }
      }

      .row {
        & .button-container {

        }
      }
    }
  }
}
</style>
