import api from '@/api';
import {apiBaseUrl} from '@/store/config';
import {RESOURCE_APPLICATION} from '@/constants';

const moduleUsers = {
  namespaced: true,
  state: {
    userProfile: null,
    preferences: null,
    loadingPreferences: false,
    upsConfig: null,
    displayAlert: false,
    alert: {
      type: 'success',
      text: 'Your preferences have been saved'
    }
  },
  getters: {
    userProfile: state => state.userProfile,
    sourcesUserProfile: state => state.userProfile.sources,
    sourcesStringUserProfile: state => state.userProfile.sources.map(source => source.id).toString(),
    profileApplication: state => {
      const applications: any[] = [];
      state.userProfile?.source.forEach(s => applications.push(s.application));
      return applications;
    },
    hasPublisherAccessTo: state => state.userProfile?.accesses?.filter(a => a.roles.includes('publisher')).map(a=> a.content),
    hasReviewerAccessTo: state => state.userProfile?.accesses?.filter(a => a.roles.includes('reviewer')).map(a=> a.content),
    hasAuthorAccessTo: state => state.userProfile?.accesses?.filter(a => a.roles.includes('author')).map(a=> a.content),
    hasViewerAccessTo: state => state.userProfile?.accesses?.filter(a => a.roles.includes('viewer')).map(a=> a.content),
    // Below needs to be removed as at R5 we are supporting multiple roles for user
    // 4 user roles in 4.3 release, there will just be one role for one user
    isSuperAdmin: state => state.userProfile?.supercalifragislisticexpealidociousAdmin,
    isPublisher: state => state.userProfile?.appRole?.findIndex(v => v.name === 'publisher') > -1,
    isAuthor: state => state.userProfile?.appRole?.findIndex(v => v.name === 'author') > -1,
    isReviewer: state => state.userProfile?.appRole?.findIndex(v => v.name === 'reviewer') > -1,
    isViewer: state => state.userProfile?.appRole?.findIndex(v => v.name === 'viewer') > -1,
    userAccesses: state => state.userProfile?.accesses,
    profileSourcesIds: state => {
      const profileSourcesIds: string[] = [];
      state?.userProfile?.sources?.forEach(source => profileSourcesIds.push(source.id)); // ['aboriginalhscattainment', 'attendance', 'curriculum']
      return profileSourcesIds;
    },
    preferences: state => state.preferences,
    resourceCategoryFromPreference: state => state.preferences.resourceCategory,
    upsConfig: state => state.upsConfig,
    loading: state => state.loadingPreferences,
    displayAlert: state => state.displayAlert,
    alert: state => state.alert,
    availableContentGroups: (state, _, __, rootGetters) => {
      const sourceIds = new Set(rootGetters['metadata/sources'].map(({id}) => id));
      return state.userProfile.source.filter(({id}) => sourceIds.has(id));
    },
    collectionSupportedContentGroups: (state, _, __, rootGetters) => {
      const sourceIds = new Set(rootGetters['metadata/sources'].map(({id}) => id));
      // currently only
      return state.userProfile.source.filter(({id, application}) => sourceIds.has(id) && (application.id === RESOURCE_APPLICATION.URH || application.id === RESOURCE_APPLICATION.OLP));
    },
    contentArea: state => state.userProfile.contentArea
  },
  mutations: {
    SET_USER_PROFILE(state, userProfile) {
      const sources = userProfile.source.map(contentSource => {
        return {
          id: contentSource.id,
          name: contentSource.name
        }
      });

      // add a new field for sources of content area
      userProfile.sources = sources;
      state.userProfile = userProfile;
    },
    SET_PREFERENCES(state, value) {
      state.preferences = value;
    },
    SET_LOADING_PREFERENCES(state, value) {
      state.loadingPreferences = value;
    },
    SET_UPS_CONFIG(state, config) {
      state.upsConfig = config;
    },
    SET_DISPLAY_ALERT(state, value) {
      state.displayAlert = value;
    },
    SET_ALERT(state, value) {
      state.alert = value;
    }
  },
  actions: {
    /**
     * This action is fired after Oauth validation to either update the store with the user's profile,
     * or create and push it to the API
     */
    async fetchOrCreateUserProfile({dispatch}, userRequest) {
      let userProfile = await dispatch('fetchUserProfile', userRequest.userId);
      if (!userProfile) {
        userProfile = await dispatch('createUserProfile', userRequest);
      }
      return userProfile;
    },
    async fetchUserProfile({commit}, uid) {
      const userProfile = await api.users.getUser(apiBaseUrl, uid); // returns user profile
      if (userProfile) {
        commit('SET_USER_PROFILE', userProfile);
        commit('SET_PREFERENCES', userProfile.preferences); // existing profile has preferences
      }
      return userProfile;
    },
    async createUserProfile({commit}, userRequest) {
      const userProfile = await api.users.createUser(apiBaseUrl, userRequest);  // returns user profile
      commit('SET_USER_PROFILE', userProfile);
      return userProfile;
    },
    /**
     * The following actions are fired to create, fetch, and update user preferences.
     * Preferences were not set in a new module because it will be incorporated into the user profile soon.
     */
    async sendPreferences({commit, state, dispatch}, preferencesRequest) {
      commit('SET_LOADING_PREFERENCES', true);
      try {
        const payload = {
          userId: state.userProfile.userId,
          preferencesRequest
        }
        const preferences = await api.users.sendPreferences(apiBaseUrl, payload);
        commit('SET_PREFERENCES', preferences);
        commit('SET_RESOURCE_CATEGORY', preferences.resourceCategory, {root: true}); // commit resourceCategory to store after successful preference api call
        await dispatch('fetchUserProfile', state.userProfile.userId);
      } catch (err) {
        console.error(err);
        const alert = {
          type: 'error',
          text: 'There was an error saving your preferences.'
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        commit('SET_LOADING_PREFERENCES', false);
        // check if user didn't skip. If they skipped, alert should not be displayed
        if (preferencesRequest.learningAreas.length !== 0 || preferencesRequest.years.length !== 0) {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }
    },
    async fetchPreferences({commit, state}, uid) {
      if (!state.preferences) {
        const preferences = await api.users.getPreferences(apiBaseUrl, uid);
        commit('SET_PREFERENCES', preferences);
        return preferences;
      } else {
        return state.preferences;
      }
    },
    async updatePreferences({commit, state, dispatch}, preferencesRequest) {
      commit('SET_LOADING_PREFERENCES', true);
      try {
        const payload = {
          userId: state.userProfile.userId,
          preferencesRequest
        }
        const preferences = await api.users.updatePreferences(apiBaseUrl, payload);
        commit('SET_PREFERENCES', preferences);
        commit('SET_RESOURCE_CATEGORY', preferences.resourceCategory, {root: true}); // commit resourceCategory to store after successful preference api call
        await dispatch('fetchUserProfile', state.userProfile.userId);
        const alert = {
          type: 'success',
          text: 'Your preferences have been saved'
        }
        commit('SET_ALERT', alert, {root: true});
      } catch (err) {
        console.error(err);
        const alert = {
          type: 'error',
          text: 'There was an error saving your preferences'
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        commit('SET_LOADING_PREFERENCES', false);
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
    },
    async getUpsConfig({commit}) {
      const config = await api.users.getUpsConfig(apiBaseUrl);
      commit('SET_UPS_CONFIG', config);
      return config;
    },
    displaySuccessToastr({commit}, {text, duration = 20000}) {
      commit('SET_ALERT', {type: 'success', text}, {root: true});
      commit('SET_DISPLAY_ALERT', true, {root: true});
      setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), duration);
    }
  }
};

export default moduleUsers;
