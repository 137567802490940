<template>
  <v-navigation-drawer
    v-bind="$attrs"
    ref="navRoot"
    v-model="drawer"
    :app="app"
    :right="right"
    :clipped="clipped"
    :width="width"
    :color="$vuetify.theme.dark ? ADS_Colors.DP02 : ADS_Colors.Light_10"
    :temporary="temporary"
    tabindex="0"
    v-on="$listeners"
  >
    <div class="notifications">
      <v-card
        v-if="notificationData.length > 0"
        flat
      >
        <v-card-title
          class="d-flex contrast notificationTitle justify-space-between py-4 pr-1"
          :style="{backgroundColor: $vuetify.theme.dark ? ADS_Colors.DP02 : ADS_Colors.Navy}"
        >
          <span
            role="heading"
            tabindex="0"
            :aria-label="notificationData.length + 'notification(s)'"
            class="title"
            :style="{color: ADS_Colors.White}"
          >
            Notifications
            <span
              role="title"
            >
              <v-badge
                :content="notificationData.length"
                color="red"
                class="ml-2"
              />
            </span>
          </span>
          <span
            role="button"
            class="pr-4"
          >
            <v-btn
              aria-label="close notifications"
              size="18"
              class="size--40"
              icon
              @click="$eventHub.$emit('toggle:notifications')"
              @keyup.enter="$eventHub.$emit('toggle:notifications')"
            >
              <v-icon
                size="24"
                :color="ADS_Colors.White"
              >
                close
              </v-icon>
            </v-btn>
          </span>
        </v-card-title>

        <v-divider
          class="divider"
        />

        <v-card-text
          class="pa-0"
        >
          <v-list
            class="notification--contents contrast"
            flat
            style="padding-bottom: 115px"
            three-line
          >
            <template v-for="(item, index) in notificationData">
              <v-list-item
                :key="'notification' + index"
                class="pl-5 pr-2 py-0"
                role="row"
                :aria-label="'notification item ' + (index + 1)"
                :style="index < notificationData.length - 1 ? 'border-bottom:1px solid #A0A5AE': ''"
              >
                <template #default="{}">
                  <v-list-item-icon class="mr-4 mb-0">
                    <div
                      class="d-flex align-self-center"
                    >
                      <div
                        class="align-self-center d-flex"
                        style="height: 40px; width: 40px; border-radius: 4px"
                      >
                        <v-icon
                          size="30"
                          class="ma-auto"
                          style="width: 100%"
                          :color="!item.resource ? ADS_Colors.Dark_60 : ''"
                        >
                          mdi-bell-outline
                        </v-icon>
                      </div>
                    </div>
                  </v-list-item-icon>

                  <v-list-item-content
                    tabindex="0"
                    :aria-label="item.title + ' ' + item.description + ' ' + (item.updatedAt != '' ? timeSince(item.updatedAt) + ' ago' : '')"
                  >
                    <div
                      class="subtitle-1"
                      :title="item.title"
                      tabindex="0"
                      :style="{'color': !item.resource ? ADS_Colors.Dark_60 : ''}"
                    >
                      {{ showTruncated(item.title, 39) }}
                    </div>
                    <div
                      class="pb-4 body-2 pr-2"
                      tabindex="0"
                      :title="item.description"
                      :style="{'color': !item.resource ? ADS_Colors.Dark_60 : ''}"
                    >
                      <span>
                        <span class="font-weight-bold">{{ item.userName }}</span>{{ showTruncated(` ${item.description}`, 150) }}
                      </span>
                      <span
                        v-if="item.link"
                        class="pl-2"
                      >
                        <a
                          target="_blank"
                          :href="item.link"
                          aria-label="read more link double tap to open in new tab"
                          style="text-decoration: none"
                        >
                          Read More<v-icon size="12">launch</v-icon>
                        </a>
                      </span>
                    </div>

                    <div
                      class="pb-4 body-2 pr-2"
                      tabindex="0"
                      :title="item.description"
                    >
                      <span
                        v-if="!item.resource"
                        class="font-weight-bold"
                      >
                        This resource card is no longer available
                      </span>
                      <router-link
                        v-else
                        :id="item.origin.resourceId"
                        :to="{
                          name: 'MyResourceDetailsPage',
                          params: {
                            id: item.origin.resourceId
                          }}"
                      >
                        <span
                          class="font-weight-bold"
                        >
                          {{ showTruncated(`${item.resource.document.document.Name}`, 150) }}
                        </span>
                      </router-link>
                    </div>

                    <div class="d-flex pb-0 mb-0">
                      <div class="d-flex grow align-self-center justify-space-between">
                        <span
                          class="caption align-self-center"
                          style="max-width: 90%"
                        >
                          DLRH
                          <span class="font-weight-bold"> - </span>
                          {{ item.updatedAt != "" ? timeSince(item.updatedAt) + " ago" : "" }}
                        </span>
                      </div>
                    </div>
                  </v-list-item-content>

                  <v-list-item-icon class="ml-0">
                    <span
                      class="mt-n1"
                    >
                      <v-btn
                        :aria-label="'delete notification ' + item.title"
                        style="width: 40px; height: 40px"
                        class="pa-0"
                        icon
                        @click.stop="deleteNotification(item._id)"
                      >
                        <v-icon
                          size="20"
                          :color="ADS_Colors.Dark_60"
                        >
                          close
                        </v-icon>
                      </v-btn>
                    </span>
                  </v-list-item-icon>
                </template>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card
        v-if="notificationData.length == 0"
        flat
        style="text-align: center"
        class="flex"
      >
        <div
          role=""
          class="pt-10"
        >
          <span class="pt-4 pb-7 flex-start">
            <img
              alt="no notification image"
              :src="logo"
            >
          </span>
        </div>
        <span
          role="heading"
          class="noItem"
        >
          No notifications
        </span>
      </v-card>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {ADS_Colors} from '@nswdoe/doe-ui-core';

export default {
  name: 'Notifications',
  props: {
    app: {
      type: Boolean,
      default: true   // override the default value 'false' in v-navigation-drawer
    },
    right: {
      type: Boolean,
      default: true   // override the default value 'false' in v-navigation-drawer
    },
    width: {
      type: [Number, String],
      default: '360'  // override the default value '256' in v-navigation-drawer
    },
    clipped: {
      type: Boolean,
      default: true   // override the default value 'false' in v-navigation-drawer
    },
    temporary: {
      type: Boolean,
      default: true   // override the default value 'false' in v-navigation-drawer
    },
    notificationData: {
      type: Array,
      default: function () {
        return []
      }
    },
    iconsToMap: {
      type: Array,
      default: function () {
        return [
          {app: 'mypl', icon: 'my-my-pl-edu'},
          {app: 'schoolbiz', icon: 'my-school-biz'},
          {app: 'cica', icon: 'my-ci-checklist'}
        ];
      }
    }
  },
  data() {
    return {
      drawer: false,
      ADS_Colors,
      logo: require('./no_notification.svg')
    }
  },
  mounted() {
    // adding eventHub listener
    this.$eventHub.$on('toggle:notifications', this.toggleNotifications);
  },
  beforeUnmount() {
    // removing eventHub listener
    this.$eventHub.$off('toggle:notifications');
  },
  methods: {
    toggleNotifications() {
      this.drawer = !this.drawer;
    },
    deleteNotification: function (notificationId) {
      this.$store.dispatch('myResources/deleteNotification', notificationId);
    },
    showTruncated(message, limit) {
      if (message.length > limit + 3) {
        message = message.substring(0, limit) + '...'
      }
      return message
    },
    timeSince(timestamp) {
      let date = new Date(timestamp)
      let seconds = Math.floor((new Date() - date) / 1000)
      let interval = Math.floor(seconds / 31536000)
      if (interval > 1) {
        return interval + ' years'
      }
      interval = Math.floor(seconds / 2592000)
      if (interval > 1) {
        return interval + ' months'
      }
      interval = Math.floor(seconds / 2592000)
      if (interval == 1) {
        return interval + ' month'
      }
      interval = Math.floor(seconds / 86400)
      if (interval > 1) {
        return interval + ' days'
      }
      interval = Math.floor(seconds / 3600)
      if (interval > 1) {
        return interval + ' hours'
      }
      interval = Math.floor(seconds / 60)
      if (interval > 1) {
        return interval + ' minutes'
      }
      return Math.floor(seconds) + ' seconds'
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  overflow: hidden;

  .divider {
    top: 70px;
    z-index: 1000;
    position: fixed !important;
    overflow: hidden;
    width: 100%;
    opacity: 1;
  }

  .notificationTitle {
    position: fixed !important;
    overflow: hidden;
    width: 100%;
    z-index: 1000;
    opacity: 1;
  }

  .notification--contents {
    top: 70px;
    position: relative;
    max-height: 100vh;
    overflow: scroll;
  }

  .size--40 {
    width: 40px;
    height: 40px
  }
}

.v-overlay__scrim {
  opacity: 0 !important;
}

.notifications svg {
  height: 24px;
  width: 24px;
}

.v-overlay v-overlay--active {
  z-index: 0 !important;
}

.caption {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1rem;
}
</style>
