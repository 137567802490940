var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{class:{'rounded-0': !_vm.rounded},attrs:{"width":_vm.size,"height":_vm.cardHeight,"title":_vm.resource.name}},[_c('v-img',{attrs:{"src":_vm.imageUrl,"lazy-src":_vm.resourceDefaultImage,"width":_vm.size,"aspect-ratio":1.85,"contain":false,"eager":true,"alt":_vm.altDescription}},[(_vm.isEditor)?_c('v-chip',{staticClass:"editor-chip",attrs:{"small":"","label":""}},[_vm._v(" EDITOR ")]):_vm._e()],1),_c('div',{staticClass:"card-info"},[_c('div',{staticClass:"card-info--heading"},[_c('v-card-title',{class:{
            'd-flex justify-space-between': true,
            'px-2 pt-0 pb-0 mt-0': _vm.size < 350,
            'pb-0 pt-1 mt-0' : _vm.size >= 350
          }},[_c('span',{class:{
              'resource-type': true,
              'resource-type-xs': _vm.size < 280
            },attrs:{"aria-label":'resource type: '+ _vm.resourceType}},[_vm._v(" "+_vm._s(_vm.resourceType)+" ")]),_c('span',{staticClass:"d-flex"},_vm._l((_vm.resourceCategory),function(cat){return _c('FeatureIcon',{key:cat.id,staticClass:"ml-2",attrs:{"size":"20","aria-label":cat.label,"color":cat.iconColor,"backgroundColor":cat.iconBg,"icon":cat.icon}})}),1),(_vm.isDraft && (_vm.isOwner || _vm.isEditor))?_c('v-chip',{staticClass:"draft-chip",attrs:{"small":""}},[_vm._v(" DRAFT ")]):_vm._e()],1),(_vm.isDraft)?_c('v-card-title',{staticClass:"pt-0 pb-0",class:{'px-2': _vm.size < 350 }},[_c('a',{staticClass:"primary-control",attrs:{"id":_vm.itemId,"tabindex":"0"},on:{"click":_vm.openDetailsPage,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.openDetailsPage.apply(null, arguments)}}},[_c('h3',{staticClass:"card-title",class:{'card-title-xl': _vm.size > 450, 'card-title-md': _vm.size < 320}},[_vm._v(" "+_vm._s(_vm.resource.Name ? _vm.resource.Name : 'No title created')+" ")])])]):_c('v-card-title',{staticClass:"pt-0 pb-0",class:{'px-2': _vm.size < 350 }},[_c('router-link',{staticClass:"primary-control",attrs:{"id":_vm.item.id,"to":{
              name: 'HubResourceDetailsPage',
              params: {
                id: _vm.itemId
              }},"tabindex":"0"}},[_c('h3',{staticClass:"card-title",class:{'card-title-xl': _vm.size > 450, 'card-title-md': _vm.size < 320}},[_vm._v(" "+_vm._s(_vm.resource.Name ? _vm.resource.Name : 'No title created')+" ")])])],1)],1),_c('v-card-actions',{class:{ 'd-flex align-end': true }},[_c('v-card-subtitle',{staticClass:"card-footer",class:{'pt-1 pr-6': _vm.size >= 350, 'px-2 pt-0 pb-2': _vm.size < 350, 'pb-1' : true}},[(_vm.isSIRCategory)?_c('div',{staticClass:"card-left-note"},[_vm._v(" Focus Area: "),_c('span',{class:{
                'years-sm': _vm.size < 300,
                'years-xs': _vm.size < 280
              }},[_vm._v(" "+_vm._s(_vm.focusAreas ? _vm.focusAreas : 'None')+" ")])]):_c('div',[_vm._v(" Years: "),_c('span',{class:{
                'years-sm': _vm.size < 300,
                'years-xs': _vm.size < 280
              }},[_vm._v(" "+_vm._s(_vm.years ? _vm.years : 'None selected')+" ")])]),_c('span',[_vm._v("Updated: "+_vm._s(_vm.moment(_vm.updatedDate).format('DD MMM YYYY')))])]),_c('v-spacer'),[_c('div',{staticClass:"d-flex justify-end mb-1"},[_c('v-tooltip',{attrs:{"bottom":"","activator":`.save-to-collection-${_vm._uid}`}},[_c('span',[_vm._v("Save to collection")])]),_c('v-tooltip',{attrs:{"bottom":"","activator":`.copy-link-${_vm._uid}`}},[_c('span',[_vm._v("Copy this link")])]),_c('v-tooltip',{attrs:{"bottom":"","activator":`.goto-resource-${_vm._uid}`}},[_c('span',[_vm._v("Go to Resource")])]),_c('v-btn',{staticClass:"button-icon save-to-collection",class:`save-to-collection-${_vm._uid}`,attrs:{"icon":"","small":"","aria-label":"Save to collection"},on:{"click":_vm.handleAddToCollection}},[_c('v-icon',{attrs:{"outlined":""}},[_vm._v(" mdi-bookmark-plus-outline ")])],1),(_vm.showAddToCollectionDialog)?[_c('SaveToCollectionDialog',{attrs:{"items":[{..._vm.item}],"selected-cards":[{
                  ..._vm.item?.document,
                  Application: _vm.item?.audit?.iss
                }]},model:{value:(_vm.showAddToCollectionDialog),callback:function ($$v) {_vm.showAddToCollectionDialog=$$v},expression:"showAddToCollectionDialog"}})]:_vm._e(),_c('div',[_c('Dialog',{attrs:{"title":"Copy this link","dark-heading":"","max-width":_vm.$vuetify.breakpoint.smAndUp ? 500 : 360},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{staticClass:"button-icon copy-button",class:`copy-link-${_vm._uid}`,attrs:{"icon":"","small":"","aria-label":"copy url"},on:{"click":function($event){_vm.dialogCopyLink = true}}},[_c('v-icon',{attrs:{"outlined":""}},[_vm._v(" content_copy ")])],1)]},proxy:true},{key:"content",fn:function(){return [_c('CopyClipboardMenu',{attrs:{"is-open":_vm.dialogCopyLink,"description":"Copy and paste this link:","text-to-copy":_vm.itemUrl}})]},proxy:true}]),model:{value:(_vm.dialogCopyLink),callback:function ($$v) {_vm.dialogCopyLink=$$v},expression:"dialogCopyLink"}})],1),_c('v-btn',{staticClass:"button-icon go-to-resource-button",class:`goto-resource-${_vm._uid}`,attrs:{"icon":"","small":"","aria-label":"Go to resource"},on:{"click":_vm.handleGoToResource}},[_c('v-icon',{attrs:{"outlined":""}},[_vm._v(" open_in_new ")])],1)],2)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }