export const API_BASE_DEV = 'https://resources.dev.education.nsw.gov.au/api/v1';
export const SITE_URL_DEV = 'https://resources.dev.education.nsw.gov.au/';
export const OUTCOME_SEARCH_URL = 'http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=';

export const MENUS_MODEL = {
  Years: 'Years',
  KLAs: 'Learning Area',
  ResourceType: 'ResourceType',
  Alignment: 'Alignment',
  Capabilities: 'Capabilities',
  Priorities: 'Priorities',
  Source: 'Source',
  Access: 'Access',
};

export const FILTERS = {
  Years: 'resource-years',
  KLAs: 'resource-klas',
  ResourceType: 'resource-types',
  Alignment: 'resource-alignments',
  Capabilities: 'resource-general-capabilities',
  Priorities: 'resource-cross-curriculum-priorities',
  Source: 'resource-sources',
  Access: 'resource-access-levels',
  RandN: 'resource-randntopics',
};

export const CHECKBOX_YEARS = [
  {
    name: 'Early Stage (K)',
    id: 'YEAR_K',
  },
  {
    name: 'Stage 1',
    id: 'stage_one',
    children: [
      {
        name: 'Year 1',
        id: 'YEAR_1',
      },
      {
        name: 'Year 2',
        id: 'YEAR_2',
      },
    ]
  },
  {
    name: 'Stage 2',
    id: 'stage_two',
    children: [
      {
        name: 'Year 3',
        id: 'YEAR_3',
      },
      {
        name: 'Year 4',
        id: 'YEAR_4',
      },
    ]
  },
  {
    name: 'Stage 3',
    id: 'stage_three',
    children: [
      {
        name: 'Year 5',
        id: 'YEAR_5',
      },
      {
        name: 'Year 6',
        id: 'YEAR_6',
      }
    ]
  },
  {
    name: 'Stage 4',
    id: 'stage_four',
    children: [
      {
        name: 'Year 7',
        id: 'YEAR_7',
      },
      {
        name: 'Year 8',
        id: 'YEAR_8',
      },
    ]
  },
  {
    name: 'Stage 5',
    id: 'stage_five',
    children: [
      {
        name: 'Year 9',
        id: 'YEAR_9',
      },
      {
        name: 'Year 10',
        id: 'YEAR_10',
      },
    ]
  },
  {
    name: 'Stage 6',
    id: 'stage_six',
    children: [
      {
        name: 'Year 11',
        id: 'YEAR_11',
      },
      {
        name: 'Year 12',
        id: 'YEAR_12',
      },
    ]
  }
];

export const CHECKBOX_STAGES = [
  {
    id: 'K',
    name: 'Early Stage (K)'
  },
  {
    id: 'stage_one',
    name: 'Stage 1'
  },
  {
    id: 'stage_two',
    name: 'Stage 2'
  },
  {
    id: 'stage_three',
    name: 'Stage 3'
  },
  {
    id: 'stage_four',
    name: 'Stage 4'
  },
  {
    id: 'stage_five',
    name: 'Stage 5'
  },
  {
    id: 'stage_6',
    name: 'Stage 6'
  }
]

export const CHECKBOX_KLAS = [
  {
    name: 'English',
    value: 'ENGLISH',
  },
  {
    name: 'Mathematics',
    value: 'MATHEMATICS',
  },
  {
    name: 'Science',
    value: 'SCIENCE',
  },
  {
    name: 'HSIE',
    value: 'HSIE',
  },
  {
    name: 'Languages',
    value: 'LANGUAGES',
  },
  {
    name: 'TAS',
    value: 'TAS',
  },
  {
    name: 'PDHPE',
    value: 'PDHPE',
  },
  {
    name: 'Creative arts',
    value: 'CREATIVE_ARTS',
  },
];

export const CHECKBOX_RESOURCE_TYPE = [
  {
    name: 'Interactive',
    value: 'Interactive',
  },
  {
    name: 'Image',
    value: 'Image',
  },
  {
    name: 'Audio',
    value: 'Audio',
  },
  {
    name: 'Video',
    value: 'Video',
  },
  {
    name: 'Collections',
    value: 'Collections',
  },
  {
    name: 'Teacher',
    value: 'Teacher',
  },
  {
    name: 'Text',
    value: 'Text',
  },
  {
    name: 'Assessment',
    value: 'Assessment',
  },
];

export const CHECKBOX_MY_RESOURCES_LIBRARIES = [
  {
    id: 'createdbyme',
    name: 'Created by me'
  },
  {
    id: 'sharedwithme',
    name: 'Shared with me'
  },
  {
    id: 'sharedbyme',
    name: 'Shared by me'
  }
]

export const FILTER_OPTIONS = {
  Years: CHECKBOX_YEARS,
  'Learning Area': CHECKBOX_KLAS,
  'Resource Type': CHECKBOX_RESOURCE_TYPE,
  Capabilities: 'Capabilities',
  Priorities: 'Priorities',
  Source: 'Source',
  Access: 'Access',
}

export const SORT_OPTIONS = [
  {
    text: 'Relevance',
    value: 'relevance'
  },
  {
    text: 'Created Date',
    value: 'created:desc'
  },
  {
    text: 'Updated Date',
    value: 'updated:desc'
  },
  {
    text: 'Name',
    value: 'name:asc'
  },
  {
    text: 'Most viewed',
    value: 'numberViews:desc'
  },
  {
    text: 'Likes',
    value: 'numberLikes:desc'
  },
]

export const MY_COLLECTIONS_SORT_OPTIONS = [
  {
    text: 'Relevance',
    value: 'relevance'
  },
  {
    text: 'Created Date',
    value: 'created:desc'
  },
  {
    text: 'Updated Date',
    value: 'updated:desc'
  },
  {
    text: 'Name',
    value: 'name:asc'
  }
]

export const MY_RESOURCES_SORT_OPTIONS = [
  {
    text: 'Relevance',
    value: 'relevance'
  },
  {
    text: 'Created Date',
    value: 'created:desc'
  },
  {
    text: 'Updated Date',
    value: 'updated:desc'
  },
  {
    text: 'Name',
    value: 'name:asc'
  }
]

export const COLLECTION_SORT_OPTIONS = {
  ADDED_ASC: 'added:asc',
  ADDED_DESC: 'added:desc',
  UPDATED_ASC: 'updated:asc',
  UPDATED_DESC: 'updated:desc',
  SEQUENCE: 'sequence'
}

export const QUERIES = [
  'search',
  'sort',
  'library',
  'years',
  'klas',
  'resource-type',
  'source',
  'randntopics',
  'resource-category',
  'phase',
  'resourcetype',
  'focusarea',
  'sefalignment'
]

export const SORT_OPTIONS_SAVED_SEARCHES = [
  {
    text: 'Last Updated',
    value: 'updatedAt:desc'
  },
  {
    text: 'Last Run',
    value: 'lastRun:desc'
  },
  {
    text: 'Name',
    value: 'searchName:asc'
  },
];

export const MORE_ACTIONS_MENU_ITEMS = [
  {
    id: 'inviteCollaborators',
    name: 'Invite collaborators',
    tag: 'button',
    icon: 'mdi-account-multiple-plus-outline'
  },
  {
    id: 'shareCard',
    name: 'Share this card',
    tag: 'button',
    icon: 'mdi-export-variant'
  },
  // {
  //   id: 'copyCard',
  //   name: 'Make a copy',
  //   tag: 'a',
  //   icon: 'mdi-content-copy'
  // },
  {
    id: 'versionHistory',
    name: 'Version history',
    tag: 'button',
    icon: 'mdi-history'
  },
  {
    id: 'info',
    name: 'Information',
    tag: 'button',
    icon: 'mdi-information-outline'
  },
  {
    id: 'delete',
    name: 'Delete',
    tag: 'button',
    icon: 'mdi-delete-outline',
  }
];

export const MORE_ACTIONS_MENU_ITEMS_EDITOR_VIEWER = [
  {
    id: 'shareCard',
    name: 'Share this card',
    tag: 'button',
    icon: 'mdi-export-variant'
  },
  // {
  //   id: 'copyCard',
  //   name: 'Make a copy',
  //   tag: 'a',
  //   icon: 'mdi-content-copy'
  // },
  {
    id: 'versionHistory',
    name: 'Version history',
    tag: 'button',
    icon: 'mdi-history'
  },
  {
    id: 'info',
    name: 'Information',
    tag: 'button',
    icon: 'mdi-information-outline'
  },
  {
    id: 'delete',
    name: 'Delete',
    tag: 'button',
    icon: 'mdi-delete-outline',
  }
];

export const SEARCH_CONTEXT = {
  HUB: 'HUB',
  MY_RESOURCES: 'MY_RESOURCES'
}

export const COMPONENT_CONFIG = {
  alert: {
    timeout: 20000
  }
}

export const RESOURCE_CATEGORIES = [
  {
    id: 'tlr',
    icon: 'school',
    iconColor: 'white',
    iconBg: '#1D428A',
    label: 'Teaching and learning resources'
  },
  {
    id: 'sir',
    icon: 'build',
    iconColor: 'white',
    iconBg: '#DC5800',
    label: 'School improvement resource'
  },
  {
    id: 'gen',
    icon: 'school',
    iconColor: 'white',
    iconBg: '#1D428A',
    label: 'Generic resource'
  },
  {
    id: 'collection',
    icon: 'mdi-bookmark-multiple-outline',
    iconBg: 'white',
    label: 'Collection'
  }
];

export const SCHOOL_IMPROVEMENT_SOURCES = [
  {
    'type': [
      'School improvement resources'
    ],
    'id': 'aboriginalhscattainment',
    'name': 'Aboriginal HSC Attainment'
  },
  {
    'type': [
      'School improvement resources'
    ],
    'id': 'attendance',
    'name': 'Attendance'
  },
  {
    'type': [
      'School improvement resources'
    ],
    'id': 'behaviour',
    'name': 'Behaviour'
  },
  {
    'type': [
      'School improvement resources'
    ],
    'id': 'disabilityandinclusion',
    'name': 'Disability and Inclusion'
  },
  {
    'type': [
      'School improvement resources'
    ],
    'id': 'financialmanagement',
    'name': 'Financial Management'
  },
  {
    'type': [
      'School improvement resources'
    ],
    'id': 'seia',
    'name': 'School Excellence in Action'
  },
  {
    'type': [
      'School improvement resources'
    ],
    'id': 'wellbeing',
    'name': 'Wellbeing'
  }
]

export const HUB_FILTERS_STRUCTURE = {
  urhlibrary: '',
  library: '',
  'resource-category': '',
  'resource-years': '',
  'resource-klas': '',
  'resource-types': '',
  'resource-sources': '',
  'resource-randntopics': '',
  // new query parameters in R4 for Hub:
  formats: '',
  resourcetype: '', // Hub-Type, checkboxHubType
  phase: '',
  sefalignment: '',
  focusarea: ''
};

export const HUB_FILTERS_SELECTION_STRUCTURE = {
  checkboxResourceCategory: [],
  checkboxUrhLibrary: [],
  checkboxLibrary: [],
  checkboxYears: [],
  checkboxKLAs: [],
  checkboxResourceType: [],
  checkboxSource: [],
  checkboxRandN: [],
  // new in R4:
  checkboxFormat: [],
  checkboxHubType: [],
  checkboxPhase: [],
  checkboxSefAlignment: [],
  checkboxFocusArea: []
};

export const MY_RESOURCES_FILTERS_STRUCTURE = {
  myResourcesLibraries: '',
  years: '',
  klas: '',
  types: '',
  formats: ''
};

export const MY_RESOURCES_FILTERS_SELECTION_STRUCTURE = {
  checkboxMyResourcesLibraries: [],
  checkboxYears: [],
  checkboxKLAs: [],
  checkboxResourceType: [],
  checkboxFormat: []
};

export const MY_RESOURCES_RESOURCE_DETAILS_STRUCTURE = {
  archive: null,
  audit: null,
  curriculum: null,
  document: null,
  draft: null,
  files: null,
  links: null,
  misc: null,
  professionalLearning: null,
  record: null,
  userAccess: null
};

// match resourceDetails.document or resourceDetails.draft object
export const RESOURCE_DOCUMENT_OR_DRAFT = {
  AusCurrContDesc: [],
  Author: 'NSW Department of Education',
  DateCreated: '',
  DateModified: '',
  DateResCreated: '',
  Description: '',
  FocusArea: [],
  Format: '',
  IsAustCurr: false,
  IsNSWSyllabus: false, // Set IsNSWSyllabus=true if there are Syllabuses added to the resource (NSWSyllabus), otherwise false
  ItemID: '',
  KLA: [],
  Keywords: [],
  LitLearnProgLevel: [],
  LitLearnProgLevelV3: [],
  NSWSyllabus: [],
  Name: '',
  NumLearnProgLevel: [],
  NumLearnProgLevelV3: [],
  PLLinks: [],
  Phase: [],
  Publisher: 'NSW Department of Education',
  QAAssured: true, // default to true for all resources created from the editor
  RandNTopic: [],
  RelatedResources: [],
  ResourceCategory: [],
  ResourceImage: {author: '', resourceImage: '', thumbnailUrl: ''},
  ResourceType: '',
  ResourceURL: '', // 4.3 - not used for links anymore
  Rights: '© State of New South Wales (Department of Education).',
  SEFAlignment: [],
  SIPTerms: [],
  SchoolType: [],
  Source: '',
  SubSource: '',
  Theme: '',
  ThumbnailImage: '',
  Topic: [],
  Year: [],
  Stage: [] // Stage will be disregarded when calling the api
};

// 4.3
export const RESOURCE_DETAILS_STRUCTURE = {
  archive: null,
  audit: null,
  collections: [],
  document: {...RESOURCE_DOCUMENT_OR_DRAFT},
  draft: {...RESOURCE_DOCUMENT_OR_DRAFT},
  files: [],
  links: [], // 4.3 - move links from document/draft
  misc: null,
  record: null,
  subResources: [],
  userAccess: null
};

export const RESOURCE_QUERY_ACTIONS = {
  CREATEDBYME: 'createdbyme',
  SHAREDBYME: 'sharedbyme',
  SHAREDWITHME: 'sharedwithme'
}

export const RESOURCE_SCOPES = {
  INTERNAL_SCOPE: 0,
  MYRESOURCES_SCOPE: 1, // not being used for editor
  PUBLIC_SCOPE: 2,
  EQUELLA_SCOPE: 3,
  NON_URH_PUBLIC: 4
}

export const RESOURCE_APPLICATION = {
  URH: 'urh',
  OLP: 'olp',
  EQUELLA: 'equ'
}

export const URLS = {
  PROGRESIONS: {
    NUMERACY: 'https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=',
    LITERACY: 'https://educationstandards.nsw.edu.au/wps/wcm/connect/32837681-1ffc-49b3-8069-c756611ff054/national-literacy-learning-progression.pdf?MOD=AJPERES&CVID=',
    LITERACY_NUMERACY_V3: 'https://education.nsw.gov.au/teaching-and-learning/curriculum/literacy-and-numeracy/resources-for-schools/learning-progressions'
  }
}

export const DEFAULT_MAX_NO_OF_COLLECTIONS = 256
// items for NavigationDrawer component
export const NAVIGATION_DRAWER_ITEMS = [
  {
    title: 'Hub Resources',
    icon: 'vertical_split',
    iconOutlined: true,
    accessLevel: 2,
    route: '/home'
  },
  {
    title: 'My Resources',
    icon: 'collections_bookmark',
    iconOutlined: true,
    accessLevel: 2,
    route: '/myresources'
  },
  {
    title: 'My Collections',
    icon: 'bookmarks',
    iconOutlined: true,
    accessLevel: 2,
    route: '/mycollections'
  },
  {
    title: 'Community',
    icon: 'storefront',
    iconOutlined: false,
    accessLevel: 2,
    route: '/community'
  },
  {
    title: 'Help & About',
    icon: 'help_outline',
    iconOutlined: false,
    accessLevel: 2,
    items: [
      {
        title: 'FAQs',
        route: '/faq'
      },
      {
        title: 'Tutorials',
        route: '/tutorials'
      },
      {
        title: 'Road Map',
        route: '/roadmap'
      }
    ]
  },
  {
    title: 'Settings',
    icon: 'settings',
    iconOutlined: true,
    accessLevel: 2,
    route: '/settings'
  },
  {
    title: 'Resource Editor Access',
    icon: 'mdi-sitemap-outline',
    iconOutlined: false,
    accessLevel: 4,
    route: '/resourceeditoradmin'
  },
  {
    title: 'Hub Resource Editor',
    icon: 'mdi-pencil-box-outline',
    iconOutlined: false,
    accessLevel: 3,
    route: '/resourceeditor'
  },
  {
    title: 'Digital Learning Selector',
    icon: 'mdi-launch',
    iconOutlined: false,
    accessLevel: 2,
    href: 'https://app.education.nsw.gov.au/digital-learning-selector/',
    target: '_blank'
  },

];

export const EDITOR_ACTIONS_ITEMS = [
  {
    id: 'duplicate',
    name: 'Duplicate',
    tag: 'a',
    icon: 'mdi-content-copy'
  }
];

export const EDITOR_ACTIONS_ITEMS_WITH_DELETE = [
  ...EDITOR_ACTIONS_ITEMS,
  {
    id: 'delete',
    name: 'Delete',
    tag: 'button',
    icon: 'mdi-delete-outline',
  }
];

export const PUBLISHER_ACTIONS_ITEMS = [
  ...EDITOR_ACTIONS_ITEMS,
  {
    id: 'unpublish',
    name: 'Unpublish',
    tag: 'button',
    icon: 'mdi-undo',
  }
];

export const PUBLISHER_ACTIONS_ITEMS_WITH_DELETE = [
  ...PUBLISHER_ACTIONS_ITEMS,
  {
    id: 'delete',
    name: 'Delete',
    tag: 'button',
    icon: 'mdi-delete-outline',
  }
];

// expansionPanel items for ResourceEditor::Preview
export const EXPANSION_PANEL_ITEMS_EDITOR_PREVIEW = [
  {
    id: 'Overview',
    title: 'Overview',
  },
  {
    id: 'CurriculumAlignment',
    title: 'Syllabus /  Curriculum Alignment',
  },
  {
    id: 'Progressions',
    title: 'National Literacy and/or Numeracy Learning Progression(s)',
  },
  {
    id: 'Keywords',
    title: 'Keywords',
  }
];

export const EDPUSH_ACTION = {
  SUBMIT: 'submit',
  ENDORSE: 'endorse',
  DECLINE: 'decline',
  PUBLISH: 'publish',
  UNPUBLISH: 'unpublish',
  ARCHIVE: 'archive',
  SAVE_AS_DRAFT: 'saveAsDraft'
};

export const ELASTIC_STATUS = {
  ARCHIVED: 'archived',      // 0
  DECLINED: 'declined',      // 1
  DRAFT: 'draft',            // 2
  ENDORSED: 'endorsed',        // 3
  PENDING: 'pending',        // 4
  PUBLISHED: 'published',    // 5
  REJECTED: 'rejected',      // 6
  UNPUBLISHED: 'unpublished' // 7
};

export const DATATABLE_ITEMS_STATUS = {
  ARCHIVED: 'Archived',      // 0
  DECLINED: 'Declined',      // 1
  DRAFT: 'Draft',            // 2
  ENDORSED: 'Endorsed',        // 3
  PENDING: 'Pending',        // 4
  PUBLISHED: 'Published',    // 5
  REJECTED: 'Rejected',      // 6
  UNPUBLISHED: 'Unpublished' // 7
};

export enum ResourceStatus {
  all = -1,
  archived = 0,
  declined = 1,
  draft = 2,
  endorsed = 3,
  pending = 4,
  published = 5,
  rejected = 6,
  unpublished = 7,
}

export const SourceType = {
  File: 'file',
  Link: 'link'
};

export const ERROR_LOOKUP = [{
    code: '404-f',
    title: 'Error: Resource is no longer published',
    subtitle: 'The selected resource has been removed from the URH by the Content Owner. Please check back at a later date'
  },{
    code: '500',
    title: 'Error:',
    subtitle: 'Something went wrong. Please try again later.'
  }
]
