<template>
  <v-list>
    <div
      v-if="items.length > 1"
      class="d-flex justify-space-between"
    >
      <v-subheader>Source</v-subheader>
      <v-subheader>
        <a
          target="_blank"
          @click="openAll"
        >
          Open all
        </a>
      </v-subheader>
    </div>
    <template v-for="({type, ...item}, index) in items">
      <v-list-item
        v-if="type === 'link'"
        :key="index"
        class="menu_items"
        tag="a"
        role="link"
        @click="openIndividualFile(item)"
      >
        <v-list-item-icon class="mr-0">
          <v-icon>
            link
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="ml-n3">
          {{ item.label || item.url }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-else
        :key="index"
        tag="div"
        role="link"
      >
        <v-list-item-icon class="mr-1">
          <v-icon
            v-if="(item.ZipFileEntryPoint && item.ZipFileEntryPoint !== item.DownloadUrl) || item.ArchiveType === 'SCORM'"
            role="button"
            alt="launch"
            title="launch"
            @click="launchFile(item)"
          >
            mdi-file-check-outline
          </v-icon>
          <v-icon
            v-else
            role="button"
            alt="download"
            title="download"
            @click="downloadFile(item)"
          >
            mdi-file-check-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <div
            v-if="(item.ZipFileEntryPoint && item.ZipFileEntryPoint !== item.DownloadUrl) || item.ArchiveType === 'SCORM'"
            role="button"
            @click="launchFile(item)"
          >
            {{ fileName(item.FileName) }}
          </div>
          <div
            v-else
            role="button"
            @click="downloadFile(item)"
          >
            {{ fileName(item.FileName) }}
          </div>
        </v-list-item-content>
        <v-list-item-icon>
          <div>
            <a
              alt="download"
              title="download"
            >
              <v-icon
                class="mr-2"
                @click="downloadFile(item)"
              >
                download
              </v-icon>
            </a>
            <a
              alt="launch"
              title="launch"
            >
              <v-icon
                v-if="(item.ZipFileEntryPoint && item.ZipFileEntryPoint !== item.DownloadUrl) || item.ArchiveType === 'SCORM'"
                @click="launchFile(item)"
              >
                launch
              </v-icon>
            </a>
          </div>
        </v-list-item-icon>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>

export default {
  name: 'GoToResourceList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemUrl: String, // ResourceURL
    id: String, // ItemId of the resource card
    name: String, // Name of the resource card
  },
  methods: {
    fileName(name) {
      const arr = name.split('/');
      return arr[arr.length - 1];
    },
    openAll() {
      this.$store.dispatch('captureGoToResource', { id: this.id, name: this.name });
      // TODO: find a workaround for popup blocker issue
      this.items.forEach(({type, DownloadUrl, url}) => window.open(type === 'link' ? url : DownloadUrl, '_blank'));
    },
    downloadFile(item) { // download is only shown for scorm or zip with html
      this.$store.dispatch('captureGoToResource', {id: this.id, name: this.name});
      if (item.DownloadUrl) { // pointing to the zip file
        window.open(item.DownloadUrl)
      }
    },
    launchFile(item) {
      this.$store.dispatch('captureGoToResource', {id: this.id, name: this.name});
      if (item.ZipFileEntryPoint) {
        window.open(item.ZipFileEntryPoint, '_blank');
      }
    },
    openIndividualFile(item) {
      this.$store.dispatch('captureGoToResource', { id: this.id, name: this.name });
      window.open(item.url, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

::v-deep.v-list {
  .v-subheader {
    color: $ads-primary-blue;
    font-weight: bold;
  }

  .menu_items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    color: $ads-primary-blue;


    & .v-list-item__content {
      display: block;
      width: 260px;
      /* Required for text-overflow to do anything */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
