<template>
  <div>
    <AppBar
      v-if="$vuetify.breakpoint.xsOnly"
      showBackButton
      :color="ADS_Colors.Navy"
    >
      <template #title>
        <h1 class="appbar-title">
          {{ appBarTitle }}
        </h1>
      </template>
    </AppBar>
    <v-row class="create-new-resource__wrapper">
      <v-col
        cols="12"
        lg="10"
        md="10"
        sm="12"
        class="content__wrapper"
      >
        <!--Resource Editor Top Section  -->
        <div class="top">
          <!--  left content - back button  -->
          <template
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <AdsButton
              v-if="$store.state.previousPath !== '/'"
              tertiary
              icon="mdi-arrow-left"
              button-text="Back"
              @click="$router.back()"
              @keyup.enter="$router.back()"
            />
            <AdsButton
              v-else
              tertiary
              icon="mdi-arrow-left"
              button-text="Back to Resource Editor"
              @click="$router.push({ name: 'ResourceEditorPage' })"
              @keyup.enter="$router.push({ name: 'ResourceEditorPage' })"
            />
          </template>
          <v-spacer v-else />

          <!-- right content - Viewer -->
          <div
            v-if="isViewer"
            class="right-content"
          />

          <!-- right content - Author -->
          <div
            v-if="isAuthor"
            class="right-content"
          >
            <!-- Author cannot edit published or endorsed resources, only Draft or Pending -->
            <v-switch
              v-if="shouldAuthorOrReviewerEdit"
              v-model="editMode"
              hide-details
              label="Edit"
            />
            <!-- NEW RESOURCE has 'save as draft' and 'submit for review' options -->
            <template v-if="isNewResource">
              <!--create a new (Draft) resource -->
              <SaveAsDraftButton
                :disabled="disableSaveOrSubmit"
                @click="handleCreate(true, true)"
              />
              <SubmitForReviewButton
                :disabled="disableSaveOrSubmit"
                @click="submitForReviewDialog = true; $store.dispatch('editor/getReviewersList', document.Source)"
              />
            </template>
            <!-- Existing resource actions -->
            <template v-if="!isNewResource">
              <!-- Edit Mode, Author cannot edit published or endorsed resources, only Draft || Pending || Declined -->
              <template v-if="editMode && (isDraftOrPending || isDeclined)">
                <SaveAsDraftButton
                  :disabled="disableSaveOrSubmit"
                  @click="saveAsDraftForExistingResource"
                />
                <SubmitForReviewButton
                  :disabled="disableSaveOrSubmit"
                  @click="submitForReviewDialog = true; $store.dispatch('editor/getReviewersList', document.Source)"
                />
              </template>
              <!-- Preview Mode -->
              <template v-if="!editMode">
                <!-- In Preview, can submit a Draft for review-->
                <SubmitForReviewButton
                  v-if="isDraft"
                  @click="submitForReviewDialog = true; $store.dispatch('editor/getReviewersList', resourceDetails.draft.Source)"
                />
                <!-- Show 'More actions' button on preview (duplicate & delete if isOwner) -->
                <span class="mr-3">
                  <ActionsMenu
                    bottom
                    offset-y
                    left
                    :nudge-bottom="8"
                    :items="isDraft && isOwner ? actionsItemsWithDelete : actionsItems"
                    @clickedAction="handleMenuAction"
                  />
                </span>
              </template>
            </template>
          </div>


          <!-- right content - Reviewer -->
          <div
            v-if="isReviewer"
            class="right-content"
          >
            <!-- Reviewer cannot edit Published or Endorsed resources (save as Author) -->
            <v-switch
              v-if="shouldAuthorOrReviewerEdit"
              v-model="editMode"
              hide-details
              label="Edit"
            />
            <!-- NEW RESOURCE has 'save as draft' and 'submit for review' options -->
            <template v-if="isNewResource">
              <!-- Reviewer can create a new draft -->
              <SaveAsDraftButton
                :disabled="disableSaveOrSubmit"
                @click="handleCreate(true, true)"
              />
              <SubmitForReviewButton
                :disabled="disableSaveOrSubmit"
                @click="submitForReviewDialog = true; $store.dispatch('editor/getReviewersList', document.Source)"
              />
            </template>

            <!-- Existing Pending resource actions -->
            <template v-if="!isNewResource && isPending">
              <!-- For Pending resource, in Preview, Reviewer can Decline/Endorse, buttons are enabled-->
              <template v-if="!editMode">
                <DeclineButton
                  @click="declineDialog = true; $store.dispatch('editor/getReviewersList', resourceDetails.draft.Source)"
                />
                <EndorseButton
                  @click="endorseDialog = true; $store.dispatch('editor/getReviewersList', resourceDetails.draft.Source)"
                />
              </template>
              <!-- For Pending resource, in Edit, Reviewer can Decline/Endorse, will check required fields-->
              <template v-if="editMode">
                <DeclineButton
                  :disabled="disableSaveOrSubmit"
                  @click="declineDialog = true; $store.dispatch('editor/getReviewersList', document.Source)"
                />
                <EndorseButton
                  :disabled="disableSaveOrSubmit"
                  @click="endorseDialog = true; $store.dispatch('editor/getReviewersList', document.Source)"
                />
              </template>
            </template>

            <!-- Existing Draft or Declined resource actions -->
            <template v-if="!isNewResource && (isDraft || isDeclined)">
              <template v-if="editMode">
                <!-- For Draft or Declined resource, in Edit, Reviewer can edit and Save (update) or Submit -->
                <SaveAsDraftButton
                  :disabled="disableSaveOrSubmit"
                  @click="saveAsDraftForExistingResource"
                />
                <SubmitForReviewButton
                  :disabled="disableSaveOrSubmit"
                  @click="submitForReviewDialog = true; $store.dispatch('editor/getReviewersList', document.Source)"
                />
              </template>
            </template>

            <!-- Existing Endorsed resource actions -->
            <template v-if="!isNewResource && isEndorsed">
              <!-- Show 'More actions' button on preview -->
              <span class="mr-3">
                <ActionsMenu
                  bottom
                  offset-y
                  left
                  :nudge-bottom="8"
                  :items="actionsItems"
                  @clickedAction="handleMenuAction"
                />
              </span>
            </template>
          </div>


          <!-- right content - Publisher -->
          <div
            v-if="isPublisher"
            class="right-content"
          >
            <v-switch
              v-if="shouldPublisherEdit"
              v-model="editMode"
              hide-details
              label="Edit"
            />
            <!-- for Publisher, NEW RESOURCE Always have 'save as draft' and 'publish' options (as previous releases) -->
            <template v-if="isNewResource">
              <!-- Publisher can create a new draft -->
              <SaveButtonForPublisher
                :disabled="disableSaveOrSubmit"
                @click="handleCreate(true, true)"
              />
              <PublishButton
                :disabled="disablePublish"
                @click="publish"
              />
            </template>

            <!-- Existing Endorsed resource actions -->
            <template v-if="!isNewResource && isEndorsed">
              <template v-if="!editMode">
                <!-- For Endorsed resource, in Preview, Publisher can Decline/Publish, buttons are enabled-->
                <DeclineButton
                  @click="declineDialog = true; $store.dispatch('editor/getReviewersList', resourceDetails.draft.Source)"
                />
                <PublishButton @click="publish" />
                <!-- Show 'More actions' button on preview -->
                <span class="mr-3">
                  <ActionsMenu
                    bottom
                    offset-y
                    left
                    :nudge-bottom="8"
                    :items="actionsItems"
                    @clickedAction="handleMenuAction"
                  />
                </span>
              </template>
              <template v-if="editMode">
                <!-- For Endorsed resource, in Edit, Publisher can Decline/Publish-->
                <DeclineButton
                  @click="declineDialog = true; $store.dispatch('editor/getReviewersList', document.Source)"
                />
                <PublishButton @click="publish" />
              </template>
            </template>

            <!-- Existing Published resource actions -->
            <template v-if="!isNewResource && isPublished">
              <!-- For Published resource, in Preview, Publisher can Unpublish, button is enabled-->
              <template v-if="!editMode">
                <!--  Show 'More actions' button on preview -->
                <span class="mr-3">
                  <ActionsMenu
                    bottom
                    offset-y
                    left
                    :nudge-bottom="8"
                    :items="actionsItemsPublisher"
                    @clickedAction="handleMenuAction"
                  />
                </span>
                <!--                <UnpublishButton
                                  v-if="isPublisher"
                                  :disabled="disableUnpublish"
                                  @click="publish"
                                />-->
              </template>
              <!-- For Published resource, in Edit, Publisher can Unpublish or Save and Publish-->
              <template v-if="editMode">
                <UnpublishButton
                  v-if="isPublisher"
                  :disabled="disableUnpublish"
                  @click="confirmUnpublish"
                />
                <!-- save the published resource with save state -->
                <SaveAndPublishButton
                  :disabled="disableSaveOrSubmit"
                  @click="savePublished"
                />
              </template>
            </template>

            <!-- Existing Non-Published & Non-Endorsed resource actions, same as Reviewer -->
            <!-- Existing Pending resource actions -->
            <template v-if="!isNewResource && isPending">
              <!-- For Pending resource, in Preview, Reviewer can Decline/Endorse, buttons are enabled-->
              <template v-if="!editMode">
                <DeclineButton
                  @click="declineDialog = true; $store.dispatch('editor/getReviewersList', resourceDetails.draft.Source)"
                />
                <EndorseButton
                  @click="endorseDialog = true; $store.dispatch('editor/getReviewersList', resourceDetails.draft.Source)"
                />
                <!-- Show 'More actions' button on preview -->
                <span class="mr-3">
                  <ActionsMenu
                    bottom
                    offset-y
                    left
                    :nudge-bottom="8"
                    :items="actionsItems"
                    @clickedAction="handleMenuAction"
                  />
                </span>
              </template>
              <!-- For Pending resource, in Edit, Reviewer can Decline/Endorse, will check required fields-->
              <template v-if="editMode">
                <DeclineButton
                  :disabled="disableSaveOrSubmit"
                  @click="declineDialog = true; $store.dispatch('editor/getReviewersList', document.Source)"
                />
                <EndorseButton
                  :disabled="disableSaveOrSubmit"
                  @click="endorseDialog = true; $store.dispatch('editor/getReviewersList', document.Source)"
                />
              </template>
            </template>

            <!-- Existing Draft or Declined resource actions -->
            <template v-if="!isNewResource && (isDraft || isDeclined)">
              <template v-if="!editMode">
                <!-- Show 'More actions' button on preview -->
                <span class="mr-3">
                  <ActionsMenu
                    bottom
                    offset-y
                    left
                    :nudge-bottom="8"
                    :items="isDraft ? actionsItemsWithDelete : actionsItems"
                    @clickedAction="handleMenuAction"
                  />
                </span>
              </template>
              <template v-if="editMode">
                <!-- For Draft or Declined resource, in Edit, publisher can edit and Save or Publish -->
                <SaveAsDraftButton
                  :disabled="disableSaveOrSubmit"
                  @click="saveAsDraftForExistingResource"
                />
                <PublishButton
                  :disabled="disableSaveOrSubmit"
                  @click="publish"
                />
                <!--                <SubmitForReviewButton
                  :disabled="disableSaveOrSubmit"
                  @click="submitForReviewDialog = true; $store.dispatch('editor/getReviewersList', document.Source)"
                />-->
              </template>
            </template>
          </div>
        </div>

        <div class="show-overview mt-8">
          <AdsButton
            tertiary
            button-text="Show overview"
            :color="ADS_Colors.PRIMARY_BLUE"
            @click="handleShowOverview"
            @keyup.enter="handleShowOverview"
          />
        </div>

        <!--        Dialogs  -->
        <fileProgressDialog
          v-model="fileProgressDialog"
          :addMult="addMult"
        />
        <DeleteResourceDialog
          v-model="deleteDialog"
          :resource="resource && resource"
          @confirm="handleDelete"
        />
        <ErrorDialog
          v-model="showErrorDialog"
          title="Warning"
          :resourceIdExist="true"
        >
          <template #message>
            <p>
              We've noticed this is a duplicate of an existing resource card. Do you want to overwrite and publish
              changes <strong>OR</strong> publish as a new resource card?
            </p>
          </template>
          <template #existingResourceId>
            <span class="resourceId"><strong>Existing Resource ID:</strong> {{ hasOriginalId }} </span>
          </template>
          <template #buttons>
            <AdsButton
              secondary
              button-text="Close"
              @click="$downwardBroadcast('close:dialog');"
              @keyup.enter="$downwardBroadcast('close:dialog');"
            />

            <AdsButton
              secondary
              color="warning"
              button-text="Overwrite existing"
              @click="showOverWriteDialog = true"
              @keyup.enter="showOverWriteDialog = true"
            />
            <!-- if no selected cards, disable the Save button -->
            <AdsButton
              primary
              button-text="Publish as new"
              @click="publishAsNew"
              @keyup.enter="publishAsNew"
            />
          </template>
        </ErrorDialog>
        <OverwriteExistingDialog
          v-model="showOverWriteDialog"
          @overwrite:existing="overwriteExisting"
        />
        <!--When user tries to delete resource if they are not allowed.-->
        <ErrorDialog
          v-model="showDeleteError"
          title="You do not have permissions to delete this resource"
          :resource-id-exist="false"
        >
          <template #message>
            <p> Please contact the resource owner should you have any concerns.</p>
          </template>
          <template #buttons>
            <AdsButton
              button-text="Close"
              @click="$downwardBroadcast('close:dialog')"
              @keyup.enter="$downwardBroadcast('close:dialog')"
            />
          </template>
        </ErrorDialog>

        <template v-if="!isNewResource">
          <SubmitForReviewDialog
            v-model="submitForReviewDialog"
            :record-id="resourceDetails.record.id"
            :application="resourceDetails.audit.iss"
            :source="resourceDetails.record.collection"
          />
          <DeclineDialog
            v-model="declineDialog"
            :record-id="resourceDetails.record.id"
            :application="resourceDetails.audit.iss"
            :source="resourceDetails.record.collection"
          />
          <ConfirmUnpublishDialog
            v-model="confirmUnpublishDialog"
            @onConfirm="publish"
          />
          <EndorseDialog
            v-model="endorseDialog"
            :record-id="resourceDetails.record.id"
            :application="resourceDetails.audit.iss"
            :source="resourceDetails.record.collection"
          />
        </template>

        <!--Resource Editor Content-->
        <div class="content__container">
          <!--          Edit section STARTS-->
          <section v-show="editMode">
            <v-form>
              <AddNewImage v-model="document.ThumbnailImage" />

              <!--Content Admin section-->
              <v-card
                v-show="!isPublished"
                class="content mb-6"
              >
                <ContentAdmin
                  class="section"
                  :contentAdmin="contentAdmin"
                  :document="document"
                  @validityChange="handleContentAdministrationValidityChange"
                />
              </v-card>

              <v-card class="content mb-6">
                <SourceFilesAndLinks
                  v-model="filesAndLinks"
                  class="section"
                  @get:files="getFiles"
                  @get:links="getLinks"
                />
              </v-card>

              <!--Resource Category section-->
              <v-card class="content mb-6">
                <div class="section">
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h3>Resource category *</h3>
                      <div class="body-2">
                        Which category best describes this resource?
                      </div>
                    </v-col>
                  </v-row>

                  <!-- Resource Category -->
                  <v-row>
                    <v-col
                      cols="12"
                      class="mb-4"
                    >
                      <v-radio-group
                        v-model="document.ResourceCategory"
                        hide-details
                        row
                      >
                        <v-radio
                          label="Teaching and learning resource"
                          :value="['tlr']"
                          :rules="[rules.requiredCategory]"
                        />
                        <v-radio
                          label="School Improvement resource"
                          :value="['sir']"
                          :rules="[rules.requiredCategory]"
                        />
                        <v-radio
                          v-if="contentAdmin.application === 'equ'"
                          label="General"
                          :value="['gen']"
                          :rules="[rules.requiredCategory]"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <!-- Resource Title -->
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <AdsTextField
                        v-model="document.Name"
                        label="Resource title *"
                        aria-label="Resource title"
                        aria-required="true"
                        counter="200"
                        maxlength="200"
                        :rules="resourceTitleValidationRules(document.Name)"
                      />
                    </v-col>
                  </v-row>

                  <!-- Resource Phase / Resource Type -->
                  <v-row class="mt-2">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-combobox
                        v-model="document.Phase"
                        class="resource-type__selector"
                        outlined
                        aria-label="Phase"
                        aria-required="true"
                        label="Phase *"
                        return-object
                        item-text="phase"
                        item-value="id"
                        multiple
                        chips
                        deletable-chips
                        no-data-text="Select Resource Category"
                        :items="phases"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="document.ResourceType"
                        class="resource-type__selector"
                        outlined
                        aria-label="Resource type"
                        aria-required="true"
                        item-text="name"
                        item-value="id"
                        label="Resource type *"
                        :items="resourceTypeByCategory"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="document.Format"
                        class="resource-type__selector"
                        outlined
                        aria-label="Resource Format"
                        aria-required="true"
                        label="Resource Format *"
                        :items="$store.getters['metadata/formats'].map(el => el.name)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        id="owner-field"
                        v-model="document.Source"
                        aria-label="Owner"
                        aria-required="true"
                        label="Owner *"
                        outlined
                        :items="owner"
                        :readonly="!isNewResource"
                        item-text="name"
                        item-value="id"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                      class="autocomplete__wrapper"
                    >
                      <Autocomplete
                        v-model="document.KLA"
                        label="Learning areas (optional)"
                        aria-label="Learning areas"
                        aria-required="true"
                        :items="$store.getters['metadata/klas'].map(el => el.name)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <!--          Stage field will be disregarded when sending to the api-->
                      <v-combobox
                        id="stages-field"
                        v-model="stages"
                        aria-label="stages"
                        aria-required="true"
                        label="Stages (optional)"
                        multiple
                        outlined
                        chips
                        deletable-chips
                        :items="allStages"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="autocomplete__wrapper"
                    >
                      <v-combobox
                        id="years-field"
                        v-model="years"
                        :items="scholasticYears"
                        multiple
                        outlined
                        chips
                        deletable-chips
                        label="Years (optional)"
                        placeholder="Years (optional)"
                      />
                    </v-col>
                  </v-row>

                  <v-row class="mb-16 pb-16">
                    <v-col cols="12">
                      <h3>Description</h3>
                      <quill-editor
                        v-model="document.Description"
                        style="height: 200px;"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card>

              <!--Other Fields Sections-->
              <v-card class="content mb-6">
                <AdsExpansionPanel
                  v-model="panel"
                  class="section"
                  :items="expansionPanelItems"
                  multiple
                >
                  <template #contentOverview>
                    <v-row
                      :class="{'mt-6 mx-6': $vuetify.breakpoint.lgAndUp, 'mt-6 mx-3': $vuetify.breakpoint.mdAndDown}"
                    >
                      <v-col>
                        <v-row>
                          <v-col
                            cols="12"
                            class="px-0"
                          >
                            <Autocomplete
                              v-model="document.Topic"
                              label="Topics"
                              aria-label="Topics"
                              aria-required="true"
                              :items="topics.map(el => el.name)"
                            />
                          </v-col>
                        </v-row>
                        <Curriculum
                          v-bind="$listeners"
                          :syllabus="syllabus"
                          :outcomes="outcomes"
                          :literacyV3="literacyV3"
                          :numeracyV3="numeracyV3"
                          :literacyV2="literacyV2"
                          :numeracyV2="numeracyV2"
                          :curriculum-input="curriculumInput"
                          @curriculumSelection="handleCurriculumSelections"
                          @confirmProgressions="handleProgressions"
                        />
                        <RemoveOrRetainProgressionsDialog />
                        <v-row>
                          <v-col
                            cols="12"
                            class="px-0"
                          >
                            <!-- needs items props and v-model value -->
                            <Autocomplete
                              v-model="document.SchoolType"
                              aria-label="School type"
                              aria-required="true"
                              label="School type"
                              :items="schoolTypes && schoolTypes.map(el => el.name) || []"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="px-0"
                          >
                            <GroupedSelect
                              v-model="document.SEFAlignment"
                              :items="sefItemsByDomain"
                              label="SEF Alignment"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="px-0"
                          >
                            <Autocomplete
                              v-model="document.SIPTerms"
                              label="SIP Terms"
                              placeholder="Search"
                              :items="$store.getters['metadata/sipTerms'] && $store.getters['metadata/sipTerms'].map(el => el.name) || []"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="px-0"
                          >
                            <v-combobox
                              v-model="document.FocusArea"
                              class="resource-type__selector focus_area"
                              multiple
                              outlined
                              chips
                              deletable-chips
                              return-object
                              aria-label="Focus area"
                              aria-required="true"
                              item-text="focusArea"
                              item-value="id"
                              label="Focus area"
                              persistent-hint
                              :items="focusArea"
                            />
                          </v-col>
                        </v-row>

                        <v-row v-show="['readingandnumeracy','literacyandnumeracypackages'].includes(document?.Source)">
                          <v-col
                            cols="12"
                            class="px-0"
                          >
                            <!-- needs items props and v-model value -->
                            <v-autocomplete
                              v-model="document.RandNTopic"
                              aria-label="L&N Topics"
                              aria-required="true"
                              label="L&N Topics"
                              item-text="name"
                              item-value="id"
                              multiple
                              outlined
                              chips
                              deletable-chips
                              :items="$store.getters['metadata/randntopics']"
                            />
                          </v-col>
                        </v-row>

                        <!-- Related Resources  -->
                        <LinkListIterator
                          :items="document.RelatedResources"
                          :skeleton="linkListSkeleton"
                          button-text="Add related resources"
                          title="Related resources"
                          description="For existing URH resources, enter ID and title. For external resources, enter the title and URL"
                          :displayFields="['id', 'title', 'url']"
                        />

                        <!-- Professional learning  -->
                        <LinkListIterator
                          class="mb-3"
                          :items="document.PLLinks"
                          :skeleton="linkListSkeleton"
                          button-text="Add professional learning"
                          title="Professional learning"
                          description="For existing URH resources, enter ID and title. For external resources, enter the title and URL"
                          :displayFields="['id', 'title', 'url']"
                        />

                        <InputRadioGroup
                          :selected="document.Publisher"
                          label="Publisher"
                          :options="ownersList"
                          @change="val => document.Publisher=val"
                        />

                        <InputRadioGroup
                          :selected="Array.isArray(document.Author) && document.Author.join(', ')
                            || document.Author"
                          label="Author"
                          :options="ownersList"
                          @change="val => document.Author=val"
                        />

                        <CopyrightSelector
                          :statement="document.Rights"
                          :copyrightTitle="copyrightTitle"
                          :copyrightRadioGroupLabel="copyrightRadioGroupLabel"
                          :copyrightBody="copyrightBody"
                          :copyrightItems="copyrightItems"
                          @change="handleCopyright"
                        />
                        <v-row>
                          <v-col
                            cols="12"
                            class="px-0"
                          >
                            <Autocomplete
                              v-model="document.Keywords"
                              allow-user-input
                              label="keywords"
                              persistent-hint
                              hint="Use comma or enter to add new keyword"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </template>
                </AdsExpansionPanel>
              </v-card>
            </v-form>
            <CustomResourceIDDialog
              v-model="showCustomResourceIdDialog"
              :resourceDetail="resourceDetails"
            />
          </section>
          <!--Edit section ENDS-->

          <!--Preview Container-->
          <v-card
            v-show="!editMode"
            class="content"
          >
            <Preview
              class="section"
              :isNewResource="isNewResource"
            />
          </v-card>
        </div>
      </v-col>
    </v-row>
    <OverviewDrawer v-model="overviewDrawer" />
  </div>
</template>

<script>
import {ADS_Colors, AdsButton, AdsExpansionPanel, AdsTextField, AppBar} from '@nswdoe/doe-ui-core';
import Preview from '@/views/ResourceEditorDetailsPage/Preview/Preview';
import ActionsMenu from '@/views/ResourceEditorDetailsPage/ActionsMenu/ActionsMenu';
import DeleteResourceDialog from '@/components/Dialogs/Editor/DeleteResource';
import {mapGetters, mapState} from 'vuex';
import moment from 'moment';
import {
  EDITOR_ACTIONS_ITEMS, EDITOR_ACTIONS_ITEMS_WITH_DELETE,
  EDPUSH_ACTION,
  ELASTIC_STATUS,
  PUBLISHER_ACTIONS_ITEMS, PUBLISHER_ACTIONS_ITEMS_WITH_DELETE,
  RESOURCE_APPLICATION,
  RESOURCE_DETAILS_STRUCTURE,
  RESOURCE_DOCUMENT_OR_DRAFT,
  RESOURCE_SCOPES, ResourceStatus, SourceType
} from '@/constants'
import {ErrorDialog} from '@/components/Dialogs/Collections';
import OverwriteExistingDialog from '@/components/Dialogs/Editor/OverwriteExistingDialog';
import GroupedSelect from '@/views/ResourceEditorDetailsPage/Edit/GroupedSelect';
import CopyrightSelector from '@/views/ResourceEditorDetailsPage/Edit/CopyrightSelector';
import Autocomplete from '@/views/ResourceEditorDetailsPage/Edit/Autocomplete';
import ContentAdmin from '@/views/ResourceEditorDetailsPage/Edit/ContentAdmin';
import Curriculum from '@/views/ResourceEditorDetailsPage/Edit/Curriculum';
import RemoveOrRetainProgressionsDialog
  from '@/views/ResourceEditorDetailsPage/Edit/Curriculum/RemoveOrRetainProgressionsDialog';
import LinkListIterator from '@/views/ResourceEditorDetailsPage/Edit/LinkListIterator';
import InputRadioGroup from '@/components/InputRadioGroup';
import CustomResourceIDDialog from '@/components/Dialogs/Editor/CustomResourceIDDialog';
import topics from '@/views/ResourceEditorDetailsPage/Edit/topics.json';
import SubmitForReviewDialog from '@/components/Dialogs/Editor/SubmitForReviewDialog';
import DeclineDialog from '@/components/Dialogs/Editor/DeclineDialog.vue';
import EndorseDialog from '@/components/Dialogs/Editor/EndorseDialog.vue';
import AddNewImage from '@/views/ResourceEditorDetailsPage/Edit/AddNewImage';
import {getFilesOrLinks} from '@/transforms';
import SubmitForReviewButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/SubmitForReviewButton.vue';
import SaveAsDraftButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/SaveAsDraftButton.vue';
import SaveButtonForPublisher from '@/views/ResourceEditorDetailsPage/WorkflowButtons/SaveButtonForPublisher.vue';
import DeclineButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/DeclineButton.vue';
import EndorseButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/EndorseButton.vue';
import PublishButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/PublishButton.vue';
import UnpublishButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/UnpublishButton.vue';
import SaveAndPublishButton from '@/views/ResourceEditorDetailsPage/WorkflowButtons/SaveAndPublishButton.vue';
import OverviewDrawer from '@/components/Drawers/OverviewDrawer';
import SourceFilesAndLinks from '@/views/ResourceEditorDetailsPage/Edit/SourceFilesAndLinks.vue';
import FileProgressDialog from '@/components/Dialogs/Editor/FileProgressDialog.vue';
import ConfirmUnpublishDialog from '@/components/Dialogs/Editor/ConfirmUnpublishDialog';
import _ from 'lodash';

export default {
  name: 'ResourceEditorDetailsPage',
  components: {
    ConfirmUnpublishDialog,
    SaveAndPublishButton,
    UnpublishButton,
    PublishButton,
    EndorseButton,
    DeclineButton,
    SaveAsDraftButton,
    SaveButtonForPublisher,
    SubmitForReviewButton,
    EndorseDialog,
    DeclineDialog,
    SubmitForReviewDialog,
    ActionsMenu,
    DeleteResourceDialog,
    AdsButton,
    Preview,
    AppBar,
    ErrorDialog,
    OverwriteExistingDialog,
    GroupedSelect,
    CopyrightSelector,
    Autocomplete,
    ContentAdmin,
    AdsTextField,
    AdsExpansionPanel,
    Curriculum,
    RemoveOrRetainProgressionsDialog,
    LinkListIterator,
    InputRadioGroup,
    CustomResourceIDDialog,
    SourceFilesAndLinks,
    AddNewImage,
    OverviewDrawer,
	FileProgressDialog
  },
  data() {
    return {
      contentAdmin: {
        application: this.profileApplication,
        scope: this.resourceDetails?.userAccess?.scope || RESOURCE_SCOPES?.INTERNAL_SCOPE,
        qualityAssuredConfirmed: false,
        qualityAssuredBy: null,
        qualityAssuredDate: null
      },
      source: null,
      contentAdministrationValid: false,
      sourceFilesAndLinksValid: false,
      resourceTitleValid: true,
      showOverWriteDialog: false,
      showErrorDialog: false,
      ADS_Colors,
      moment,
      deleteDialog: false,
      fileProgressDialog: false,
      hideEditToggle: false,
      shareCard: false,
      dialogShare: false,
      dialogCollaborators: false,
      deleteDraftDialog: false,
      submitForReviewDialog: false,
      declineDialog: false,
      endorseDialog: false,
      confirmUnpublishDialog: false,
      processing: false,
      actionsItems: EDITOR_ACTIONS_ITEMS,
      actionsItemsWithDelete: EDITOR_ACTIONS_ITEMS_WITH_DELETE,
      actionsItemsPublisher: PUBLISHER_ACTIONS_ITEMS,
      actionsItemsPublisherWithDelete: PUBLISHER_ACTIONS_ITEMS_WITH_DELETE,
      /**
       * Data for Edit
       */
      topics,
      linkListSkeleton: {
        id: '',
        name: '',
        url: '',
      },
      loading: false,
      error: false, // if id exist show text box in error state
      success: false, // if id is available show text box in success state
      successMessage: '', // show appropriate message either error or success
      errorMessage: '',
      filesAndLinks: [],
      files: null,
      // newFiles: [],    // newly added files when calling the api
      // filesToKeep: [], // existing files on a resource when calling the api
      links: null, // 4.3 - move links from document/draft
      literacyV3: [],
      numeracyV3: [],
      literacyV2: [],
      numeracyV2: [],
      // match resourceDetails.document or resourceDetails.draft object, commit to Vuex when toggling editMode to false to show Preview (as Preview only reads from Vuex)
      document: {...RESOURCE_DOCUMENT_OR_DRAFT},
      rules: {
        requiredCategory: value => value?.length > 0 || ''
      },
      expansionPanelItems: [
        {
          id: 'Overview',
          title: '<span role="heading" aria-level="3">Other fields (optional)</span>',
        }
      ],
      panel: [0],
      copyrightTitle: 'Copyright statement',
      copyrightRadioGroupLabel: 'Please select from the following statements:',
      copyrightBody: 'The attached resource must not infringe copyright laws. Users must identify any third-party copyright material embedded in their resource before uploading. <br> The Department retains copyright in all resources created by its staff.',
      copyrightItems: ['© State of New South Wales (Department of Education).'],
      statementOptions: ['© State of New South Wales (Department of Education).'],
      ownersList: ['NSW Department of Education'],
      overviewDrawer: false
      /**
       * Data for Edit - ENDS
       */
    }
  },
  beforeUnmount() {
    this.$store.commit('editor/SET_RESOURCE_DETAILS', null);
    this.$store.commit('editor/SET_EDIT_MODE', false);
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.editor.resourceDetails,
      resource: state => state.editor?.resourceDetails?.record?.status === ResourceStatus.published ? state.editor?.resourceDetails?.document : state.editor.resourceDetails?.draft,
      editMode: state => state.editor.editMode,
      sefItemsAll: state => state.metadata.filtersMetadata.sefalignment,
      resourceFiles: state => state.editor.resourceFiles,
      loadPercent: state => state.loadPercent,
      zipFileChildCount: state => state.editor.zipFileChildCount,
    }),
    ...mapGetters({
      userProfile: 'users/userProfile',
      hasPublisherAccessTo: 'users/hasPublisherAccessTo',
      hasReviewerAccessTo: 'users/hasReviewerAccessTo',
      hasAuthorAccessTo: 'users/hasAuthorAccessTo',
      hasViewerAccessTo: 'users/hasViewerAccessTo',
      isArchived: 'editor/isArchived',
      isDeclined: 'editor/isDeclined',
      isDraft: 'editor/isDraft',
      isEndorsed: 'editor/isEndorsed',
      isPending: 'editor/isPending',
      isPublished: 'editor/isPublished',
      isRejected: 'editor/isRejected',
      isUnpublished: 'editor/isUnpublished',
      allPhases: 'metadata/phaseItems',
      allSources: 'metadata/sources',
      allHubResourceTypes: 'metadata/allHubResourceTypes',
      sefAlignmentItems: 'metadata/sefAlignmentItems',
      schoolTypes: 'metadata/schoolTypes',
      allFocusArea: 'metadata/focusAreaItems',
      sources: 'metadata/sources',
      stagesAndYears: 'metadata/mappedStagesAndYears',
      profileApplication: 'users/profileApplication',
      activeResourceId: 'editor/activeResourceId',
      orderedSources: 'editor/orderedSources'
    }),
    isPublisher() {
      return this.checkAccess(this.hasPublisherAccessTo)
    },
    isReviewer() {
      return this.checkAccess(this.hasReviewerAccessTo)
    },
    isAuthor() {
      return this.checkAccess(this.hasAuthorAccessTo)
    },
    isViewer() {
      return this.checkAccess(this.hasViewerAccessTo)
    },
    addMult() {
      return this.zipFileChildCount * 0.07
    },
    // Author role
    isDraftOrPending() {
      return this.isDraft || this.isPending
    },
    shouldAuthorOrReviewerEdit() {
      // Author and Reviewer can edit new resource, or can edit existing resource whose status is Draft or Pending or Declined
      // can not edit a submitted resource (Pending status)
      return this.isNewResource || this.isDraftOrPending || this.isDeclined;
    },
    // Publisher role
    shouldPublisherEdit() {
      // can edit the new resource, or can edit existing resources including Published
      return this.isNewResource || this.isDraftOrPending || this.isDeclined || this.isEndorsed || this.isPublished;
    },
    showDeleteError: {
      get() {
        return this.$store.state.editor.errorState
      },
      set(value) {
        this.$store.commit('editor/SET_DISPLAY_ERROR', value)
      }
    },
    hasOriginalId() {
      return this.resourceDetails && this.resourceDetails.record && this.resourceDetails.record.originalId
    },
    isNewResource() {
      return this.$route.params?.id === 'new';
    },
    isFilesAndLinksEmpty() {
      const resLink = this.filesAndLinks.filter(item => {
        if (item.type === SourceType.Link) {
          return item.data.url ? true : false;
        }
      });
      const resFile = this.filesAndLinks.filter(item => {
        if (item.type === SourceType.File) {
          return item.MimeType ? true : false;
        }
      });
      return (resLink.length > 0 || resFile.length > 0) ? false : true;
    },
    completedMandatoryFields() {
      // check if all mandatory fields are filled in
      if (!this.document) {
        return false;
      } else {
        const mandatoryFields = [
          this.document.ResourceCategory?.length > 0,
          Number.isInteger(this.contentAdmin.scope), // 0 is a valid scope
          !!this.contentAdmin.application,
          !_.isEmpty(this.document.Phase),
          !!this.document.ItemID,
          !!this.document.Format,
          !!this.document.ResourceType,
          !!this.document.Source,
          !!this.document.FocusArea,
          !!this.document.Name,
          this.filesAndLinks.length > 0 && this.filesAndLinks.every( f => {
            if (f.type === 'link') {
              return !_.isEmpty(f.data.url)
            } else {
              return !_.isEmpty(f.data)
            }
          })
        ];
        return mandatoryFields.every(i => i === true);
      }
    },
    disableUnpublish() {
      if (this.editMode) {
        return !this.completedMandatoryFields;
      }
      return false;
    },
    disableSaveOrSubmit() {
      if (this.isNewResource) {
        return !this.completedMandatoryFields;
      }
      return !this.completedMandatoryFields;
    },
    disablePublish() {
      return !this.isDraft;
    },
    displayActionsButton() {
      return (!this.editMode && !this.isNewResource) || this.hideEditToggle;
    },
    editMode: {
      get() {
        return this.$store.state.editor.editMode;
      },
      set(val) {
        this.$store.commit('editor/SET_EDIT_MODE', val);
      }
    },
    isOwner() {
      return this.resourceDetails?.userAccess?.owner.includes(this.$store.getters['users/userProfile'].userId.toLowerCase());
    },
    appBarTitle() {
      if (this.isNewResource) {
        return 'Create new resource';
      } else {
        return this.document.Name;
      }
    },
    resourceId() {
      if (this.isNewResource) {
        return '';
      }
      if (this.resourceDetails?.record?.id) {
        return this.resourceDetails.record.id;
      }
      return '';
    },
    // if record.published = true, use the document object for the form; if false, use the draft object
    // record.published also drives the UI (buttons on the top, Preview, etc.)
    /*    isPublished() {
          if (this.isNewResource) { // for a new resource, state.resourceDetails initially is null
            return false;
          }
          return this.$store.state.editor.resourceDetails?.record?.published;
        },*/
    /**
     * Edit - STARTS
     */
    showCustomResourceIdDialog: {
      get() {
        return this.$store.state.editor.customResourceId
      },
      set(value) {
        this.$store.commit('editor/SET_CUSTOM_RESOURCE_ID', value)
      }
    },
    sefItemsByDomain() {
      return this.sefItemsAll.reduce(function (previousValue, currentValue) {
        const parent = {
          header: currentValue.name,
        }
        const children = currentValue.children.map(s => {
          return {
            domain: currentValue.name,
            id: s.id,
            element: s.name
          }
        });
        return [...previousValue, parent, ...children]
      }, []) || [];
    },
    stages: {
      get() {
        return this.document?.Stage?.map(y => {
          return {
            text: this.allStages.find(stage => stage.value === y).text,
            value: y
          }
        }) || []
      },
      set(value) {
        this.document.Stage = value.map(stage => stage.value);
      }
    },
    years: {
      get() {
        return this.document.Year?.map(y => {
          return {
            text: this.scholasticYears.find(sy => sy.value === y).text,
            value: y
          }
        }) || []
      },
      set(value) {
        this.document.Year = value.map(y => y.value).sort(function (a, b) {
          return a - b
        });
      },
    },
    scholasticYears() {
      return this.$store.getters['metadata/years']?.map(y => {
        return {
          value: y.shortName,
          text: y.name
        }
      }) || []
    },
    allStages() {
      return this.$store.getters['metadata/stages'].map(s => {
        return {
          value: s.name === 'Early Stage 1' ? 'ES1' : s.name.replace('Stage ', ''),
          text: s.name
        }
      })
    },
    descriptionEnhanced: {
      get() {
        return this.document.Description.replaceAll('<br>', '\n');
      },
      set(val) {
        this.document.Description = val.replaceAll('\n', '<br>');
      }
    },
    syllabus() {
      return this.document?.NSWSyllabus?.map(el => el.name) || []
    },
    outcomes() {
      return this.document?.NSWSyllabus?.reduce(function (outcomes, syllabus) {
        return [...outcomes, ...syllabus.outcomes]
      }, [])
    },
    curriculumInput() {
      // unfortunately nSyllabus requires Stage values not the ids so lets use the local stages form field
      const stageValues = this.stages.map(s => s.text)
      return {
        kla: this.document?.KLA,
        stage: stageValues,
      }
    },
    phases() {
      return this.allPhases.filter(phase => {
        return this.document?.ResourceCategory?.join(',') === phase.category.join(',')
      }) || [];
    },
    focusArea() {
      return this.allFocusArea?.filter(focusArea => {
        return focusArea.ownerid === this.document?.Source;
      });
    },
    resourceTypeByCategory() {
      //Lookup in metadata for the resourceType for the category
      return this.document?.ResourceCategory?.length > 0 && this.allHubResourceTypes.length > 0 && this.allHubResourceTypes?.filter(type => {
        return type.resourcecatid.join(',') === this.document.ResourceCategory?.join(',')
      }) || this.allHubResourceTypes;

      // return this.allHubResourceTypes
    },
    owner() {
      const _owner = [];
      this.userProfile?.source.forEach(s => {
        if (s.application.id === this.contentAdmin.application) {
          _owner.push(s);
        }
      });
      return _owner;
    }
    /**
     * Edit - ENDS
     */
  },
  watch: {
    resourceDetails: {
      handler(resourceDetails) {
        // first render a created resource
        if (resourceDetails) {
          this.handleDisplayedVersion();
        } else {
          this.document = {...RESOURCE_DOCUMENT_OR_DRAFT};
          this.files = null;
        }
      }
    },
    'document.Source'(value) {
      this.source = value
    },
    $route(route) {
      if (route.name === 'ResourceEditorDetailsPage') {
        this.handleDisplayedVersion();
      }
    },
    /**
     * Edit - STARTS
     */
    'contentAdmin.application': {
      handler(newVal, oldVal) {
        if (this.isNewResource) {
          if (oldVal === RESOURCE_APPLICATION.EQUELLA && newVal !== RESOURCE_APPLICATION.EQUELLA) {
            // when changing from EQU to others reset ResourceCategory, Phase and ResourceType
            this.document.ResourceCategory = null;
            this.document.Phase = null;
            this.document.ResourceType = null;
          }
          if (newVal === RESOURCE_APPLICATION.EQUELLA) {
            // if there is no value then set default
            this.document.ResourceCategory = ['gen'];
            this.contentAdmin.scope = RESOURCE_SCOPES.INTERNAL_SCOPE;
            console.log(this.resourceTypeByCategory)
            this.document.Phase = [this.phases[0]];
            this.document.ResourceType = this.resourceTypeByCategory[0].id;
          }
          if (newVal !== RESOURCE_APPLICATION.OLP) {
            // set default scope for non-olp resources
            this.contentAdmin.scope = RESOURCE_SCOPES.INTERNAL_SCOPE;
          }
        }

        // save to Vuex
        this.$store.commit('editor/SET_APPLICATION_USER_SELECTED', newVal);
      }
    },
    'document.ResourceCategory': {
      handler(newVal, oldVal) {
        console.log(newVal)
        if (newVal?.[0] === 'gen') {
          // this.document.Phase = ['N/A'];
          this.document.Phase = [this.phases[0]];
          this.document.ResourceType = this.resourceTypeByCategory[0].id;
        } else if (oldVal && !newVal) {
          this.document.Phase = []
        } else {
          this.document.Phase = this.isPublished ? this.resourceDetails?.document.Phase : this.resourceDetails?.draft.Phase
        }
      }
    },
    'document.ThumbnailImage': {
      handler(val) {
        if (val) {
          this.document.ThumbnailImage = this.document.ResourceImage.resourceImage = this.document.ResourceImage.thumbnailUrl = val;
        }
      }
    },
    editMode: {
      handler(newVal) {
        if (!newVal) { // show Preview
          // for a new resource, it always shows Edit first, need to commit form data in Edit to Vuex for Preview to read
          if (this.isNewResource && !this.resourceDetails) {
            // when creating a new resource, store.resourceDetails is null
            const resourceDetails = {...RESOURCE_DETAILS_STRUCTURE};
            if (this.isPublished) {
              resourceDetails.document = {...this.document}; // copy the object and commit to Vuex
            } else {
              resourceDetails.draft = {...this.document};
            }
            this.$store.commit('editor/SET_RESOURCE_DETAILS', resourceDetails);
          }
        } else { // show Edit
          this.contentAdmin.scope = this.resourceDetails?.userAccess?.scope || RESOURCE_SCOPES.INTERNAL_SCOPE;
          this.contentAdmin.application = this.resourceDetails?.audit?.iss || this.profileApplication;
          this.files = [...this.resourceDetails?.files] || null
          this.links = [...this.resourceDetails?.links] || null
          if (this.isPublished) {
            this.document = {...this.resourceDetails?.document}; // copy the object from Vuex
          } else {
            this.document = {...this.resourceDetails?.draft};
          }
          this.filesAndLinks = [];
          this.orderedSources.forEach(item => {
            if (item.type === SourceType.Link) {
              this.filesAndLinks.push({
                type: SourceType.Link,
                data: {
                  url: item.url,
                  label: item.label
                },
                rules: []
              });
            } else {
              // create a dummy binary file with 0 bytes
              const binaryFile = [new File([''], item.FileName, {type: item.MimeType})];

              this.filesAndLinks.push({
                type: SourceType.File,
                data: binaryFile,
                rules: []
              });
              /*              axios({
                              url: item.DownloadUrl,
                              method: 'GET',
                              responseType: 'blob',
                            }).then((response) => {
                              const blob = new Blob([response.data]);
                              // const binaryFile = [new File([blob], item.FileName, {type: item.MimeType})];
                              const binaryFile = [new File([''], item.FileName, {type: item.MimeType})];

                              this.filesAndLinks.push({
                                type: SourceType.File,
                                data: binaryFile,
                                rules: []
                              });
                            });*/
            }
          });
          console.log(this.filesAndLinks);

          this.progressions = this.document?.LitLearnProgLevel?.concat(this.document.NumLearnProgLevel); // for progressions literacy and numeracy
        }
      }
    },
    'document.Year': {
      handler(val) {
        // when element is active or Stage is not defined (to support old resources) need to re calculate stages
        if (document.activeElement && document.activeElement.id === 'years-field' || (val && !this.document.Stage)) {

          const stages = val ? this.stagesAndYears.filter(sy => {
            return val.includes(sy.year)
          }).map(s => s.stage) : []

          // just removing duplicates
          this.document.Stage = [...new Set(stages)];
        }
      }
    },
    'document.Stage': {
      handler(selectedStages) {
        if (document.activeElement && document.activeElement.id === 'stages-field') {

          const years = selectedStages ? this.stagesAndYears.filter(sy => {
            return selectedStages.includes(sy.stage)
          }).map(s => s.year) : []

          this.document.Year = years;
        }
      }
    },
    curriculumInput: {
      async handler(val) {
        const payload = {
          kla: val?.kla,
          stage: val?.stage
        }
        if (val?.kla && val?.kla.length > 0 && val?.stage && val?.stage.length > 0) {
          await this.$store.dispatch('metadata/fetchNsyllabus', payload);
        }
      }
    },
    syllabus(arr) {
      if (arr.length > 0) {
        this.document.IsNSWSyllabus = true;
      } else {
        this.document.IsNSWSyllabus = false;
      }
    },
    'document.LitLearnProgLevelV3': {
      immediate: true,
      handler(val) {
        this.literacyV3 = val?.map(prog => {
          return {
            ...prog, 'type': 'literacy'
          }
        })
      }
    },
    'document.NumLearnProgLevelV3': {
      immediate: true,
      handler(val) {
        this.numeracyV3 = val?.map(prog => {
          return {
            ...prog, 'type': 'numeracy'
          }
        })
      }
    },
    'document.LitLearnProgLevel': {
      immediate: true,
      handler(val) {
        this.literacyV2 = val?.map(prog => {
          return {
            ...prog, 'type': 'literacy'
          }
        })
      }
    },
    'document.NumLearnProgLevel': {
      immediate: true,
      handler(val) {
        this.numeracyV2 = val?.map(prog => {
          return {
            ...prog, 'type': 'numeracy'
          }
        })
      }
    },
    /**
     * Edit - ENDS
     */
  },
  methods: {
    checkAccess(accesses) {
      const contentArea = (this.isNewResource && this.source) ? this.source : this.resourceDetails?.record?.collection
      return accesses.findIndex(ca => ca.id === contentArea) > -1
    },
    getFiles(files) {
      this.files = files;  // ok to just assign reference
    },
    getLinks(links) {
      this.links = links;  // ok to just assign reference
    },
    confirmUnpublish() {
      if (!this.isPublished) {
        this.publish();
      } else {
        this.confirmUnpublishDialog = true;
      }
    },
    resourceTitleValidationRules(value) {
      // \xB0C  is for math degree symbol
      const validCharacters = /^[a-zA-Z0-9_/?,:()!\xB0C+=&' .-]*$/;
      this.resourceTitleValid = validCharacters.test(value);
      return [this.resourceTitleValid || 'Card title cannot include the special characters used.'];
    },
    handleContentAdministrationValidityChange(valid) {
      this.contentAdministrationValid = valid;
    },
    handleSourceFilesAndLinksValidityChange(valid) {
      this.sourceFilesAndLinksValid = valid;
    },
    // handleUploadFileOrLinkValidationChange(valid) {
    //   // console.log('999 - handleUploadFileOrLinkValidationChange triggered', valid);
    //   this.uploadFileOrLinkValid = valid;
    // },
    handleDisplayedVersion() {
      this.hideEditToggle = false;

      //update local version
      this.document = {...this.resourceDetails.document};
      this.source = this.resourceDetails?.record?.collection;
      this.files = this.resourceDetails.files ? [...this.resourceDetails.files] : null;
      this.links = this.resourceDetails.links ? [...this.resourceDetails.links] : null;
      this.contentAdmin.scope = this.resourceDetails.userAccess?.scope || RESOURCE_SCOPES.INTERNAL_SCOPE
      this.contentAdmin.application = this.resourceDetails.audit?.iss || this.profileApplication;
    },
    handleDiscardDraft() {
      this.deleteDraftDialog = true;
    },
    async savePublished() {
      this.loading = true;

      // use current document to save resource
      let payload = {
        document: this.document,
        scope: this.contentAdmin.scope,
        application: this.contentAdmin.application,
        files: this.files,
        links: this.links,
        filesToInclude: this.$store.state.editor.filesToInclude,
        displayAlert: false
      }

      if(this.files.length > 0) this.fileProgressDialog = true;
      const updatedResource = await this.$store.dispatch('editor/savePublishedResource', payload);
      this.fileProgressDialog = false;
      if (updatedResource) {
        this.$store.commit('editor/SET_RESOURCE_DETAILS', updatedResource);
        this.$store.commit('editor/SET_EDIT_MODE', false);
        this.$store.commit('editor/SET_PUBLISH_SUBMITTED', true);
        await this.$router.push({name: 'ResourceEditorPage'});
      }
      this.loading = false;
    },
    async saveAsDraftForExistingResource() {
      await this.handleUpdate();
      if (this.isDeclined || this.isPending) {
        // for Declined resource, need to post Elastic for status Update to be Draft
        const response = await this.$store.dispatch(
          'editor/updateResourceStatus',
          {action: ELASTIC_STATUS.DRAFT, assigneeId: ''}
        );
        if (response?.data) {
          this.$store.commit('editor/SET_RESOURCE_DETAILS', response.data);
        }
      }
    },
    /**
     * When updating an existing resource, e.g. update a created draft/pending resource
     * @returns {Promise<void>}
     */
    async handleUpdate(action = EDPUSH_ACTION.SAVE_AS_DRAFT, assigneeId, status) {
      this.loading = true;

      const files = getFilesOrLinks(this.filesAndLinks, SourceType.File);
      const links = getFilesOrLinks(this.filesAndLinks, SourceType.Link);
      console.log(files)

      // use current document to save draft/published resource
      const payload = {
        scope: this.contentAdmin.scope,
        application: this.contentAdmin.application,
        document: this.document,
        // filesToKeep: this.filesToKeep,
        // files: this.newFiles,
        files,
        links,
        zipFiles: this.$store.state.editor.zipFiles,
        filesToInclude: this.$store.state.editor.filesToInclude,
        isPublished: this.isPublished || false,
        assigneeId,
        status,
        displayAlert: false
      };

      if(files.length > 0) this.fileProgressDialog = true;
      const updatedResource = await this.$store.dispatch('editor/updateResource', payload);
      this.fileProgressDialog = false;
      if (updatedResource) {
        this.$store.commit('editor/SET_RESOURCE_DETAILS', updatedResource);
        this.editMode = false; // switch to Preview
      }
      this.loading = false;
    },
    // isDraft should always be driven by the trigger not resource state for create api
    // 4.3 - add default parameter to be true, as now if a resource is always a Draft until it is published
    async handleCreate(isDraft = true, displayAlert = false) {
      this.loading = true;

      const files = getFilesOrLinks(this.filesAndLinks, SourceType.File);
      const links = getFilesOrLinks(this.filesAndLinks, SourceType.Link);

      // For a new resource, SaveAsDraft is to create it (POST, status to be draft)
      if (this.isNewResource) {
        const payload = {
          scope: this.contentAdmin.scope,
          application: this.contentAdmin.application,
          document: {
            ...this.document,
            QA: {
              qaassured: this.contentAdmin.qualityAssuredConfirmed,
              ...(this.contentAdmin.qualityAssuredConfirmed && {
                qaassuredby: this.contentAdmin.qualityAssuredBy,
                qadate: this.contentAdmin.qualityAssuredDate
              })
            }
          },
          files,
          links,
          zipFiles: this.$store.state.editor.zipFiles,
          zipFileAlias: this.$store.state.editor.zipFileAlias,
          filesToInclude: this.$store.state.editor.filesToInclude,
          isDraft: isDraft, // this should be passed from the handler
          displayAlert: displayAlert,
        }
        console.log('payload files from handleCreate function/index.vue', files)
        if(files.length > 0) this.fileProgressDialog = true;
        const createdResource = await this.$store.dispatch('editor/createResource', payload);
        this.fileProgressDialog = false;

        if (createdResource) {
          await this.$store.commit('editor/SET_RESOURCE_DETAILS', createdResource);
          // 4.3 - newly created resource is always SaveAsDraft
          this.document = {...this.resourceDetails?.draft};
          this.editMode = false; // switch to Preview
          // update the route with the id and show Preview (otherwise /new is still in address bar and still isNewResource)
          const modifiedRoute = Object.assign({}, this.$route);
          modifiedRoute.params.id = createdResource.record.id;
          this.$router.replace(modifiedRoute).catch(() => {
          });
        }
        return createdResource;
      }
      this.loading = false;
    },
    /**
     * publish function needs to check if it's Preview or Edit mode.
     * if Preview, use state.resourceDetails fetched from server
     * if Edit, use local data
     * @returns {Promise<void>}
     */
    async publish() {
      // check originalId
      if (this.resourceDetails.record.originalId) {
        this.showErrorDialog = true;
        return;
      }

      await this.publishAsNew();
    },
    async publishAsNew() {
      this.loading = true;
      let payload;
      if (this.editMode) { // Edit
        const files = getFilesOrLinks(this.filesAndLinks, SourceType.File);
        const links = getFilesOrLinks(this.filesAndLinks, SourceType.Link);
        // new files don't have the "key" field, only existing files have
        /*        this.filesToKeep = files.filter(file => file.key ? true : false);
                this.newFiles = files.filter(file => file.key ? false : true);
                console.log('this.filesToKeep = ', this.filesToKeep);
                console.log('this.newFiles = ', this.newFiles);*/
        // no file or data changes for unpublishing
        if(files.length > 0 && !this.isPublished) this.fileProgressDialog = true;
        payload = {
          isPublished: this.isPublished,
          scope: this.contentAdmin.scope,
          application: this.contentAdmin.application,
          zipFiles: this.$store.state.editor.zipFiles,
          filesToInclude: this.$store.state.editor.filesToInclude,
          files,
          links,
          // for new resource, all data is in this.document; for existing resource switching from Preview, this.document contains Document or Draft as per check
          document: this.document,
          displayAlert: false
        };

      } else { // Preview
        payload = {
          isPublished: this.isPublished,
          scope: this.contentAdmin.scope,
          application: this.contentAdmin.application || this.resourceDetails?.audit?.iss,
          files: this.resourceDetails.files,
          links: this.resourceDetails.links,
          zipFiles: this.$store.state.editor.zipFiles,
          filesToInclude: this.$store.state.editor.filesToInclude,
          // if record.published = true, use the document object; if false, use the draft object
          document: this.isPublished ? this.resourceDetails.document : this.resourceDetails.draft,
          displayAlert: false
        };
      }
      const response = await this.$store.dispatch('editor/publishOrUnPublishResource', payload);
      if (response) {
        this.fileProgressDialog = false;
        this.$store.commit('editor/SET_RESOURCE_DETAILS', response);
        this.editMode = false; // switch to Preview
        // 4.3 - switch to data table and show the published/unpublished dialog
        if (this.isPublished) {
          this.$store.commit('editor/SET_PUBLISH_SUBMITTED', true);
        } else if (this.isUnpublished || this.isDraft) { // add isDraft as a workaround for the state machine limit
          this.$store.commit('editor/SET_UNPUBLISH_SUBMITTED', true);
        }
        await this.$router.push({name: 'ResourceEditorPage'});
      }
      this.loading = false;
    },
    async overwriteExisting() {
      this.loading = true;
      let files, links;
      if (this.editMode) { // Edit
        files = getFilesOrLinks(this.filesAndLinks, SourceType.File);
        links = getFilesOrLinks(this.filesAndLinks, SourceType.Link);
      } else { // Preview
        files = this.resourceDetails.files;
        links = this.resourceDetails.links;
      }
      console.log(links)
      console.log(files)
      const payload = {
        isPublished: this.isPublished,
        scope: this.contentAdmin.scope,
        application: this.contentAdmin.application,
        filesToInclude: this.$store.state.editor.filesToInclude,
        files,
        links,
        // if record.published = true, use the document object; if false, use the draft object
        document: this.isPublished ? this.resourceDetails.document : this.resourceDetails.draft,
        displayAlert: true,
        originalId: this.resourceDetails.record.originalId
      };
      if(files.length > 0) this.fileProgressDialog = true;
      const response = await this.$store.dispatch('editor/publishOrUnPublishResource', payload);
      this.fileProgressDialog = false;
      if (response) {
        this.showErrorDialog = false;
        this.$store.commit('editor/SET_RESOURCE_DETAILS', response);
        this.$store.commit('editor/SET_EDIT_MODE', false);
        await this.$router.push({name: 'ResourceEditorDetailsPage', params: {id: response.record.id}});
      }
      this.loading = false;
    },
    async handleDelete(resource) {
      this.loading = true;
      const payload = {
        application: this.contentAdmin.application,
        resourceId: this.resourceDetails.record.id,
        repository: this.resourceDetails.record.collection,
        displayAlert: true
      }

      const response = await this.$store.dispatch('editor/deleteResource', payload);
      if (response) {
        console.log('RESOURCE ' + this.resourceDetails.record.ItemID + ' deleted')
        await this.$router.push({name: 'ResourceEditorPage'});
      }
      this.loading = false;
    },
    async handleCopy() {
      const payload = {
        resourceId: this.resourceDetails.record.id,
        resourceName: this.document.Name,
        repository: this.resourceDetails.record.collection,
        application: this.contentAdmin.application,
        displayAlert: true
      }

      this.$store.commit('editor/SET_CUSTOM_RESOURCE_ID', true)
    },
    handleMenuAction(action) {
      switch (action) {
        case 'duplicate':
          this.handleCopy()
          break;
        case 'delete':
          // Allow deletion only to owner
          // just get rid of !this.isOwner after or when user access is fixed.
          if (this.isOwner || !this.isOwner) {
            this.deleteDialog = true;
          } else {
            const alert = {
              type: 'error',
              text: 'There was an error deleting this resource'
            }
            this.$store.commit('SET_ALERT', alert, {root: true});
            this.$store.commit('SET_DISPLAY_ALERT', true);
          }
          break;
        case 'unpublish':
          this.confirmUnpublishDialog = true
          break;
      }
    },
    /**
     * Edit - STARTS
     */

    handleCopyright(statement) {
      this.document.Rights = statement;
    },
    handleProgressions(progressions) {
      if (progressions.literacyV3) {
        this.document.LitLearnProgLevelV3 = progressions.literacyV3
      }
      if (progressions.numeracyV3) {
        this.document.NumLearnProgLevelV3 = progressions.numeracyV3
      }
      if (progressions.literacyV2) {
        this.document.LitLearnProgLevel = progressions.literacyV2
      }
      if (progressions.numeracyV2) {
        this.document.NumLearnProgLevel = progressions.numeracyV2
      }
    },
    handleCurriculumSelections(curriculum) {
      if (curriculum.syllabus) {
        this.document.NSWSyllabus = curriculum.syllabus
      }
    },
    handleShowOverview() {
      this.$store.dispatch('editor/loadAuditDataByResource', this.activeResourceId);
      this.overviewDrawer = true;
    },
    /**
     * Edit - ENDS
     */
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.create-new-resource__wrapper {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $ads-light-10;

  .row {
    margin-top: 0;
    margin-bottom: 0;
  }

  .content__wrapper {
    max-width: 1240px;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 12px;

      .right-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 56px;

        .v-input--switch {
          margin-right: 16px;
          margin-top: 0;
          padding-top: 0;
        }
      }
    }

    .show-overview {
      text-align: right;
    }

    .middle-content {
      display: flex;
      justify-content: flex-end;

      span.label {
        color: $ads-grey-02;
      }

      span a {
        font-weight: bold;
        color: #002664;
      }
    }

    .content__container {
      margin: 12px 0 32px 0;
      padding: 17px 0 48px;
      display: flex;
      justify-content: center;
    }
  }
}

::v-deep.theme--light {
  .v-btn {
    &:hover {
      &::before {
        opacity: 0 !important;
      }
    }
  }
}

.appbar-title {
  padding-right: 12px;
  font-size: 1.25rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// from Edit - STARTS
.row {
  margin: 0 auto;

  .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.content {
  ::v-deep label.v-label {
    color: $ads-dark;
  }

  & .section {
    width: 100%;
    padding: 48px 0;
    margin: 0 auto;
    width: 80%;
  }
}

.action-button__wrapper {
  display: flex;
  align-items: center;
  max-height: 56px;

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
    //width: 100%;

    ::v-deep.v-input--radio-group__input {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.v-input--radio-group--column {
  .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 0;
  }
}

.copyright__container {
  border: 1px solid $ads-light-40;
  box-sizing: border-box;
  border-radius: 4px;

  .body-1 {
    letter-spacing: normal !important;
  }

  //::v-deep label.v-label {
  //  color: $ads-dark;
  //}
}

::v-deep.theme--light {
  .v-btn {
    &:hover {
      &::before {
        opacity: 0 !important;
      }
    }
  }
}

.v-application .error--text {
  color: $ads-error-red !important;
  caret-color: $ads-error-red !important;
}

::v-deep div.error--text {
  color: $ads-error-red !important;
  caret-color: $ads-error-red !important;
}

::v-deep label.error--text {
  color: $ads-error-red !important;
  caret-color: $ads-error-red !important;
}

::v-deep button.error--text {
  color: $ads-error-red !important;
  caret-color: $ads-error-red !important;
}

::v-deep i.error--text {
  color: $ads-error-red !important;
  caret-color: $ads-error-red !important;
}

// copied from Autocomplete - start
::v-deep.v-autocomplete {
  padding: 0;
}

.theme--light {
  ::v-deep.v-messages {
    text-align: right !important;
  }
}

::v-deep.v-select {
  &.v-select--chips:not(.v-text-field--single-line) {
    &.v-text-field--enclosed {
      .v-select__selections {
        min-height: 56px;
      }
    }
  }
}

::v-deep.v-menu__content {
  &.v-autocomplete__content {
    .v-list {
      .v-list-item--link {
        &.v-list-item {
          &.theme--light {
            &:hover {
              &::before {
                opacity: 0;
              }
            }
          }
        }

        &.v-list-item--highlighted {
          background-color: $ads-navy !important;
          color: $ads-white !important;
          outline: none !important;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.theme--light {
  ::v-deep.v-icon {
    &:focus {
      border: 2px solid $ads-navy;
      border-radius: 50%;

      &::after {
        opacity: 0;
      }
    }
  }

  ::v-deep.v-chip {
    background: $ads-white;
    border: 1px solid $ads-navy;

    .v-chip__content {
      button {
        &:focus {
          border: none !important;
          outline: 2px auto $ads-navy;
        }
      }
    }
  }
}

// copied from Autocomplete - end
//from Edit - ENDS

@media only screen and (max-width: 960px) {
  .create-new-resource__wrapper {
    .content__wrapper {
      .content__container {
        margin: 24px 0;
        padding: 24px;
        justify-content: center;
      }
    }
  }

  //from Edit - STARTS
  .row {
    margin-bottom: 0;
  }

  .content {
    width: 100%;

    .autocomplete__wrapper {
      margin-top: 0;
    }
  }
  //from Edit - ENDS
}

@media only screen and (max-width: 600px) {
  .create-new-resource__wrapper {
    .content__wrapper {
      padding: 0;

      .top {
        margin: 8px;
      }

      .content__container {
        margin: 0;
        padding: 12px 0;

        .section {
          width: 100%;
          padding-inline: 12px;
        }
      }
    }
  }
}
</style>
