<template>
  <v-row
    dense
    align="center"
    justify="start"
    class="chip-filters__wrapper"
  >
    <v-col
      sm="auto"
      class="mr-3"
    >
      Filter by:
    </v-col>

    <!-- 1. Owner -->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="Source"
        v-model="checkboxOwner"
        name="Owner"
        heading="Owner"
        :multiLevel="false"
        :alignRight="$vuetify.breakpoint.smOnly"
        :items="profileSources"
      />
    </v-col>

    <!-- 2. Category = Hub-Type (the new Resource Category) -->
    <v-col
      sm="auto"
      md="auto"
      lg="auto"
    >
      <ChipFilter
        id="Category"
        v-model="checkboxCategory"
        name="Category"
        heading="Category"
        :alignRight="$vuetify.breakpoint.smOnly"
        :items="resourcecategories"
      />
    </v-col>
  </v-row>
</template>

<script>
import {ChipFilter} from '@nswdoe/doe-ui-core';
import {mapGetters} from 'vuex'
import _debounce from 'lodash/debounce'
import {SortField} from 'resource_store_vue_client/index';
import sortBy from 'lodash/sortBy';

export default {
  name: 'FiltersForEditor',
  components: {
    ChipFilter
  },
  data() {
    return {
      profileSources: null
    }
  },
  computed: {
    ...mapGetters({
      resourcecategories: 'metadata/resourcecategories',
      sources: 'metadata/sources',
      profileSourcesIds: 'users/profileSourcesIds'
    }),
    checkboxOwner: {
      get() {
        return this.$store.state.editor.filtersSelection.checkboxOwner;
      },
      set(value) {
        const filtersSelection = {...this.$store.state.editor.filtersSelection};
        filtersSelection.checkboxOwner = value;
        this.$store.commit('editor/SET_FILTERS_SELECTION', filtersSelection);

        const newFilters = {...this.$store.state.editor.filters};
        newFilters.owner = value.join();
        this.$store.commit('editor/SET_FILTERS', newFilters);
        this.debouncedFetchResources()
      }
    },
    checkboxCategory: {
      get() {
        return this.$store.state.editor.filtersSelection.checkboxCategory
      },
      set(value) {
        const filtersSelection = {...this.$store.state.editor.filtersSelection};
        filtersSelection.checkboxCategory = value;
        this.$store.commit('editor/SET_FILTERS_SELECTION', filtersSelection);
        const newFilters = {...this.$store.state.editor.filters};
        newFilters.resourcecategory = value.join();
        this.$store.commit('editor/SET_FILTERS', newFilters);
        this.debouncedFetchResources()
      }
    }
  },
  created() {
    // to avoid multiple api calls on each state update
    this.debouncedFetchResources = _debounce(this.fetchResources, 500)
  },
  mounted() {
    this.getProfileSources();
  },
  methods: {
    getProfileSources() {
      const _sources = [];
      this.sources.forEach(source => {
        if (this.profileSourcesIds.includes(source.id)) {
          _sources.push(source);
        }
      });
      this.profileSources = sortBy(_sources, 'name');
      if (this.$store.state.editor.filtersSelection.checkboxOwner.length === 0) {

        // when onMounted:
        // 1. set checkboxOwner's initial value
        this.checkboxOwner = [..._sources];

        // 2. set filters from user profile before calling the api
        const filtersSelection = {...this.$store.state.editor.filtersSelection};
        filtersSelection.checkboxOwner = this.profileSourcesIds;
        this.$store.commit('editor/SET_FILTERS_SELECTION', filtersSelection);

        const newFilters = {...this.$store.state.editor.filters};
        newFilters.owner = this.profileSourcesIds.join();
        this.$store.commit('editor/SET_FILTERS', newFilters);
      }
    },
    fetchResources() {
      const pagination = {
        itemsPerPage: this.$store.state.editor.perPage,
        page: this.$store.state.editor.pageNum,
        sortDesc: [true], // this should be the default order
        sortBy: [SortField.UPDATED]
      }
      this.$store.dispatch('editor/fetchResources', pagination);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.chip-filters__wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% + 12px);
  overflow-x: scroll;
}

.chip-filters__wrapper::-webkit-scrollbar {
  display: none;
}

.clear-all {
  &:hover {
    &:before {
      opacity: 0 !important;
    }
  }

  &:focus {
    outline-color: $ads-navy !important;
  }
}

.v-menu {
  &__content {
    max-width: 290px;
  }
}

::v-deep .v-card {
  &__title {
    .heading {
      width: 100px;
      word-break: keep-all;
      line-height: 1.5;
      margin-right: 5px;
    }

    button.close-button {
      align-self: flex-start;
    }
  }
}

@media only screen and (max-width: 620px) {
  .chip-filters__wrapper {
    .col {
      &:first-child {
        margin-left: 12px;
      }

      &:last-child {
        margin-right: 12px;
      }
    }
  }
}
</style>
