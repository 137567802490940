<template>
  <div
    class="card--wrapper"
    :class="{ 'dense' : dense}"
  >
    <v-btn
      v-if="enableSelection"
      class="btn-select-resource"
      small
      white
      fab
      aria-label="select resource"
      @click="cardClickHandler"
      @keyup.enter="cardClickHandler"
    >
      <v-icon>{{ selected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
    </v-btn>
    <v-card
      :width="size"
      :height="cardHeight"
      :class="{'rounded-0': !rounded}"
      :title="resource.Name"
      v-on="$listeners"
    >
      <v-img
        :src="imageUrl"
        :lazy-src="resourceDefaultImage"
        :width="size"
        :aspect-ratio="1.85"
        :contain="false"
        :eager="true"
        :alt="altDescription"
      >
        <v-chip
          v-if="!dense && isRecentlyAdded && !sequenceOrder"
          small
          label
          aria-label="recently added resource"
          class="status-chip pl-1"
        >
          <v-badge
            dot
            inline
            left
            color="#EE3C48"
          />
          Recently Added
        </v-chip>

        <v-chip
          v-if="sequenceOrder"
          small
          label
          class="order-chip"
        >
          {{ itemIndex + 1 }} / {{ itemLength }}
        </v-chip>
      </v-img>
      <div class="card-info">
        <div class="card-info--heading">
          <v-card-title
            :class="{
              'd-flex justify-space-between': true,
              'px-2 pt-0 pb-0 mt-0': size < 350,
              'pb-0 pt-1 mt-0' : size >= 350
            }"
          >
            <span
              :class="{
                'resource-type': true,
                'resource-type-xs': size < 280
              }"
              :aria-label="'resource type: '+ resourceType"
            >
              {{ resourceType }}
            </span>
            <span
              class="d-flex"
            >
              <FeatureIcon
                v-for="cat in resourceCategory"
                :key="cat.id"
                size="20"
                :aria-label="cat.label"
                :color="cat.iconColor"
                :backgroundColor="cat.iconBg"
                :icon="cat.icon"
                class="ml-2"
              />
            </span>
          </v-card-title>

          <v-card-title
            class="pt-0 pb-0"
            :class="{'px-2': size < 350 }"
          >
            <router-link
              :id="resource.resourceId.id"
              :class="{ disabled: enableNavigation }"
              :to="{
                name: editorPage ? 'ResourceEditorDetailsPage' : 'HubResourceDetailsPage',
                params: {
                  id: resource.resourceId.id,
                  collection: 'collections'
                }}"
              tabindex="0"
              class="primary-control"
            >
              <h3
                class="card-title"
                :class="{'card-title-xl': size > 450, 'card-title-md': size < 320, 'card-title-sm': dense}"
              >
                <div :class="{'truncated': dense}">
                  {{ resource.Name || 'No title created' }}
                </div>
              </h3>
            </router-link>
          </v-card-title>
        </div>
        <v-card-actions :class="{ 'd-flex align-end': true }">
          <v-card-subtitle
            :class="{'pt-1 pr-6': size >= 350, 'px-2 pt-0 pb-2': size < 350, 'pb-1' : true}"
            class="card-footer"
          >
            <div v-if="isSIRCategory">
              Focus Area:
              <span
                :class="{
                  'years-sm': size < 300,
                  'years-xs': size < 280
                }"
              >
                {{ focusAreas ? focusAreas : 'None' }}
              </span>
            </div>
            <div v-else>
              Years:
              <span
                :class="{
                  'years-sm': size < 300,
                  'years-xs': size < 280
                }"
              >
                {{ years ? years : 'None selected' }}
              </span>
            </div>
            <span>Updated: {{ moment(updatedDate).format('DD MMM YYYY') }}</span>
          </v-card-subtitle>
          <v-spacer />

          <template v-if="!dense">
            <div class="d-flex mb-1">
              <div>
                <Dialog
                  v-model="dialogCopyLink"
                  title="Share this link"
                  dark-heading
                  :max-width="$vuetify.breakpoint.smAndUp ? 500 : 360"
                >
                  <template #activator="{ on, attrs}">
                    <button
                      aria-label="copy content"
                      class="button-icon copy-button"
                      @click.stop="dialogCopyLink = true"
                    >
                      <v-icon>share</v-icon>
                    </button>
                  </template>
                  <template #content>
                    <CopyClipboardMenu
                      :is-open="dialogCopyLink"
                      description="Copy and paste this link:"
                      :text-to-copy="itemUrl"
                    />
                  </template>
                </Dialog>
              </div>

              <button
                aria-label="go to resource"
                class="button-icon go-to-resource-button"
                @click.stop="handleGoToResource"
              >
                <v-icon>open_in_new</v-icon>
              </button>
            </div>
          </template>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import resourceDefaultImage from '@/assets/default_myresources_image.png';
import Dialog from '@/components/Dialogs/Dialog';
import CopyClipboardMenu from '@/components/CopyClipboardMenu';
import {FeatureIcon} from '@nswdoe/doe-ui-core';
import {RESOURCE_CATEGORIES} from '@/constants';
import {mapGetters} from 'vuex';

export default {
  name: 'ResourceCard',
  components: {
    Dialog,
    CopyClipboardMenu,
    FeatureIcon
  },
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    size: {
      type: [Number, String],
      default: 360
    },
    height: {
      type: [Number, String],
      default: 400
    },
    rounded: {
      type: Boolean,
      default: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    enableSelection: {
      type: Boolean,
      default: true
    },
    enableNavigation: {
      type: Boolean,
      default: true
    },
    statusChip:{
      type: Boolean,
      default: false
    },
    itemLength:{
      type: Number,
      default: 0
    },
    itemIndex:{
      type: Number,
      default: 0
    },
    sequenceOrder:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moment,
      dialogCopyLink: false,
      deleteDialog: false,
      resourceDefaultImage,
      selected: this.isSelected
    }
  },
  computed: {
    ...mapGetters({
      allHubResourceTypes: 'metadata/allHubResourceTypes'
    }),
    editorPage() {
      const ret = this.$route.query.rce === 'true' || this.$route.params.rce == true || this.$route.params.rce
      console.log(ret, 'return editorpage', this.$route.query)
      return ret
    },
    isSIRCategory() {
      return this.resource.ResourceCategory?.includes('sir') || false;
    },
    focusAreas() {
      return this.resource.FocusArea?.map(e => e.focusArea).join(', ');
    },
    resourceType() {
      // Lookup in metadata for the resourceType label
      return this.allHubResourceTypes.length > 0 && this.allHubResourceTypes?.find(type => {
        return type.id === this.resource.ResourceType
      })?.name || 'Resource';
    },
    resource() {
      return this.item;
    },
    resourceCategory() {
      return this.resource.ResourceCategory?.map(id => this.getCategoryDetails(id));
    },
    imageUrl() {
      if (this.resource.ThumbnailImage || this.resource.image) {
        return this.resource.ThumbnailImage || this.resource.image;
      } else {
        return resourceDefaultImage;
      }
    },
    altDescription() {
      return this.resource.ResourceImage?.altDescription ? this.resource.ResourceImage?.altDescription : 'alt description not available';
    },
    updatedDate() {
      return this.resource.DateModified;
    },
    itemUrl() {
      return this.resource?.ResourceURL || this.resource.file?.DownloadUrl;
    },
    itemId() {
      return this.item.ItemID;
    },
    years() {
      return this.resource.Year?.join(' ');
    },
    isRecentlyAdded() {
      const dateSevenDaysAgo = new Date()
      dateSevenDaysAgo.setDate(dateSevenDaysAgo.getDate() - 7)
      return !moment(this.resource.DateAdded).isBefore(dateSevenDaysAgo);
    },
    isOwner() {
      return false;
    },
    isViewer() {
      return false;
    },
    cardHeight() {
      return this.$vuetify.breakpoint.mdAndDown ? this.height + 20 : this.height;
    }
  },
  watch: {
    isSelected(val) {
      this.selected = val
    }
  },
  methods: {
    cardClickHandler() {
      this.$emit('onSelect', { select: !this.selected, item: this.item });
      console.log('selection ' + this.selected + ' item ' + this.item.ItemID)
    },
    handleGoToResource() {
      this.$store.dispatch('captureGoToResource', { id : this.item.ItemID });
      window.open(this.itemUrl, '_blank');
    },
    getCategoryDetails(category) {
      return RESOURCE_CATEGORIES.find(cat => cat.id === category) || null;
    },
    parseAndSortYearsOrStages(arr, str) {
      let arrModified = [];
      let includeString = false;
      if (arr.includes(str)) {
        includeString = true;
        arrModified = arr.filter(s => s !== str);
        arrModified = arrModified.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      } else {
        arrModified = arr.map(s => s.replace(/^[a-zA-Z]+/, '')).sort(function (a, b) {
          return a - b
        });
      }
      if (includeString) {
        arrModified.unshift('K');
      }
      return arrModified;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.card--wrapper {
  position: relative;
  .btn-select-resource {
    position: absolute;
    right: 20px;
    top: 8px;
    z-index: 1;
    opacity: 0.8;

    &.v-btn:focus {
      outline-color: white !important;
    }
  }
  &.dense .btn-select-resource {
    right: 10px;
  }

}
.v-card {

  display: flex;
  flex-direction: column;

  .v-image {
    position: relative;
    border: none;

    &.v-responsive {
      flex: none;
    }

    .order-chip {
      background-color: $ads-dark-70;
      font-weight: bold;
      color: $ads-white;
      position: absolute;
      bottom: 8px;
      right: 8px;
    }

    .status-chip {
      background-color: $ads-white;
      font-weight: bold;
      color: $ads-dark-70;
      position: absolute;
      left: 8px;
      top: 8px;
    }
  }

  .card-footer {
    max-width: 90%;
  }

  .card-info {

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .resource-type {
      font-size: 14px;
      font-weight: bold;
      display: block;
      color: $ads-blue-1;
      line-height: 28px;

      &.resource-type-xs {
        line-height: 24px;
      }
    }

    .truncated {
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .primary-control {
      text-decoration: none;

      &:focus {
        outline-color: $ads-navy;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .card-title {
        font-size: 18px;
        color: $ads-navy;
        font-weight: bold;
        line-height: 23px;
        height: 69px;
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &.card-title-md {
          font-size: 16px;
          line-height: 23px;
          height: 46px;
          -webkit-line-clamp: 2;
        }

        &.card-title-xl {
          font-size: 20px;
          line-height: 24px;
          height: 48px;
          -webkit-line-clamp: 2;
        }
        &.card-title-sm {
          font-size: 16px;
          line-height: 18px;
          height: 20px;
          -webkit-line-clamp: 2;
        }
      }
    }

    span {
      &.years-sm {
        letter-spacing: -0.5px;
      }

      &.years-xs {
        letter-spacing: -1px;
      }
    }

    .v-card__actions {
      padding: 0;
      position: relative;

      .button-icon {
        box-sizing: border-box;
        border: 2px solid transparent;
        border-radius: 50%;
        padding: 4px;

        &:focus {
          border-color: $ads-navy;
          outline: none;
        }
      }
    }
  }
}
</style>
