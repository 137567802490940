<template>
  <v-card>
    <v-row class="ma-0">
      <v-col
        cols="4"
        class="image__container"
      >
        <ImageCollage
          v-if="isCollection"
          :images="collectionImages"
        />
        <v-img
          v-else
          :src="imageUrl"
          :lazy-src="resourceDefaultImage"
          :contain="false"
          :eager="true"
          alt=""
          class="rounded-2"
        />
      </v-col>
      <v-col>
        <v-card-title class="pb-0 pt-2">
          <span
            v-if="!isCollection"
            style="font-size: 14px; font-weight: bold; display: block; color: #1D428A;"
            class="fontColorResourceType"
            :aria-label="'resource type '+ resourceType"
          >
            {{ resourceType }}
          </span>
        </v-card-title>
        <v-card-title class="pt-0 pb-2">
          <router-link
            :id="itemId"
            :to="(isCollection? '/collection/': '/detail/') + itemId"
            tabindex="0"
            class="primary-control"
          >
            <h2 class="card-title">
              {{ resource.Name }}
            </h2>
          </router-link>
        </v-card-title>
        <v-card-subtitle class="pr-6 pt-2 pb-1">
          <span>Updated: {{ moment(updatedDate).format('DD MMM YYYY') }} </span>
        </v-card-subtitle>
        <v-card-text
          v-if="isTLR"
          class="pt-0 pb-4"
        >
          <span
            style="font-weight: bold;"
            class="mr-2"
          >Years:</span>
          <span>{{ resource.Year.join(', ') }}</span>
          <span
            style="font-weight: bold;"
            class="ml-9 mr-2"
          >Learning Area:</span>
          <span>{{ resource.KLA.join(', ') }}</span>
        </v-card-text>
        <v-card-text
          v-if="isSIR"
          class="pt-0 pb-4"
        >
          <span
            class="font-weight-bold mr-2"
          >Focus Area:</span>
          <span>{{ focusAreas }}</span>
        </v-card-text>
        <v-card-text
          class="pt-0 pb-0 mb-4"
          style="min-height:110px;"
        >
          <span v-html="resource.Description ? resource.Description : ''" />
        </v-card-text>
        <v-card-actions
          v-if="isCollection"
          class="d-flex justify-space-between"
        >
          <div>
            {{ item.subResources.length }} resources
          </div>
          <Chip
            v-if="isFollowing"
            chipClass="status-chip px-4 mr-2 mb-2"
            small
            style="font-weight: bold"
            :textColor="ADS_Colors.Navy"
            text="FOLLOWING"
            :color="ADS_Colors.Blue_3"
          />
        </v-card-actions>
        <v-card-actions
          v-else
          class="actions-wrapper"
        >
          <div class="mr-3">
            <GoToResource
              :id="resource.ItemID"
              :item-url="resource.ResourceURL"
            />
          </div>
          <div>
            <Share
              :item="item"
              align-right
            />
          </div>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment';
import * as prettyBytes from 'pretty-bytes';
import Share from '../../components/Share';
import GoToResource from '../../components/GoToResource';
import resourceDefaultImage from '@/assets/default_myresources_image.png';
import {mapGetters, mapState} from 'vuex'
import ImageCollage from '@/components/ImageCollage';
import {ADS_Colors, Chip} from '@nswdoe/doe-ui-core'

export default {
  name: 'ResourceCardList',
  components: {
    Chip,
    ImageCollage,
    Share,
    GoToResource
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      moment,
      prettyBytes,
      resourceDefaultImage,
      ADS_Colors
    }
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.resourceDetails
    }),
    ...mapGetters({
      allHubResourceTypes: 'metadata/allHubResourceTypes',
      userProfile: 'users/userProfile'
    }),
    isCollection() {
      return this.item.record.isResourceCollection;
    },
    isFollowing() {
      return this.item.userAccess?.followers?.includes(this.userProfile.userId)
    },
    collectionImages(){
      return this.item.subResources?.map( item => item.image)
    },
    isSIR() {
      return this.item.ResourceCategory?.includes('sir');
    },
    isTLR() {
      return this.item.ResourceCategory?.includes('tlr');
    },
    focusAreas() {
      return this.item.FocusArea?.map(e => e.focusArea).join(', ');
    },
    resourceType() {
      // Lookup in metadata for the resourceType label
      return this.allHubResourceTypes.length > 0 && this.allHubResourceTypes?.find(type => {
        return type.id === this.resource.ResourceType
      })?.name || 'Resource';
    },
    resource() {
      if (this.item.document) {
        if (this.isDraft && !this.isEditor && !this.isOwner) {
          const reversedArchive = this.item.archive.slice().reverse();
          const beforeDraft = reversedArchive.find(resource => !resource.document.draft);
          return beforeDraft.document;
        }
        return this.item.document;
      } else {
        return this.item;
      }
    },
    updatedDate() {
      return this.item.record.modified ? this.item.record.modified : this.item.record.created;
    },
    imageUrl() {
      if (this.resource.ThumbnailImage) {
        return this.resource.ThumbnailImage;
      } else {
        return resourceDefaultImage;
      }
    },
    itemId() {
      return this.item?.document?.ItemID;
    },
  }
}
</script>

<style lang="scss" scoped>
.image__container {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .v-image {
    border-radius: 4px;
  }
}

.primary-control {
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .card-title {
    font-size: 18px;
    font-weight: bold;
    word-break: keep-all;
    line-height: 27px;
    min-height: 44px;
    color: #041E42;
  }
}
</style>
